import React from "react";
import Lottie from "react-lottie";
import theme from "root/config/theme";
import styled from "styled-components";
import loading from "root/animations/loading.json";
import { up } from "styled-breakpoints";

interface LoadingIndicatorProps {
  children?: React.ReactNode;
  variant: "small" | "medium" | "big";
  color?: "black" | "blue" | "white" | "green" | "";
  autoWidth?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  background-color: var(--bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;

  span {
    opacity: 0.5;
  }

  &.medium[data-auto-width="true"] {
    width: 100%;
  }

  &.big {
    font-family: ${theme.fonts.rhode};
    font-weight: ${theme.fontWeights.medium};
    font-size: 14px;
    letter-spacing: 0.01em;
    padding: 23px 28px 21px;

    ${up("md")} {
      font-size: 16px;
    }
  }

  &.small {
    font-family: ${theme.fonts.abcdiatype};
    font-weight: ${theme.fontWeights.bold};
    font-size: 9px;
    letter-spacing: 0.1em;
    padding: 6px 10px 5px;

    ${up("xxl")} {
      font-size: 12px;
      padding: 6px 12px 6px;
    }

    ${up("x3l")} {
      font-size: 16px;
      padding: 6px 16px 6px;
    }
  }

  &.medium {
    font-family: ${theme.fonts.rhode};
    font-weight: ${theme.fontWeights.medium};
    font-size: 12px;
    letter-spacing: 0.01em;
    letter-spacing: 0.01em;
    padding: 14px 24px 12px;
    font-size: 12px;

    ${up("md")} {
      font-size: 14px;
    }

    ${up("xxl")} {
      font-size: 16px;
      padding: 14px 36px 12px;
    }
  }

  &[data-color="black"] {
    color: ${theme.colors.white};
    background: ${theme.colors.black};

    path {
      stroke: ${theme.colors.white};
    }
  }

  &[data-color="blue"] {
    color: ${theme.colors.white};
    background: ${theme.colors.blue};

    path {
      stroke: ${theme.colors.white};
    }
  }

  &[data-color="white"] {
    color: ${theme.colors.black};
    background: ${theme.colors.white};

    path {
      stroke: ${theme.colors.black};
    }
  }

  &[data-color="green"] {
    color: ${theme.colors.black};
    background: ${theme.colors.green};

    path {
      stroke: ${theme.colors.black};
    }
  }
`;

const LottieWrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;

  ${up("xxl")} {
    right: 0;
  }
`;

export default function LoadingIndicator({
  children,
  variant,
  color,
  autoWidth,
}: LoadingIndicatorProps) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

  const loaderStyle = {
    left: children ? "auto" : "0",
  };

  return (
    <Wrapper className={variant} data-color={color} data-auto-width={autoWidth}>
      <span>{children}</span>
      <LottieWrap style={loaderStyle}>
        <Lottie options={defaultOptions} width={80} height="100%" />
      </LottieWrap>
    </Wrapper>
  );
}
