import React from "react";

export default function ArtistsIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4066 2.0155C11.9886 2.0155 11.5666 2.1835 11.2501 2.5L4.90613 8.75L4.59412 9.047C4.21912 9.117 3.86162 9.301 3.57812 9.6095C3.16212 10.0645 3.08012 10.621 2.92212 11.2185C2.76363 11.8165 2.56263 12.4825 2.07813 13.2185L1.57812 14H2.50013C4.45713 14 5.74812 13.0645 6.40662 12.406L6.42163 12.391C6.72263 12.082 6.89062 11.6935 6.95312 11.297L7.20312 11.0625L13.5471 4.8125V4.797C13.851 4.49204 14.0217 4.07905 14.0217 3.6485C14.0217 3.21796 13.851 2.80496 13.5471 2.5C13.2306 2.1835 12.8241 2.0155 12.4061 2.0155H12.4066ZM12.4066 3C12.5626 3 12.7111 3.07 12.8441 3.203C13.1116 3.4705 13.1116 3.826 12.8441 4.094L8.32812 8.5625L7.42212 7.656L11.9531 3.203C12.0861 3.0705 12.2501 3 12.4066 3ZM6.70262 8.3595L7.60913 9.2655L6.85913 10L5.95262 9.0935L6.70262 8.3595ZM4.98513 10.015C5.23713 10.023 5.51012 10.1205 5.71962 10.312C6.10813 10.667 6.11762 11.2885 5.70362 11.7025C5.27012 12.136 4.50063 12.695 3.37563 12.906C3.61562 12.3805 3.78363 11.89 3.89113 11.484C4.05713 10.857 4.18013 10.427 4.31313 10.2805C4.48713 10.0915 4.73312 10.0075 4.98513 10.0155V10.015Z"
        fill={color}
      />
    </svg>
  );
}
