import { useEffect, useState } from "react";
import defaultRedaxios from "redaxios";

export default function useHasGiveaway() {
  const [giveawaySlug, setGiveawaySlug] = useState("");

  useEffect(() => {
    const makeRequest = async () => {
      try {
        const req = await defaultRedaxios.get("/api/giveaways");

        setGiveawaySlug(req.data?.slug || "");
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    makeRequest();
  }, []);

  return giveawaySlug;
}
