import React, { MutableRefObject } from "react";
import AssetLightboxModal from "root/components/design-system/AssetLightboxModal";
import LightboxModal from "root/components/design-system/LightboxModal";
import { AssetMetadataType } from "root/lib/graphqlDefs";
import { AssetMediaItem } from "root/modules/AssetDetailsPage/AssetDetailsHero";
import create from "zustand";

interface LightboxProps {
  images: string[];
  initial: number;
  label: string;
  darkMode?: boolean;
  backToRef?: MutableRefObject<HTMLDivElement | undefined>;
}

interface AssetLightboxProps {
  media: AssetMediaItem[];
  metadata?: AssetMetadataType;
  initial: number;
  label: string;
}

interface ModalStoreType {
  lightbox?: LightboxProps;
  assetLightbox?: AssetLightboxProps;
  closing: boolean;
  isOpen: boolean;
  renderLightbox: (options: LightboxProps) => void;
  renderAssetLightbox: (options: AssetLightboxProps) => void;
  closeLightbox: () => void;
  closeAssetLightbox: () => void;
}

const useLightbox = create<ModalStoreType>((set) => ({
  modal: undefined,
  closing: false,
  isOpen: false,
  renderLightbox: (options: LightboxProps) =>
    set({ lightbox: options, isOpen: true }),
  renderAssetLightbox: (options: AssetLightboxProps) =>
    set({ assetLightbox: options }),
  closeAssetLightbox: () => set(() => ({ assetLightbox: null })),
  closeLightbox: () => {
    set({ closing: true, isOpen: false });
    setTimeout(() => set({ lightbox: null, closing: false }), 500);
  },
}));

export function LightboxProvider({ children }) {
  const {
    lightbox,
    closeLightbox,
    closing,
    isOpen,
    assetLightbox,
    closeAssetLightbox,
  } = useLightbox();

  return (
    <>
      {lightbox && (
        <LightboxModal
          isOpen={isOpen}
          onDismiss={closeLightbox}
          closing={closing}
          {...lightbox}
        />
      )}

      {assetLightbox && (
        <AssetLightboxModal onDismiss={closeAssetLightbox} {...assetLightbox} />
      )}
      {children}
    </>
  );
}

export default useLightbox;
