import { AssetMetadataType } from "root/lib/graphqlDefs";
import { NormalizedMediaType } from "./normalizeMediaType";

export default function getAssetMediaType(
  assetMetadata: AssetMetadataType
): NormalizedMediaType {
  const { mediaType, animationUrl, imageUrl, muxPlaybackId } = assetMetadata;

  if (mediaType) {
    if (mediaType.startsWith("image")) return "image";

    if (mediaType.startsWith("video")) return "video";

    return "3d";
  }

  if (muxPlaybackId || animationUrl !== imageUrl) return "video";

  if (imageUrl) return "image";

  return "3d";
}
