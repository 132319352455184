import { autocomplete, AutocompleteOptions } from "@algolia/autocomplete-js";
import React, { createElement, Fragment, useEffect, useRef } from "react";
import { render } from "react-dom";
import useHeaderLayoutStore from "root/hooks/useHeaderLayoutStore";
import styled from "styled-components";
import { Flex, Box, Typography } from "../design-system";
import SearchIcon from "../icons/SearchIcon";
import "@algolia/autocomplete-theme-classic";
import styles from "./Search.module.css";

const StyledSearch = styled.div`
  width: 100%;
`;

function noResultsFound(state) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="250px"
    >
      <SearchIcon stroke="#000" />
      <Box pt="16px" />
      <Typography fontSize={{ _: "24px", lg: "36px" }}>
        No search results
      </Typography>
      <Typography fontSize={{ _: "8px", lg: "18px" }}>
        We couldn’t find results for {state.query}
      </Typography>
    </Flex>
  );
}

export default function AlgoliaSearchContainer(
  props: AutocompleteOptions<never>
) {
  const containerRef = useRef(null);
  const { darkMode } = useHeaderLayoutStore();

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      ...props,
      renderer: { createElement, Fragment },
      placeholder: "Search for artworks, collections, artists etc",
      detachedMediaQuery: `(max-width: 767px)`,
      classNames: {
        root: styles.root,
        panel: styles.pannel,
        form: styles.form,
        input: styles.input,
        sourceHeader: styles.header,
        list: styles.list,
        detachedContainer: styles.detachedContainer,
        detachedSearchButtonPlaceholder: styles.detachedSearchButtonPlaceholder,
        detachedSearchButton: styles.detachedSearchButton,
        detachedSearchButtonIcon: styles.detachedSearchButtonIcon,
      },
      render({ children }, root) {
        render(children, root);
      },
      renderNoResults({ state }, root) {
        render(noResultsFound(state), root);
      },
      container: containerRef.current,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  const formBgColor = darkMode ? "#343434" : "#edf2f7";
  const searchBtnBgColor = darkMode ? "#000" : "#edf2f7";
  const textColor = darkMode ? "#eaf6ff" : "#494d50";
  const primaryColor = darkMode ? "#eaf6ff" : "#161515";

  return (
    <StyledSearch
      style={{
        "--aa-bg-color": formBgColor,
        "--aa-input-background-color": formBgColor,
        "--aa-text-color-rgb": textColor,
        "--aa-primary-color-rgb": primaryColor,
        "--aa-search-btn-bg": searchBtnBgColor,
      }}
      ref={containerRef}
    />
  );
}
