import React from "react";

const Twitter = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.97512 15C13.145 15 17.0666 9.22855 17.0666 4.22358C17.0666 4.05965 17.0666 3.89647 17.0552 3.73401C17.8181 3.19786 18.4766 2.534 19 1.77352C18.2886 2.07997 17.5339 2.28084 16.761 2.36942C17.5749 1.89614 18.1839 1.15164 18.4748 0.274543C17.7097 0.715751 16.8725 1.02667 15.9995 1.19387C15.4118 0.586659 14.6345 0.18458 13.7878 0.0498528C12.9412 -0.0848747 12.0725 0.0552603 11.3161 0.448572C10.5597 0.841884 9.95776 1.46645 9.6035 2.22561C9.24925 2.98477 9.16239 3.83621 9.35636 4.64817C7.80654 4.57263 6.29039 4.1813 4.90632 3.49957C3.52225 2.81784 2.30119 1.86095 1.3224 0.691008C0.82391 1.5248 0.671232 2.51184 0.895454 3.45116C1.11968 4.39048 1.70394 5.21145 2.52928 5.74693C1.90889 5.72927 1.30201 5.56666 0.76 5.27287V5.32087C0.760246 6.19531 1.0718 7.04275 1.64181 7.71946C2.21183 8.39616 3.00522 8.86046 3.8874 9.03361C3.31351 9.18571 2.71136 9.20794 2.12724 9.09859C2.37644 9.8511 2.86142 10.5091 3.5144 10.9807C4.16737 11.4523 4.9557 11.7139 5.76916 11.7288C4.96093 12.3461 4.03537 12.8024 3.04544 13.0718C2.05551 13.3412 1.02063 13.4183 0 13.2987C1.7827 14.4102 3.85694 14.9998 5.97512 14.997"
      fill="currentColor"
    />
  </svg>
);

export default Twitter;
