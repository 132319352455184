import React from "react";

interface Props {
  stroke: string;
}

export default function ArrowRight({ stroke }: Props) {
  return (
    <svg
      width="32"
      height="16"
      viewBox="0 0 32 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.3343 8.74227C31.7248 8.35174 31.7248 7.71858 31.3343 7.32805L24.9703 0.96409C24.5798 0.573566 23.9466 0.573566 23.5561 0.96409C23.1656 1.35461 23.1656 1.98778 23.5561 2.3783L29.213 8.03516L23.5561 13.692C23.1656 14.0825 23.1656 14.7157 23.5561 15.1062C23.9466 15.4968 24.5798 15.4968 24.9703 15.1062L31.3343 8.74227ZM0.726562 9.03516L30.6272 9.03516L30.6272 7.03516L0.726563 7.03516L0.726562 9.03516Z"
        fill={stroke}
      />
    </svg>
  );
}
