import React from "react";

export default function Close({
  stroke,
  size,
}: {
  stroke: string;
  size?: string;
}) {
  return (
    <svg
      width={size || "21"}
      height={size || "21"}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.70711"
        y1="1.29289"
        x2="19.8864"
        y2="19.4722"
        stroke={stroke}
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="25.7094"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 20 2)"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
}
