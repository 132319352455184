import { format } from "date-fns";
import React from "react";
import { Box, Typography } from "./design-system";
import InfiniteLooper from "./InfiniteLooper";

interface StdInfoHeaderProps {
  text: string;
  date?: Date;
  xl?: boolean;
  color?: string;
}

export default function StdInfoHeader({
  text,
  date,
  xl,
  color,
}: StdInfoHeaderProps) {
  const fontSize = xl ? { _: "100px", xl: "317px" } : { _: "36px", xl: "57px" };

  return (
    <>
      <InfiniteLooper>
        {[1, 2].map((item) => (
          <Typography
            key={item}
            fontFamily="rhode"
            fontSize={fontSize}
            color={color || "blue"}
            mx={["80px", "80px", "240px"]}
          >
            {text}
          </Typography>
        ))}
      </InfiniteLooper>
      {date && (
        <Box borderBottom="1px solid #CFCFCF" mx="20px" pb="32px">
          <Typography
            mt="12px"
            fontSize={{ _: "14px", lg: "18px" }}
            letterSpacing="0.1em"
            textAlign="center"
            case="uppercase"
          >
            LAST UPDATED – {format(new Date(date), "MMMM d y")}
          </Typography>
        </Box>
      )}
    </>
  );
}
