import { GraphQLClient } from "graphql-request";
import redaxios from "redaxios";
import { PrivateUserType } from "root/lib/graphqlDefs";

type CustomGlobals = {
  authenticatedRedaxios: typeof redaxios;
  unauthenticatedRedaxios: typeof redaxios;
  graphqlClient: GraphQLClient;
};

export default function buildGlobalRedaxios(
  user: PrivateUserType & { token: string } = null
) {
  const typedGlobal = global as unknown as CustomGlobals;

  typedGlobal.authenticatedRedaxios = null;
  typedGlobal.unauthenticatedRedaxios = null;

  if (
    authenticatedRedaxios?.defaults?.headers?.authorization ===
    `Bearer ${user?.token}`
  ) {
    return;
  }

  typedGlobal.authenticatedRedaxios = redaxios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      authorization: user?.token ? `Bearer ${user.token}` : undefined,
    },
  });

  typedGlobal.unauthenticatedRedaxios = redaxios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      authorization: undefined,
    },
  });

  typedGlobal.graphqlClient = new GraphQLClient(
    `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
    {
      fetch,
      headers: {
        Authorization: user?.token ? `Bearer ${user.token}` : undefined,
      },
      keepalive: true,
    }
  );
}
