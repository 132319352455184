import React from "react";
import { Box, Flex } from "root/components/design-system";

const CollectionBannerSkeleton = () => (
  <Flex
    flexWrap="wrap"
    width={1}
    mx="auto"
    justifyContent={{ _: "normal", md: "space-between", lg: "normal" }}
  >
    <Box
      marginTop={{ _: "20px", md: "10px", lg: "0px" }}
      display="flex"
      width={{ _: 1, md: 230, lg: 0.5 }}
      flexDirection="column"
      justifyContent="center"
      ml={{ _: "0", md: "30px", lg: "0" }}
    >
      <Flex
        marginTop={{ _: "0px", md: "10px", lg: "100px" }}
        justifyContent={{ _: "center" }}
        alignItems={{ _: "center", lg: "flex-start" }}
        flexDirection={{ _: "column" }}
      >
        <Box
          height={{ _: "11px", lg: "12px" }}
          width={{ _: "104px", lg: "145px" }}
          bg="#E6E6E6"
          borderRadius={{ _: "15px", lg: "18px" }}
          mb="6px"
        />
        <Box
          display={{ _: "none", lg: "flex" }}
          height={{ _: "18px", lg: "12px" }}
          width={{ _: "81px", lg: "145px" }}
          bg="#E6E6E6"
          borderRadius={{ _: "17px", lg: "18px" }}
        />
      </Flex>
      <Flex
        justifyContent={{ _: "center" }}
        alignItems={{ _: "center", lg: "flex-start" }}
        flexDirection={{ _: "column" }}
      >
        <Box
          height={{ _: "35px", lg: "63px" }}
          width={{ _: "241px", lg: "433px" }}
          bg="#d4d4d4"
          mt={{ _: "10px", lg: "33px" }}
          mb={{ _: "6px", lg: "15px" }}
          borderRadius={{ _: "50px", lg: "90px" }}
        />
        <Flex>
          <Box
            height={{ _: "11px", lg: "16px" }}
            width={{ _: "62px", lg: "93px" }}
            bg="#E6E6E6"
            borderRadius={{ _: "17px", lg: "23px" }}
            mb="6px"
          />
          <Box
            height={{ _: "11px", lg: "16px" }}
            width={{ _: "131px", lg: "197px" }}
            bg="#E6E6E6"
            borderRadius={{ _: "17px", lg: "22px" }}
            ml="6px"
          />
        </Flex>
        <Flex display={{ _: "flex", lg: "none" }}>
          <Box
            height={{ _: "11px", lg: "16px" }}
            width={{ _: "62px", lg: "93px" }}
            bg="#E6E6E6"
            borderRadius={{ _: "17px", lg: "23px" }}
            mb="6px"
          />
          <Box
            height={{ _: "11px", lg: "16px" }}
            width={{ _: "131px", lg: "197px" }}
            bg="#E6E6E6"
            borderRadius={{ _: "17px", lg: "22px" }}
            ml="6px"
          />
        </Flex>
      </Flex>
      <Box
        mt={{ _: "10px", lg: "34px" }}
        mb={{ _: "10px", lg: "28px" }}
        width={{ _: "332px", lg: "440px" }}
        border="1px solid #E6E6E6"
      />
      <Box
        height={{ _: "11px", lg: "14px" }}
        width={{ _: "332px", lg: "440px" }}
        bg="#E6E6E6"
        borderRadius={{ _: "17px", lg: "20px" }}
        mb="6px"
      />
      <Box
        height={{ _: "11px", lg: "14px" }}
        width={{ _: "332px", lg: "440px" }}
        bg="#E6E6E6"
        borderRadius={{ _: "17px", lg: "20px" }}
        mb="6px"
      />
      <Box
        display={{ _: "none", lg: "flex" }}
        height={{ _: "11px", lg: "14px" }}
        width={{ _: "332px", lg: "440px" }}
        bg="#E6E6E6"
        borderRadius={{ _: "17px", lg: "20px" }}
        mb="6px"
      />
      <Box
        height={{ _: "11px", lg: "14px" }}
        width={{ _: "332px", lg: "440px" }}
        bg="#E6E6E6"
        borderRadius={{ _: "17px", lg: "20px" }}
        mb={{ _: "21px", lg: "41px" }}
      />
      <Flex
        justifyContent={{ _: "center", lg: "flex-start" }}
        alignItems={{ _: "center", lg: "flex-start" }}
      >
        <Box
          height={{ _: "11px", lg: "46px" }}
          width={{ _: "78px", lg: "220px" }}
          bg="#E6E6E6"
          borderRadius={{ _: "17px", lg: "23px" }}
          mb="6px"
        />
        <Box
          display={{ _: "none", lg: "flex" }}
          height={{ _: "18px", lg: "46px" }}
          width={{ _: "81px", lg: "145px" }}
          bg="#E6E6E6"
          borderRadius={{ _: "17px", lg: "22px" }}
          ml="29px"
        />
      </Flex>
    </Box>
    <Box
      mt={{ _: "35px", lg: "5rem" }}
      mb="20px"
      width={{ _: 1, md: 0.5 }}
      display="flex"
      flexDirection="row"
    >
      <Box
        height={{ _: "308px", lg: "584px" }}
        width={{ _: "173px", lg: "427px" }}
        bg="#E6E6E6"
        mr={{ _: "20px", lg: "35px" }}
      />
      <Box
        height={{ _: "308px", lg: "584px" }}
        width={{ _: "173px", lg: "360px" }}
        bg="#E6E6E6"
      />
    </Box>
  </Flex>
);

export default CollectionBannerSkeleton;
