import {
  system,
  typography,
  space,
  color,
  TypographyProps,
  SpaceProps,
  ColorProps,
} from "styled-system";
import styled from "styled-components";
import theme from "root/config/theme";

interface CustomTypographyProps
  extends TypographyProps,
    SpaceProps,
    ColorProps {
  fontFamily?:
    | "abcdiatype"
    | "rhode"
    | "reckless"
    | "pexel"
    | "saintColombe"
    | "Rigton Variable"
    | "apocRevelations";
  case?: "uppercase" | "lowercase" | "capitalize";
}

const Typography = styled.p<CustomTypographyProps>`
  margin: 0;
  font-size: 16px;
  line-height: 150%;
  font-weight: ${theme.fontWeights.regular};
  ${(props) =>
    props.fontFamily === "rhode" ? "text-transform: uppercase;" : ""}

  ${typography}
  ${space}
  ${color}

  ${system({
    case: {
      property: "textTransform",
      scale: "textTransforms",
    },
  })}

  a {
    text-decoration: underline;
  }
`;

Typography.defaultProps = {
  fontSize: { default: "16px", x3l: "20px" },
};

export default Typography;
