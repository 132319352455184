import React from "react";
import { Box, Button, Typography, Flex } from "./design-system";

export interface ErrorModalProps {
  onClose?: () => void;
  message?: string;
  showTryLaterMessage?: boolean;
}

export default function ErrorModal({
  onClose,
  message,
  showTryLaterMessage = true,
}: ErrorModalProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="48"
      paddingBottom="32"
      maxWidth="500px"
      margin="0 auto"
    >
      <Flex alignItems="center">
        <Typography
          fontSize={{ _: "18px", xxl: "20px", x3l: "22px" }}
          fontFamily="rhode"
          fontWeight="500"
          textAlign="center"
          mr="16px"
          mt="2px"
        >
          ERROR
        </Typography>
        <span role="img" aria-label="Sad Face">
          <svg
            width="22"
            height="22"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="20.5"
              cy="20.5"
              r="19.5"
              stroke="black"
              strokeWidth="2"
            />
            <path
              d="M13 31C13 26.5817 16.3579 23 20.5 23C24.6421 23 28 26.5817 28 31"
              stroke="black"
              strokeWidth="2"
            />
            <circle cx="12.9167" cy="15.5" r="2.91667" fill="black" />
            <circle cx="28.0834" cy="15.5" r="2.91667" fill="black" />
          </svg>
        </span>
      </Flex>

      <Box mt="14" />

      {showTryLaterMessage && (
        <Typography
          textAlign="center"
          fontSize={{ _: "14px", xxl: "16px", x3l: "18px" }}
        >
          An error has occured, please try again later.
        </Typography>
      )}

      {message && (
        <Typography
          color="gray-60"
          textAlign="center"
          fontSize={{ _: "12px", lg: "16px", xxl: "14px", x3l: "16px" }}
        >
          {message}
        </Typography>
      )}

      <Box mt="32" />

      {onClose && (
        <Button autoWidth color="blue" variant="big" onClick={onClose}>
          CLOSE
        </Button>
      )}
    </Box>
  );
}
