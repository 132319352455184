import React from "react";
import formatDistance from "date-fns/formatDistance";
import { Box, Typography } from "root/components/design-system";
import Link from "next/link";
import styled from "styled-components";
import theme from "root/config/theme";
import { Routes } from "types/routeTypes";
import {
  AssetMetadataType,
  NotificationType,
  NotificationTypeEnum,
  PublicUserType,
} from "root/lib/graphqlDefs";

const Wrapper = styled.div`
  position: relative;
  padding-right: 28px;

  &[data-is-new="true"] {
    a {
      color: #000;
    }

    :hover {
      a {
        color: ${theme.colors.blue};
      }
    }

    ::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background: ${theme.colors.blue};
      right: 16px;
      top: 4px;
      border-radius: 50%;
    }
  }

  &[data-is-new="false"] {
    color: #767676;

    a {
      color: #767676;
    }

    :hover {
      a {
        color: ${theme.colors["gray-80"]};
      }
    }
  }
`;

export interface NotificationProps {
  notification: NotificationType;
  isNew: boolean;
}

function generateMessage(notification: NotificationType) {
  const messageSymbols = [
    notification.payload.assetId,
    (notification.payload.asset as AssetMetadataType)?.name,
    (notification.payload.buyer as PublicUserType)?.username,
    (notification.payload.seller as PublicUserType)?.username,
    (notification.payload.eventData as any)?.quantity,
    (notification.payload.eventData as any)?.amount,
    notification.payload.price,
    "ETH",
  ];

  const formatMessage = (message: string) => (
    <a
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: messageSymbols.reduce(
          (accum, symbol) =>
            accum.replace(symbol, `<strong>${symbol}</strong>`),
          message
        ),
      }}
    />
  );

  switch (notification.type) {
    case NotificationTypeEnum.MINT:
      return (
        <Link href={Routes.ACCOUNT}>
          <a>
            Just minted a new asset with{" "}
            <strong>ID {notification.payload.assetId}.</strong> Check your
            account page and see your new asset.
          </a>
        </Link>
      );

    case NotificationTypeEnum.NEW_PROPOSAL:
    case NotificationTypeEnum.LOST_PROPOSAL:
      return (
        <Link href={`${Routes.ACCOUNT}?tab=offers`}>
          {formatMessage(notification.message)}
        </Link>
      );

    case NotificationTypeEnum.AUCTION_ENDED:
      return (
        <Link href={`${Routes.ACCOUNT}?tab=open-bids`}>
          {formatMessage(notification.message)}
        </Link>
      );

    case NotificationTypeEnum.ACCEPT_PROPOSAL:
    case NotificationTypeEnum.DIRECT_BUY:
    case NotificationTypeEnum.AUCTION_SETTLE:
      return (
        <Link href={Routes.ACCOUNT}>{formatMessage(notification.message)}</Link>
      );

    case NotificationTypeEnum.NEW_BID:
      return (
        <Link
          href={Routes.ASSETS({
            metadata: {
              name: (notification.payload.asset as AssetMetadataType).name,
              assetId: (notification.payload.asset as AssetMetadataType)
                .assetId,
              contractAddress: (notification.payload.asset as AssetMetadataType)
                .contractAddress,
            },
            creatorUser: {
              username: (notification.payload?.creator as PublicUserType)
                ?.username as string,
            },
          })}
          passHref
        >
          {formatMessage(notification.message)}
        </Link>
      );

    case NotificationTypeEnum.FOLLOW_MINT:
      return (
        <Link
          href={Routes.ASSETS({
            metadata: {
              name: notification.payload.assetName as string,
              assetId: notification.payload.assetId as string,
              contractAddress: notification.payload.contractAddress as string,
            },
            creatorUser: { username: notification.payload.artist as string },
          })}
          passHref
        >
          <a>
            <strong>{notification.payload.assetName}</strong> was just listed
            for sale by @<strong>{notification.payload.artist}</strong>
          </a>
        </Link>
      );

    case NotificationTypeEnum.RECEIVED_ASSET:
      return (
        <Link href={Routes.ACCOUNT} passHref>
          <a>
            <strong>{notification.payload.assetName}</strong> was transfered to
            you by @<strong>{notification.payload.from}</strong>
          </a>
        </Link>
      );

    default:
      return notification.message;
  }
}

export default function NotificationLine({
  notification,
  isNew,
}: NotificationProps) {
  return (
    <Wrapper data-is-new={isNew}>
      <Typography fontSize={{ _: "12px", x3l: "14px" }} as="div">
        {generateMessage(notification)}
      </Typography>

      <Box>
        <Typography fontSize={{ _: "9px", x3l: "12px" }} mt="4px">
          {formatDistance(new Date(notification.createdAt), new Date(), {
            addSuffix: true,
          })}
        </Typography>
      </Box>
    </Wrapper>
  );
}
