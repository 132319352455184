import { FC, ReactNode } from "react";
import styled from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  typography,
  FlexboxProps,
  GridProps,
  BackgroundProps,
  BorderProps,
  ShadowProps,
  TypographyProps,
  compose,
  system,
  OverflowProps,
  SizeProps,
  VerticalAlignProps,
  DisplayProps,
  OpacityProps,
  ResponsiveValue,
} from "styled-system";
import { LiteralUnion } from "type-fest";
import theme from "root/config/theme";

export interface BaseBoxProps {
  as?: string;
  children?: ReactNode;
  hover?: BoxStyleProps;
  focus?: BoxStyleProps;
  active?: BoxStyleProps;

  // Enable custom attributes on a Box
  // for any available HTML element
  [attribute: string]: unknown;
}

export type SpaceType = ResponsiveValue<
  LiteralUnion<keyof typeof theme.space, string> | number
>;

export type ColorType = ResponsiveValue<
  LiteralUnion<keyof typeof theme.colors, string>
>;

interface BaseBoxStyleProps {
  outline?: string;
  outlineColor?: ColorType;
  outlineStyle?: string;
  outlineWidth?: string;
  cursor?: ResponsiveValue<
    LiteralUnion<
      | "auto"
      | "default"
      | "none"
      | "context-menu"
      | "help"
      | "pointer"
      | "progress"
      | "wait"
      | "cell"
      | "crosshair"
      | "text"
      | "vertical-text"
      | "alias"
      | "copy"
      | "move"
      | "no-drop"
      | "not-allowed"
      | "e-resize"
      | "n-resize"
      | "ne-resize"
      | "nw-resize"
      | "s-resize"
      | "se-resize"
      | "sw-resize"
      | "w-resize"
      | "ew-resize"
      | "ns-resize"
      | "nesw-resize"
      | "nwse-resize"
      | "col-resize"
      | "row-resize"
      | "all-scroll"
      | "zoom-in"
      | "zoom-out"
      | "grab"
      | "grabbing",
      string
    >
  >;

  m?: SpaceType;
  margin?: SpaceType;
  mt?: SpaceType;
  marginTop?: SpaceType;
  mr?: SpaceType;
  marginRight?: SpaceType;
  mb?: SpaceType;
  marginBottom?: SpaceType;
  ml?: SpaceType;
  marginLeft?: SpaceType;
  mx?: SpaceType;
  marginX?: SpaceType;
  my?: SpaceType;
  marginY?: SpaceType;
  p?: SpaceType;
  padding?: SpaceType;
  pt?: SpaceType;
  paddingTop?: SpaceType;
  pr?: SpaceType;
  paddingRight?: SpaceType;
  pb?: SpaceType;
  paddingBottom?: SpaceType;
  pl?: SpaceType;
  paddingLeft?: SpaceType;
  px?: SpaceType;
  paddingX?: SpaceType;
  py?: SpaceType;
  paddingY?: SpaceType;
  width?: SpaceType;
  minWidth?: SpaceType;
  height?: SpaceType;
  maxHeight?: SpaceType;
  color?: ColorType;
  bg?: ColorType;
  backgroundColor?: ColorType;
  left?: SpaceType;
  right?: SpaceType;
  top?: SpaceType;
  bottom?: SpaceType;
  textOverflow?: ResponsiveValue<
    LiteralUnion<"clip" | "ellipsis" | "initial" | "inherit", string>
  >;
  whiteSpace?: ResponsiveValue<
    LiteralUnion<
      | "normal"
      | "nowrap"
      | "pre"
      | "pre-line"
      | "pre-wrap"
      | "initial"
      | "inherit",
      string
    >
  >;
  zIndex?: string;
}

type BoxStyleProps = BaseBoxStyleProps &
  DisplayProps &
  VerticalAlignProps &
  SizeProps &
  OverflowProps &
  OpacityProps &
  FlexboxProps &
  GridProps &
  BackgroundProps &
  BorderProps &
  ShadowProps &
  TypographyProps;

// For future reference. If TS keeps beeing unbearably slow
// this is the reason. We can remove this and write the
// types ourselves. For now, it's bearable.
export type BoxProps = BaseBoxProps & BoxStyleProps;

const extraStyles = system({
  outline: true,
  outlineColor: {
    property: "outlineColor",
    scale: "colors",
  },
  outlineStyle: true,
  outlineWidth: true,
  cursor: true,
  whiteSpace: true,
  textOverflow: true,
  transform: true,
  transition: true,
  writingMode: true,
});

export const boxStylesFn = compose(
  space,
  color,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  typography,
  extraStyles
);

/**
 * A all-in-one tool to render a HTML with an assortment
 * of style props. The Box renders a div by default but you can change
 * the underlying element with a `as` prop.
 *
 * The available style props can be checked out at https://styled-system.com/api/
 *
 * The following prop groups are allowed: space, color, layout, flexbox, grid
 * background, border, position, shadow.
 *
 * This component implements all of styled-system style utils.
 *
 * It's also possible to use our values, defined in the theme.
 *
 * You can also pass a BoxProps compatible object to `hover`, `focus` and
 * `active` to handle these states.
 *
 * You can also pass a typography size key to `typography` prop, to use text styles.
 *
 * WARNING: Typescript support is limited on this component. As it accepts both
 * CSS valid syntax AND our own theme values, it can't autocomplete or validate
 * the values from our theme. It can autocomplete some limited rules like
 * `display` for example, but `padding` or `margin` are 100% variable values,
 * so those are not autcompleted. They are just validated against strings or
 * numbers.
 *
 * You can always inspect the theme object if desired, or check the Storybook.
 *
 */
const Box: FC<BoxProps> = styled.div.withConfig({
  shouldForwardProp: (prop, _defaultValidatorFn) =>
    ![
      ...(boxStylesFn.propNames || []),
      "cursor",
      "outline",
      "outlineColor",
      "outlineStyle",
      "hover",
      "focus",
      "active",
      "textOverflow",
      "whiteSpace",
    ].includes(prop),
})<BoxProps>`
  ${(props) =>
    props.as
      ? `
          all: initial;
          all: unset;
          display: block;
          box-sizing: border-box;
        `
      : ""}
  box-sizing: border-box;
  min-width: 0;

  ${boxStylesFn}

  &:hover {
    ${(props) =>
      props.hover ? boxStylesFn({ theme: props.theme, ...props.hover }) : ""}
  }

  &:focus {
    ${(props) =>
      props.focus ? boxStylesFn({ theme: props.theme, ...props.focus }) : ""}
  }

  &:active {
    ${(props) =>
      props.active ? boxStylesFn({ theme: props.theme, ...props.active }) : ""}
  }
`;

export default Box;
