import React from "react";
import styled from "styled-components";
import { Box } from "../design-system";
import Header from "./Header";
import Footer from "@/layout/Footer";

type Props = {
  children?: React.ReactNode;
  loginDisabled: boolean;
};

const ContentWrap = styled.div`
  flex: 1;
`;

export default function Layout({ children, loginDisabled }: Props) {
  return (
    <Box height="100%">
      <Header loginDisabled={loginDisabled} />

      <Box
        id="search-blurred-background"
        opacity="0"
        position="fixed"
        width="100%"
        height="100vh"
        backgroundColor="rgba(0,0,0,0.7)"
        css="backdrop-filter: blur(18px); pointer-events: none"
        top="0"
        left="0"
        zIndex="4"
        transition="all 0.3s ease"
      />
      <ContentWrap>{children}</ContentWrap>
      <Footer />
    </Box>
  );
}
