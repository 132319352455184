import React, { ReactNode } from "react";
import styled from "styled-components";
import theme from "root/config/theme";
import { Box } from "root/components/design-system";
import { ColStyled } from "./Col";

interface RowProps {
  children: ReactNode;
  noGutter?: boolean;
  auto?: boolean;
  className?: string;
}

const RowEl = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  justify-content: flex-start;
  flex-direction: row;

  &[data-no-gutter="false"] {
    margin-left: -${theme.grid.gutter / 2}px;
    margin-right: -${theme.grid.gutter / 2}px;
  }

  &[data-no-gutter="true"] {
    ${ColStyled} {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &[data-auto-cols="true"] {
    ${ColStyled} {
      flex: 1 0 0;
    }
  }
`;

export default function Row({
  noGutter = false,
  auto = false,
  children,
  className,
}: RowProps) {
  return (
    <RowEl
      className={className}
      data-no-gutter={noGutter}
      data-auto-cols={auto}
    >
      {children}
    </RowEl>
  );
}
