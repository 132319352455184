import React, { ReactElement } from "react";
import Link from "next/link";
import "@reach/menu-button/styles.css";
import styled from "styled-components";
import { Box, Flex, Typography } from "root/components/design-system";
import theme from "root/config/theme";
import { up } from "styled-breakpoints";
import { ExternalRoutes, Routes } from "types/routeTypes";
import useHasGiveaway from "root/hooks/useHasGiveaway";
import { useRouter } from "next/router";
import FeatherIcon from "root/components/icons/FeatherIcon";
import GridIcon from "root/components/icons/GridIcon";
import AboutUsIcon from "root/components/icons/AboutUsIcon";
import PenTool from "root/components/icons/PenTool";
import HelpCircle from "root/components/icons/HelpCircle";
import User from "root/components/icons/User";
import GiftIcon from "root/components/icons/GiftIcon";
import MediumLogo from "root/components/icons/MediumLogo";
import MobileMenu from "root/components/Layout/MobileMenu";
import ArtistsIcon from "root/components/icons/ArtistsIcon";
import useHeaderLayoutStore from "root/hooks/useHeaderLayoutStore";
import Discord from "../icons/Discrod";
import Twitter from "../icons/Twitter";
import Instagram from "../icons/Instagram";
import DropdownMenu from "../DropdownMenu";

const GiveawayCount = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 7px;
  font-weight: ${theme.fontWeights.bold};
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  border-radius: 50%;
  z-index: 2;
  background-color: #ffb249;
  color: white;

  right: 5px;
  bottom: 8px;
  font-size: 10px;
  width: 10px;
  height: 10px;
`;

const TopLevelLink = styled.a`
  display: block;
  font-size: 12px;
  color: ${theme.colors.neutralColor};

  transition: color 0.3s ease;

  &[data-active="true"] {
    font-weight: ${theme.fontWeights.bold};
    color: #000;

    &[data-dark="true"] {
      color: #fff;
    }
  }

  ${up("md")} {
    margin: 0 20px;
    font-weight: ${theme.fontWeights.regular};

    &[data-user="false"] {
      margin: 0 50px;
    }
  }

  ${up("lg")} {
    font-size: 14px;
  }

  ${up("xl")} {
    font-size: 20px;
  }

  ${up("xxl")} {
    margin: 0 50px;
  }

  :hover,
  :focus {
    color: #000;

    &[data-dark="true"] {
      color: #fff;
    }
  }
`;

const SubMenuLink = styled.a`
  display: grid;
  grid-template-columns: 25px 1fr;
  font-weight: ${theme.fontWeights.bold};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
  color: ${theme.colors.shadeBlack};

  :last-of-type {
    margin-bottom: 0px;
  }

  ${up("x3l")} {
    font-size: 20px;
    margin-bottom: 28px;
  }

  :hover,
  :focus {
    path {
      stroke: ${theme.colors.blue};
    }

    color: ${theme.colors.blue};
  }
`;

const SocialLinks = styled(Flex)`
  a {
    display: flex;
    width: 20px;
    height: 20px;
    margin-right: 20px;

    :hover,
    :focus {
      path {
        fill: ${theme.colors.blue};
      }
    }

    :last-of-type {
      margin-right: 0;
    }

    ${up("xxl")} {
      margin-right: 16px;

      :last-of-type {
        margin-right: 0px;
      }
    }
  }

  svg {
    margin: auto;
  }
`;

export const EXPLORE_SUBMENU_LINKS = [
  {
    name: "Collections",
    href: Routes.COLLECTIONS,
    icon: <GridIcon />,
  },
  {
    name: "Artworks",
    href: Routes.ARTWORKS,
    icon: <FeatherIcon />,
  },
];

export const COMMUNITY_SUBMENU_LINKS = [
  {
    name: "About us",
    href: Routes.MANIFESTO,
    icon: <AboutUsIcon />,
  },
  {
    name: "Artists",
    href: Routes.ARTISTS,
    icon: <ArtistsIcon />,
  },
  {
    name: "Editorial",
    href: Routes.EDITORIAL,
    icon: <PenTool />,
  },
  {
    name: "Help Center",
    href: Routes.FAQ,
    icon: <HelpCircle />,
  },
  {
    name: "Submit Profile",
    href: ExternalRoutes.ARTIST_TYPEFORM,
    icon: <User />,
  },
];

export const SOCIAL_LINKS = [
  {
    href: ExternalRoutes.INSTAGRAM,
    render: <Instagram />,
  },
  {
    href: ExternalRoutes.TWITTER,
    render: <Twitter />,
  },
  {
    href: ExternalRoutes.DISCORD,
    render: <Discord />,
  },
  {
    href: ExternalRoutes.MEDIUM,
    render: <MediumLogo />,
  },
];

const SubMenuIconWrapper = styled(Box)`
  align-content: center;
  height: 16px;
`;

interface SubmenuLinkItemProps {
  href: string;
  name: string;
  icon?: ReactElement<any, any>;
}

export function SubmenuLinkItem({ href, name, icon }: SubmenuLinkItemProps) {
  return (
    <Link href={href} passHref key={href}>
      <SubMenuLink>
        {icon && <SubMenuIconWrapper>{icon}</SubMenuIconWrapper>}
        <span>{name}</span>
      </SubMenuLink>
    </Link>
  );
}

export function SocialLinksList() {
  return (
    <>
      {SOCIAL_LINKS.map((item) => (
        <Link href={item.href} passHref key={item.href}>
          <a target="_blank" rel="noreferrer">
            {item.render}
          </a>
        </Link>
      ))}
    </>
  );
}

function CommunityDropDownMenu() {
  const giveawaySlug = useHasGiveaway();

  return (
    <DropdownMenu label="Community">
      <>
        {giveawaySlug && (
          <Flex>
            <SubmenuLinkItem
              name="Giveaways"
              href={`/giveaways/${giveawaySlug}`}
              icon={<GiftIcon />}
            />
            <GiveawayCount>1</GiveawayCount>
          </Flex>
        )}
        {COMMUNITY_SUBMENU_LINKS.map((link) => (
          <SubmenuLinkItem {...link} key={link.href} />
        ))}
        <Typography mt={24} fontSize="14px" lineHeight="12px" fontWeight={200}>
          Follow us on
        </Typography>
        <SocialLinks
          alignItems="center"
          justifyContent="space-between"
          mt="10px"
        >
          <SocialLinksList />
        </SocialLinks>
      </>
    </DropdownMenu>
  );
}

export default function HeaderMenu({ user }) {
  const router = useRouter();

  const { darkMode } = useHeaderLayoutStore();

  return (
    <>
      <Flex
        display={{ _: "none", md: "flex" }}
        flex="1 0 auto"
        justifyContent={{ _: "center", lg: "center" }}
        maxWidth="360px"
      >
        <Box>
          <DropdownMenu label="Explore">
            {EXPLORE_SUBMENU_LINKS.map((link) => (
              <SubmenuLinkItem {...link} key={link.href} />
            ))}
          </DropdownMenu>
        </Box>

        <Link href={Routes.DROP} passHref>
          <TopLevelLink
            data-user={!!user}
            data-dark={darkMode}
            data-active={Routes.DROP === router.asPath}
          >
            Drops
          </TopLevelLink>
        </Link>

        <Box>
          <CommunityDropDownMenu />
        </Box>
      </Flex>
      <MobileMenu />
    </>
  );
}
