import React from "react";

export default function CircledClose() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5Z"
        fill="black"
      />
      <path
        d="M8.50423 9.95694L5.76056 12.7006L4.34634 11.2864L7.09001 8.54273L4 5.45271L5.41421 4.0385L8.50423 7.12851L11.6327 4L13.047 5.41421L9.91844 8.54272L12.7006 11.3249L11.2864 12.7391L8.50423 9.95694Z"
        fill="white"
      />
    </svg>
  );
}
