import { ERCStandard, RegisteredContract } from "root/lib/graphqlDefs";
import { ethers, providers } from "ethers";

const ERC721_ABI = [
  "function isApprovedForAll(address owner, address operator) external view returns (bool)",
  "function setApprovalForAll(address operator, bool _approved) external",
];

const ERC1155_ABI = [
  "function isApprovedForAll(address account, address operator) external view returns (bool)",
  "function setApprovalForAll(address operator, bool approved) external",
];

interface IApproveCheck extends RegisteredContract {
  saleContractAddress: string;
}

export interface NFTContracts {
  isApproveForAll: (params: IApproveCheck) => Promise<boolean>;
  setApproveForAll: (
    params: IApproveCheck
  ) => Promise<providers.TransactionResponse>;
}

function getABI(ercType: ERCStandard) {
  return ercType === "ERC721" ? ERC721_ABI : ERC1155_ABI;
}
export default async function buildNFTContracts(
  provider: ethers.providers.Web3Provider
): Promise<NFTContracts> {
  const signer = provider.getSigner();

  return {
    isApproveForAll(params: IApproveCheck) {
      const contract = new ethers.Contract(
        params.address,
        getABI(params.contractType),
        signer
      );

      return contract.isApprovedForAll(
        signer.getAddress(),
        params.saleContractAddress
      );
    },
    setApproveForAll(params: IApproveCheck) {
      const contract = new ethers.Contract(
        params.address,
        getABI(params.contractType),
        signer
      );

      return contract.setApprovalForAll(params.saleContractAddress, true);
    },
  };
}
