import React from "react";
import cloudinary from "root/lib/cloudinary";
import Image from "next/image";
import { ImageProps } from "next/dist/client/image";

interface CloudinaryProps extends Omit<ImageProps, "src"> {
  publicId: string;
}

export default function CloudinaryImage({
  publicId,
  ...props
}: CloudinaryProps): JSX.Element {
  const cldImg = cloudinary.image(publicId);

  return <Image src={cldImg.toURL()} {...props} />;
}
