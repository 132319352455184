import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Container, Flex } from "root/components/design-system";
import Lottie from "react-lottie";
import { Transition } from "react-transition-group";
import siteLoading from "root/animations/siteLoading.json";
import useIsTransitioning from "root/hooks/useIsTransitioning";
import { Routes } from "types/routeTypes";
import MarketplaceLoader from "root/modules/Marketplace/MarketplaceLoader";
import { useRouter } from "next/router";
import theme from "root/config/theme";
import CollectionListLoader from "root/modules/Collections/CollectionListLoader";
import AssetLoader from "root/modules/AssetDetailsPage/AssetLoader";
import HomepageLoader from "./UserMenu/Skeleton/HomepageLoader";
import CollectionBannerSkeleton from "./UserMenu/Skeleton/CollectionBannerSkeleton";
import FeaturedArtworkSkeleton from "./UserMenu/Skeleton/FeaturedArtworkSkeleton";

const Wrapper = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 30;
  opacity: 0;

  transition: opacity 500ms ease-in-out 500ms;

  *[data-loader] {
    position: fixed;
    z-index: 30;
    height: 102vh;
    width: 100%;
    overflow: scroll;
    background: #fff;

    transition: opacity 1000ms ease-in-out;

    ::-webkit-scrollbar {
      opacity: 0;
    }

    scrollbar-color: #fff #fff;
  }

  &[data-first-time="true"] {
    opacity: 1;
  }

  &[data-state="entering"] {
    transition: opacity 100ms ease-in-out;
    opacity: 1;

    *[data-loader] {
      transition: opacity 100ms ease-in-out;
      opacity: 1;
    }
  }

  &[data-state="entered"] {
    opacity: 1;

    *[data-loader] {
      opacity: 1;
    }
  }

  &[data-state="exiting"] {
    opacity: 1;

    *[data-loader] {
      overflow: hidden;
      opacity: 1;
    }
  }

  &[data-state="exited"] {
    pointer-events: none;
    opacity: 0;

    *[data-loader] {
      overflow: hidden;
      opacity: 0;
    }
  }
`;

interface SiteLoaderProps {
  dateTime?: string;
  forceBannerCollectionLive?: boolean;
  forceDropLive?: boolean;
  forceBannerEditorialLive?: boolean;
}

export default function SiteLoader({ dateTime, ...props }: SiteLoaderProps) {
  const { transitioning, transitioningTo } = useIsTransitioning();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: siteLoading,
  };
  const [firstTime, setFirstTime] = useState(true);
  const homepageNotice = useRef("");

  const router = useRouter();

  let destinationPath = transitioningTo || router.asPath;

  function handleExited() {
    setFirstTime(false);
  }

  useEffect(() => {
    if (transitioning) {
      document.body.classList.add("no-scroll");
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [transitioning]);

  const { forceBannerCollectionLive, forceDropLive, forceBannerEditorialLive } =
    props;

  function getLoader() {
    // If the path contains @.../...[number], it's an asset page
    if (
      destinationPath.match(/@.*\/.*\d/g) ||
      destinationPath.includes("/assets/")
    ) {
      destinationPath = "asset";
    }

    switch (destinationPath) {
      case Routes.HOMEPAGE:
        homepageNotice.current = dateTime || homepageNotice.current;

        if (forceDropLive || forceBannerEditorialLive) {
          return <HomepageLoader />;
        }

        if (forceBannerCollectionLive) {
          return (
            <Container>
              <CollectionBannerSkeleton />
            </Container>
          );
        }

        return <FeaturedArtworkSkeleton />;

      case Routes.ARTWORKS:
        return <MarketplaceLoader />;
      case Routes.COLLECTIONS:
        return <CollectionListLoader />;

      case Routes.MANIFESTO:
        return (
          <Flex data-loader>
            <Flex
              m="auto"
              bg="blue"
              width={1}
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Box css="filter: invert(1)">
                <Lottie options={defaultOptions} width={100} height={50} />
              </Box>
            </Flex>
          </Flex>
        );

      case "asset":
        return <AssetLoader />;

      default:
        return (
          <Flex data-loader height="102vh">
            <Box m="auto">
              <Lottie options={defaultOptions} width={100} height={50} />
            </Box>
          </Flex>
        );
    }
  }

  return (
    <Transition in={transitioning} timeout={1500} onExited={handleExited}>
      {(state) => (
        <Wrapper
          data-first-time={firstTime}
          data-state={state}
          pt={{ _: "78px", xl: "92px" }}
          bg={
            destinationPath === Routes.MANIFESTO ? theme.colors.blue : "white"
          }
        >
          {getLoader()}
        </Wrapper>
      )}
    </Transition>
  );
}
