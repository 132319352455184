import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Box } from "root/components/design-system";
import MuxVideo, { MuxVideoProps } from "./MuxVideo";

/**
 * This component acts as a copy of MuxVideo that auto pauses when
 * hidden.
 *
 * It also only loads when it's in view. Use it for galleries
 * and such were we have lots of videos and big long pages.
 */
export default function MuxVideoOptimized({
  onCanPlay,
  playbackId,
  fallbackSrc,
  loadLevel = 2,
  ...props
}: Omit<MuxVideoProps, "ref">) {
  const [ref, inView] = useInView();
  const videoRef = useRef<HTMLVideoElement>();
  const [isInViewOnce, setIsInViewOnce] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const videoPlayPromiseRef = useRef<Promise<unknown>>();

  function handleInitialLoad(
    event: React.SyntheticEvent<HTMLVideoElement, Event>
  ) {
    setIsLoaded(true);

    // If somehow the video loaded and is not in view, pause immediatly
    // probably the user scrolled to far down
    if (!inView) videoRef?.current?.pause();

    if (onCanPlay) onCanPlay(event);
  }

  useEffect(() => {
    async function handleInView() {
      if (inView && !isInViewOnce) setIsInViewOnce(true);

      // If video is not loaded let it load normally
      // otherwise the below functions will throw errors
      if (!isLoaded) return;

      // If we play or pause with a in progress play request
      // we throw an exception. This fixes it.
      await videoPlayPromiseRef?.current;

      if (inView && videoRef?.current?.paused) {
        videoPlayPromiseRef.current = videoRef?.current
          ?.play()
          ?.catch((_error) => {
            /*
               Ignore the error, sometimes we might have
               race conditions with onload events but
               browsers seem to handle it fine. If users
               have problems we are going to be quick
               to notice (hopefully!)
            */
          });
      } else {
        videoRef?.current?.pause();
      }
    }

    handleInView();
  }, [inView, isInViewOnce, isLoaded]);

  return (
    <>
      <Box ref={ref} position="absolute" top="0" height="100%" width={1} />
      <MuxVideo
        {...props}
        ref={videoRef}
        loadLevel={loadLevel}
        onCanPlay={handleInitialLoad}
        playbackId={isInViewOnce ? playbackId : undefined}
        fallbackSrc={isInViewOnce ? fallbackSrc : undefined}
      />
    </>
  );
}
