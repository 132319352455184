import React, { useRef } from "react";
import { Menu, MenuButton, MenuPopover } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import styled from "styled-components";
import { Box } from "root/components/design-system";
import theme from "root/config/theme";
import { up } from "styled-breakpoints";
import useHeaderLayoutStore from "root/hooks/useHeaderLayoutStore";

const StyledMenuButton = styled(MenuButton)`
  display: block;
  padding: 0;
  font-size: 25px;
  font-weight: ${theme.fontWeights.bold};
  text-align: left;
  color: ${theme.colors.neutralColor};
  border: 0;
  background: none;

  transition: color 0.3s ease;

  :hover,
  :focus {
    color: #000;

    &[data-dark="true"] {
      color: #fff;
    }
  }

  ${up("md")} {
    font-size: 12px;
    font-weight: ${theme.fontWeights.regular};
    text-align: center;
  }

  ${up("lg")} {
    font-size: 14px;
  }

  ${up("xl")} {
    display: inline-flex;
    align-items: center;
    margin: 0;
    font-size: 20px;
    text-align: center;
  }
`;

const NewStyledMenuBox = styled(Box)`
  padding: 20px;
  z-index: 1;
  background: white;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const StyledMenuPopover = styled(MenuPopover)`
  z-index: 1;
  margin-top: 16px;
`;

export default function DropdownMenu({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  const { darkMode } = useHeaderLayoutStore();
  const popoverRef = useRef<HTMLDivElement>();
  const menuButtonRef = useRef<HTMLButtonElement>();
  const isOverButton = useRef(false);
  const isOverPopover = useRef(false);

  function handleMouseEnter() {
    isOverButton.current = true;
    popoverRef?.current?.removeAttribute("hidden");
    menuButtonRef?.current?.setAttribute("aria-expanded", "true");
  }

  function handleMouseLeave() {
    isOverButton.current = false;

    setTimeout(() => {
      if (!isOverPopover.current) {
        popoverRef?.current?.setAttribute("hidden", "");
        menuButtonRef?.current?.removeAttribute("aria-expanded");
      }
    }, 200);
  }

  function handleMouseEnterPopover() {
    isOverPopover.current = true;

    popoverRef?.current?.removeAttribute("hidden");
    menuButtonRef?.current?.setAttribute("aria-expanded", "true");
  }

  function handleMouseLeavePopover() {
    isOverPopover.current = false;

    setTimeout(() => {
      if (!isOverButton.current) {
        popoverRef?.current?.setAttribute("hidden", "");
        menuButtonRef?.current?.removeAttribute("aria-expanded");
      }
    }, 200);
  }

  return (
    <Menu>
      <StyledMenuButton
        ref={menuButtonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={(ev) => ev.preventDefault()}
        data-dark={darkMode}
      >
        {label}
      </StyledMenuButton>

      <StyledMenuPopover
        portal={false}
        ref={popoverRef}
        onMouseEnter={handleMouseEnterPopover}
        onMouseLeave={handleMouseLeavePopover}
      >
        <NewStyledMenuBox>{children}</NewStyledMenuBox>
      </StyledMenuPopover>
    </Menu>
  );
}
