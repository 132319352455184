import React from "react";
import Link from "next/dist/client/link";
import { Routes } from "types/routeTypes";
import useServerState from "root/hooks/useServerState";
import useConnectModal from "root/hooks/useConnectModal";
import useHeaderLayoutStore from "root/hooks/useHeaderLayoutStore";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import theme from "root/config/theme";
import { Flex, Box, Hidden } from "../design-system";
import Logo from "../Logo";
import UserMenu from "../UserMenu";
import ConnectModal from "../ConnectModal";
import HeaderMenu from "./HeaderMenu";
import AlgoliaSearch from "../FullSearch/AlgoliaSearch";

const HeaderContainer = styled(Flex)`
  column-gap: 10px;

  ${up("md")} {
    column-gap: 20px;
  }

  ${up("xl")} {
    column-gap: 30px;
  }
`;

const StyledButton = styled.button`
  padding: 6px 16px;
  font-family: ${theme.fonts.abcdiatype};
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  font-weight: ${theme.fontWeights.regular};
  text-transform: none;
  background: #fff;
  border: 1px solid #000;
  border-radius: 100px;
  transition: all 0.3s ease;

  ${up("lg")} {
    font-size: 14px;
  }

  ${up("xl")} {
    padding: 9px 28px;
    font-size: 20px;
    line-height: 25px;
  }

  :hover,
  :focus {
    background: #000;
    color: #fff;
  }
`;

export default function Header({ loginDisabled }) {
  const { user } = useServerState();
  const { startConnect, showConnectModal } = useConnectModal();

  const { showHeader, absolute, darkMode } = useHeaderLayoutStore();

  return (
    <>
      <Box
        position={absolute ? "absolute" : "relative"}
        width="100%"
        bg={darkMode ? "#000" : "white"}
        zIndex="50"
        transform={showHeader ? `translateY(0)` : `translateY(-99.9%)`}
        transition="all 0.3s ease"
        data-header
      >
        <HeaderContainer
          alignItems="center"
          justifyContent="space-between"
          mx="auto"
          py="20px"
          px={{ _: "16px", xl: "80px" }}
          maxWidth={1440}
        >
          <Box>
            <Link href={Routes.HOMEPAGE} passHref>
              <a style={{ display: "block", width: "fit-content" }}>
                <Logo dark={darkMode} />
              </a>
            </Link>
          </Box>

          <Box
            flex={{ md: "0 1 280px", lg: "0 1 480px" }}
            marginLeft={{ _: "auto", md: "0" }}
          >
            <AlgoliaSearch />
          </Box>

          <HeaderMenu user={user} />

          <Hidden sm>
            {!loginDisabled && (
              <>
                {user ? (
                  <UserMenu user={user} />
                ) : (
                  <StyledButton type="button" onClick={startConnect}>
                    Connect
                  </StyledButton>
                )}
              </>
            )}
          </Hidden>
        </HeaderContainer>
      </Box>
      {showConnectModal && <ConnectModal />}
    </>
  );
}
