import { normalize } from "styled-normalize";
import { createGlobalStyle } from "styled-components";
import "@reach/menu-button/styles.css";
import "@reach/tabs/styles.css";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
    ${normalize}

    *, &::after, &::before {
      box-sizing: border-box;
    }

    html {
      height: 100%;
    }

    body {
      height: 100%;
      font-family: ${theme.fonts.abcdiatype};
      font-weight:  ${theme.fontWeights.medium};
      overflow-x: hidden;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;

      cursor: url(/images/cursorRegular.svg), auto;
    }

    a, button, [data-cursor-pointer="true"] {
      cursor: url(/images/cursorPointer.svg), pointer;
    }

    [data-cursor-drag="true"],
    .indiana-dragging,
    .indiana-scroll-container--dragging {
      cursor: url(/images/cursorDrag.svg), move;
    }

    .no-cursor {
      cursor: none;

      a, button, [data-cursor-pointer="true"] {
        cursor: none;
      }

      [data-cursor-drag="true"], html .indiana-dragging {
        cursor: none;
      }
    }

    .loading-cursor {
      display: none;
      position: fixed;
      left: -30px;
      top: -30px;
      z-index: 9999;
    }

    .loading-cursor svg {
      animation: rotate 2s linear infinite;
    }

    @keyframes rotate {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }

    @media screen and (min-width: 1300px) {
      .loading-cursor {
        display: block;
      }
    }

    #__next {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    a {
      text-decoration: none;
      color: #000;
    }

    a:focus-visible {
      outline-style: dotted;
      outline-color: ${theme.colors["gray-50"]};
    }

    button:focus-visible {
      outline-style: dotted;
      outline-color: ${theme.colors["gray-50"]};
    }

    [data-underliner] {
      background: 
        linear-gradient(currentColor 0 0) 
        var(--d, 201%) 100% /200% 2px 
        no-repeat;
    }
    [data-underliner]:hover {
      --d: -101%;
      transition: 0.5s
    }

    ul {
      margin: 0;
      padding: 0;
    }

    h1,h2,h3,h4,h5,h6 {
      font-weight: unset;
      margin: 0;
    }    

    @media screen and (min-width: 768px) {
      .smooth-scroll {
        height: 100%;
        overflow: hidden;
      }
    }

    .no-scroll {
      height: 100%;
      overflow: hidden;
    }
`;

export default GlobalStyle;
