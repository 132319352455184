import React from "react";
import styled from "styled-components";
import theme from "root/config/theme";
import Image from "next/image";
import stringToColor from "root/utils/stringToColor";
import cidToUrl from "root/utils/cidToUrl";
import { PublicUserType } from "root/lib/graphqlDefs";
import { Box } from "./design-system";

const Avatar = styled(Box)`
  position: relative;
  background-color: ${theme.colors.grays["10"]};
  border-radius: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &[data-with-border="true"] {
    border: 2px solid #000;
  }

  &[data-dark="true"] {
    border-color: #fff;
  }
`;

export interface UserAvatarProps {
  user: PublicUserType;
  withBorder?: boolean;
  dark?: boolean;
  size?: any;
}

export default function UserAvatar({
  user,
  withBorder,
  dark,
  size,
}: UserAvatarProps) {
  const background = user.avatarCid
    ? theme.colors["gray-40"]
    : user.avatarColor || stringToColor(user.username);

  return (
    <Avatar
      width={size || "100%"}
      height={size || "100%"}
      background={background}
      data-dark={dark}
      data-with-border={withBorder}
    >
      {user.avatarCid && (
        <Image
          css="border-radius: 100px;"
          src={cidToUrl(user.avatarCid)}
          alt={`${user.name} avatar`}
          layout="fill"
          objectFit="cover"
        />
      )}
    </Avatar>
  );
}
