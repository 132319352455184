import styled from "styled-components";
import {
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  ResponsiveValue,
  system,
  ThemeValue,
} from "styled-system";
import theme from "root/config/theme";
import Box from "./Box";

interface BaseFlexProps {
  gap?: ResponsiveValue<ThemeValue<"space", typeof theme> | string>;
}

export type FlexProps = BaseFlexProps & FlexboxProps & DisplayProps;

const gap = system({
  gap: {
    properties: ["margin"],
    scale: "space",
    transform: (value, scale) => {
      if (!scale) return value;

      if (value === "auto") return `${value} !important`;

      const scaleValue = scale[value] ? `${scale[value]}px` : value;

      return `calc(${scaleValue} / 2) !important`;
    },
  },
});

const negativeGap = system({
  gap: {
    properties: ["margin"],
    scale: "space",
    transform: (value, scale) => {
      if (!scale) return value;

      if (value === "auto") return value;

      const scaleValue = `${scale[value]}px` || value;

      return `calc(${scaleValue} / -2) !important`;
    },
  },
});

const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${display}
  ${flexbox}
  ${negativeGap};

  & > * {
    ${gap}
  }
`;

export default Flex;
