import React from "react";
import { Box } from "../design-system";

export default function ChevronIcon({
  left = false,
  right = false,
  width,
  height,
  color = "#fff",
}) {
  if (left) {
    return (
      <Box
        as="svg"
        width={width}
        height={height}
        viewBox="0 0 26 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8787 44.2132L1.7868 25.1214C0.615225 23.9498 0.615225 22.0503 1.7868 20.8787L20.8787 1.78683C22.0503 0.615257 23.9498 0.615257 25.1213 1.78683C26.2929 2.9584 26.2929 4.8579 25.1213 6.02947L8.15076 23L25.1213 39.9706C26.2929 41.1422 26.2929 43.0417 25.1213 44.2132C23.9497 45.3848 22.0503 45.3848 20.8787 44.2132Z"
          fill={color}
        />
      </Box>
    );
  }

  if (right) {
    return (
      <Box
        as="svg"
        width={width}
        height={height}
        viewBox="0 0 26 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.02952 1.78682L25.1214 20.8787C26.293 22.0503 26.293 23.9498 25.1214 25.1213L6.02952 44.2132C4.85795 45.3848 2.95846 45.3848 1.78688 44.2132C0.61531 43.0417 0.61531 41.1422 1.78688 39.9706L18.7574 23L1.78688 6.02946C0.61531 4.85789 0.61531 2.9584 1.78688 1.78682C2.95846 0.615249 4.85795 0.615249 6.02952 1.78682Z"
          fill={color}
        />
      </Box>
    );
  }

  return null;
}
