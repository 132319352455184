import React from "react";

export default function User({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3332 14.5V13.1667C13.3332 12.4594 13.0522 11.7811 12.5521 11.281C12.052 10.781 11.3737 10.5 10.6665 10.5H5.33317C4.62593 10.5 3.94765 10.781 3.44755 11.281C2.94746 11.7811 2.6665 12.4594 2.6665 13.1667V14.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00016 7.83333C9.47292 7.83333 10.6668 6.63943 10.6668 5.16667C10.6668 3.69391 9.47292 2.5 8.00016 2.5C6.5274 2.5 5.3335 3.69391 5.3335 5.16667C5.3335 6.63943 6.5274 7.83333 8.00016 7.83333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
