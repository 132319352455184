import React from "react";

export default function FeatherIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4935 8.66C14.2441 7.90944 14.6657 6.89145 14.6657 5.83C14.6657 4.76854 14.2441 3.75056 13.4935 3C12.7429 2.24944 11.725 1.82777 10.6635 1.82777C9.60204 1.82777 8.58406 2.24944 7.8335 3L3.3335 7.5V13.1667H9.00016L13.4935 8.66Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6668 5.83334L1.3335 15.1667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 10.5H6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
