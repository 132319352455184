import React, { useState, useEffect } from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { up } from "styled-breakpoints";
import "@reach/dialog/styles.css";
import styled, { keyframes } from "styled-components";
import Close from "root/components/icons/Close";
import theme from "root/config/theme";

export const enterAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  50% { opacity: 1; }
  100% { transform: translateY(0); }
`;

export const exitAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-200px);
  }
`;

const Overlay = styled(DialogOverlay)`
  display: flex;
  z-index: 1000;
  transition: opacity 0.4s ease;
`;

const DialogWrap = styled(DialogContent)`
  position: relative;
  border: 1px solid ${theme.colors.blue};
  padding: 20px;
  margin: auto;
  width: 90%;

  &[data-closing="false"] {
    animation: ${enterAnimation} 0.5s ease-out forwards;
  }

  &[data-closing="true"] {
    animation: ${exitAnimation} 0.3s ease-out forwards;
  }

  ${up("md")} {
    width: 70%;
  }
  ${up("lg")} {
    width: 680px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  background: ${theme.colors["gray-30"]};
  border: 0;
  border-radius: 50%;
  padding: 2px;
  display: flex;

  svg {
    width: 70%;
    height: 70%;
    margin: auto;
  }
`;

interface DialogModalProps {
  children: React.ReactNode;
  label: string;
  onDismiss?: () => void;
  color?: string;
  closing: boolean;
  dialogStyle?: React.CSSProperties;
  withCloseButton?: boolean;
}

export default function DialogModal({
  label,
  onDismiss,
  children,
  color,
  closing,
  dialogStyle = {},
  withCloseButton = true,
}: DialogModalProps) {
  function emptyOnDismiss() {
    // no-op
  }
  const [overlayOpacity, setOverlayOpacity] = useState(0);

  useEffect(() => {
    if (closing) setOverlayOpacity(0);
    else {
      setTimeout(() => setOverlayOpacity(1), 10);
    }
  }, [closing]);

  return (
    <Overlay
      onDismiss={onDismiss || emptyOnDismiss}
      style={{ opacity: overlayOpacity }}
    >
      <DialogWrap
        aria-label={label}
        style={{
          borderColor: color || "blue",
          ...dialogStyle,
        }}
        data-closing={closing}
      >
        {onDismiss && withCloseButton && (
          <CloseButton type="button" onClick={onDismiss || emptyOnDismiss}>
            <Close stroke="#fff" />
          </CloseButton>
        )}
        {children}
      </DialogWrap>
    </Overlay>
  );
}
