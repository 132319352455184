import create, { GetState, SetState } from "zustand";
import orderBy from "lodash/orderBy";
import isEqual from "lodash/isEqual";
import { updateNotification } from "root/lib/api";
import { combine } from "zustand/middleware";
import { NotificationType } from "root/lib/graphqlDefs";
import { getNotifications } from "root/lib/graphqlQueries";

let existingInterval;

interface StateType {
  notifications?: Map<string, NotificationType>;
  eventSource?: EventSource;
}

const defaultState: StateType = {
  notifications: new Map(),
  eventSource: null,
};
const actions = (set: SetState<StateType>, get: GetState<StateType>) => {
  async function initialize(notifications: NotificationType[]) {
    set({
      notifications: new Map(
        notifications.map((notification) => [notification.id, notification])
      ),
    });

    async function fetchNotifications() {
      const newNotifications = await getNotifications();

      set(() => ({
        notifications: new Map(
          newNotifications.map((notification) => [
            notification.id,
            notification,
          ])
        ),
      }));
    }

    if (existingInterval) clearInterval(existingInterval);

    existingInterval = setInterval(fetchNotifications, 5000);
  }

  async function deleteNotifications(ids: string[]) {
    const updatedNotifications = await Promise.all(
      ids.map((id) => updateNotification(id, { isRead: true }))
    );

    set((state) => {
      const newNotifications = state.notifications;

      updatedNotifications.forEach((notification) => {
        newNotifications.set(notification.id, notification);
      });

      return {
        notifications: newNotifications,
      };
    });
  }

  async function markAllAsRead() {
    const unreadNotifications = Array.from(get().notifications.values()).filter(
      (notification) => !notification.isRead
    );

    deleteNotifications(
      unreadNotifications.map((notification) => notification.id)
    );
  }

  return { initialize, deleteNotifications, markAllAsRead };
};

const useNotificationsStore = create(combine(defaultState, actions));

export const useNotificationsInitializer = () =>
  useNotificationsStore((state) => state.initialize);

export default function useNotifications() {
  const notifications = useNotificationsStore(
    (state) =>
      orderBy(Array.from(state.notifications.values()), "createdAt", "desc"),
    (oldNotifs, newNotifs) => isEqual(oldNotifs, newNotifs)
  );
  const deleteNotifications = useNotificationsStore(
    (state) => state.deleteNotifications
  );
  const markAllAsRead = useNotificationsStore((state) => state.markAllAsRead);

  return {
    notifications,
    unreadNotifications: notifications.filter(
      (notification) => !notification.isRead
    ),
    deleteNotifications,
    markAllAsRead,
  };
}
