import { Menu, MenuButton } from "@reach/menu-button";
import React from "react";
import theme from "root/config/theme";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { Box, Flex } from "root/components/design-system";
import useNotifications from "root/hooks/useNotifications";
import { NotificationType, PrivateUserType } from "root/lib/graphqlDefs";
import UserMenuPopOver from "root/components/UserMenu/UserMenu";
import AccountButton from "./AccountButton";
import Star from "../icons/Star";

const StyledMenuButton = styled(MenuButton)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 50%;
  background: #fff;
  z-index: 2;

  transition: all 0.3s ease;

  svg {
    margin: auto;
    transition: all 0.3s ease;
  }

  :focus-visible,
  :hover {
    border-color: ${theme.colors.blue};

    svg {
      fill: ${theme.colors.blue};
    }
  }
`;

const Count = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  top: 0;
  bottom: 0;
  font-weight: ${theme.fontWeights.bold};
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  border-radius: 50%;
  z-index: 2;
  background-color: ${theme.colors.blue};
  color: white;
  font-size: 8px;

  ${up("md")} {
    left: -30%;
    width: 16px;
    height: 16px;
  }

  ${up("xl")} {
    left: -40%;
    font-size: 16px;
    width: 28px;
    height: 28px;
  }
`;

function InnerUserMenu({
  user,
  unreadNotifications,
}: {
  user: PrivateUserType;
  unreadNotifications: NotificationType[];
}) {
  return (
    <Box
      position="relative"
      width={{ _: "28px", xl: "42px", x3l: "54px" }}
      height={{ _: "28px", xl: "42px", x3l: "54px" }}
      mr={{ _: "4px", xl: "8px" }}
      flex="1 0 auto"
    >
      <StyledMenuButton>
        <Star />
        {unreadNotifications.length > 0 && (
          <Count>{unreadNotifications.length}</Count>
        )}
      </StyledMenuButton>
      <UserMenuPopOver user={user} />
    </Box>
  );
}

interface UserMenuProps {
  user: PrivateUserType;
}

export default function UserMenu({ user }: UserMenuProps) {
  const { unreadNotifications } = useNotifications();

  return (
    <Flex ml={unreadNotifications.length > 0 ? "12px" : "0"}>
      <Menu>
        <InnerUserMenu user={user} unreadNotifications={unreadNotifications} />
      </Menu>
      <Box flex="0 1 auto">
        <AccountButton user={user} />
      </Box>
    </Flex>
  );
}
