import { useRouter } from "next/router";

export default function useServerRefresher(): () => void {
  const router = useRouter();

  const hasHash = router.asPath.split("#").length > 1;

  if (hasHash) {
    return () => router.reload();
  }

  return () =>
    router.replace(router.asPath, router.asPath, {
      scroll: false,
    });
}
