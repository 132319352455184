import processStr from "root/utils/processStringToSlug";

function isDissrupContract(contractAddress) {
  return (
    contractAddress?.toLowerCase() ===
    process?.env?.ASSET_CONTRACT?.toLowerCase()
  );
}

export const Routes = {
  HOMEPAGE: "/",
  TRENDING: "/trending",
  ARTWORKS: "/artworks",
  COLLECTIONS: "/collections",
  EDITORIAL: "/editorial",
  ARTISTS: "/artists",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_SERVICE: "/terms-of-service",
  MANIFESTO: "/manifesto",
  CAREERS: "/careers",
  FAQ: "/faqs",
  ADMIN: "/admin",
  ACCOUNT: "/account",
  EDIT_ACCOUNT: "/edit-account",
  USERS: (user: { id: string; username: string; ethAddress: string }) =>
    user.id ? `/@${user.username}` : `/@${user.ethAddress}`,
  MINT: "/mint",
  CREATE_COLLECTION: "/collections/new",
  UPDATE_COLLECTION: (collection: { slug: string }) =>
    `/collections/${collection.slug}/update`,
  COLLECTION_PAGE: (collection: { slug: string }) =>
    `/collections/${collection.slug}`,
  DROP: "/drops",
  ASSETS: (asset: {
    metadata: { name: string; assetId: string; contractAddress: string };
    creatorUser: { username: string };
  }) => {
    if (isDissrupContract(asset?.metadata?.contractAddress)) {
      return `/@${asset?.creatorUser?.username}/${processStr(
        asset?.metadata?.name
      )}-${asset?.metadata?.assetId}`;
    }

    return `/assets/${asset?.metadata?.contractAddress}/${asset?.metadata?.assetId}`;
  },
};

export enum ExternalRoutes {
  WHITE_PAPER = "https://cdn.sanity.io/files/o95gyw7q/production/32ed5e854e7947a70457f4a34bd5ca52029a6835.pdf",
  YAMBO_STUDIO = "https://yambo-studio.com/",
  MEDIUM = "https://medium.com/dissrup",
  DISCORD = "https://discord.gg/n3XUsXzjGy",
  TWITTER = "https://twitter.com/dissrup1/",
  INSTAGRAM = "https://www.instagram.com/dissrup1/",
  COLAB_TYPEFORM = "https://9y679iz8sf4.typeform.com/to/qrMatpfa",
  ARTIST_TYPEFORM = "https://9y679iz8sf4.typeform.com/to/ODZ2HgI5",
}
