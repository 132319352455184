import create, { SetState } from "zustand";

interface HeaderLayoutStore {
  showHeader: boolean;
  darkMode: boolean;
  logoDarkMode: boolean;
  absolute: boolean;
  setDarkMode: (dark: boolean) => void;
  setAbsoluteHeader: (dark: boolean) => void;
  toggleHeader: (show?: boolean) => void;
}

export const headerLayoutStore = create((set: SetState<HeaderLayoutStore>) => ({
  showHeader: true,
  darkMode: false,
  logoDarkMode: false,
  absolute: false,
  setDarkMode: (dark: boolean) =>
    set(() => ({ darkMode: dark, logoDarkMode: dark })),
  setAbsoluteHeader: (absolute: boolean) => set(() => ({ absolute })),
  toggleHeader: (show?: boolean) =>
    set((state) => ({
      showHeader: show === undefined ? !state.showHeader : show,
    })),
}));

export default function useHeaderLayoutStore() {
  const headerStore = headerLayoutStore();

  return { ...headerStore };
}
