import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import useServerState from "root/hooks/useServerState";
import { Routes } from "types/routeTypes";
import useConnectModal from "root/hooks/useConnectModal";
import styled from "styled-components";
import theme from "root/config/theme";
import useHasGiveaway from "root/hooks/useHasGiveaway";
import useIsTransitioning from "root/hooks/useIsTransitioning";
import { Box, Flex, Typography } from "../design-system";
import MenuToggle from "../MenuToggle";
import {
  EXPLORE_SUBMENU_LINKS,
  COMMUNITY_SUBMENU_LINKS,
  SubmenuLinkItem,
  SocialLinksList,
} from "./HeaderMenu";
import UserTag from "../UserTag";
import DropdownMenu from "../DropdownMenu";

const StyledLink = styled.a`
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: ${theme.colors.neutralColor};
  margin-bottom: 25px;
`;

const MobileLoginBtn = styled.button`
  width: 100%;
  padding: 17px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #fff;
  border: 0;
  border-radius: 50px;
  background: ${theme.colors.shadeBlack};
`;

export default function MobileMenu() {
  const [visible, setVisible] = useState(false);
  const { user } = useServerState();
  const { startConnect } = useConnectModal();
  const { transitioning } = useIsTransitioning();
  const giveawaySlug = useHasGiveaway();

  useEffect(() => {
    if (visible) {
      document.querySelector("body").classList.add("no-scroll");
      window.scrollTo(0, 0);
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [visible]);

  useEffect(() => {
    if (transitioning) setVisible(false);
  }, [transitioning]);

  function handleConnectButton() {
    setVisible(false);
    startConnect();
  }

  return (
    <Flex justifyContent="flex-end" display={{ md: "none" }}>
      <MenuToggle toggleMenu={setVisible} menuIsVisible={visible} />

      <Flex
        flexDirection="column"
        position="fixed"
        width="100vw"
        height="101vh"
        left="0"
        top="0"
        bottom="0"
        zIndex="1"
        bg="white"
        px="20px"
        pt="64px"
        pb="48px"
        transform={`translateX(${visible ? "0" : "-100"}%)`}
        transition="all 0.3s ease"
      >
        <Box mb="25px">
          <DropdownMenu label="Explore">
            {EXPLORE_SUBMENU_LINKS.map((link) => (
              <SubmenuLinkItem {...link} key={link.href} />
            ))}
          </DropdownMenu>
        </Box>
        <Link href={Routes.DROP} passHref>
          <StyledLink>Drops</StyledLink>
        </Link>
        {COMMUNITY_SUBMENU_LINKS.map((item) => (
          <Link href={item.href} passHref key={item.href}>
            <StyledLink>{item.name}</StyledLink>
          </Link>
        ))}
        {giveawaySlug && (
          <Link href={`/giveaways/${giveawaySlug}`} passHref>
            <StyledLink>Giveaways</StyledLink>
          </Link>
        )}
        <Box mt="80px">
          {user ? (
            <Link href={Routes.USERS(user)}>
              <Box
                fontSize="20px"
                borderRadius="40px"
                border="1px solid #000"
                padding="6px 10px 6px 6px"
                width="fit-content"
              >
                <UserTag user={user} avatarSize="27px" />
              </Box>
            </Link>
          ) : (
            <>
              <MobileLoginBtn type="button" onClick={handleConnectButton}>
                Connect Wallet
              </MobileLoginBtn>
              <Flex alignItems="center" justifyContent="center" mt="16px">
                <Image
                  src="/images/MetaMaskIcon.png"
                  width="24px"
                  height="22px"
                />
                <Typography
                  fontSize="10px"
                  lineHeight="0"
                  fontWeight="light"
                  ml="16px"
                >
                  Metamask Browser is recommended for safest experience
                </Typography>
              </Flex>
            </>
          )}
        </Box>

        <Flex
          alignItems="center"
          justifyContent="space-between"
          mt="auto"
          borderTop="0.4px solid #9A9A9A"
          pt="30px"
        >
          <Typography fontSize="14px">Follows us on</Typography>
          <Flex
            width={0.5}
            justifyContent="space-between"
            justifySelf="flex-end"
          >
            <SocialLinksList />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
