/* eslint-disable no-bitwise */
export default function stringToColor(string) {
  const SATURATION = 100;
  const LIGHTNESS = 50;
  let hash = 0;

  for (let index = 0; index < string.length; index += 1) {
    hash = string.charCodeAt(index) + ((hash << 5) - hash);
  }

  const hue = hash % 360;

  return `hsl(${hue}, ${SATURATION}%, ${LIGHTNESS}%)`;
}
