import React from "react";

export default function MediumLogo({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2311 0.0264205C3.94904 0.189741 2.78885 0.745713 1.86159 1.6411C1.00346 2.46977 0.469389 3.38895 0.183632 4.52909C-0.23552 6.2016 0.0693286 7.95053 1.03214 9.39658C1.87468 10.6621 3.21443 11.5654 4.75344 11.9059C5.21279 12.0075 6.28015 12.0323 6.79015 11.9532C8.47918 11.6913 9.94039 10.7429 10.8777 9.3003C11.1506 8.88029 11.2659 8.65382 11.4452 8.18604C12.0662 6.56572 11.9662 4.77052 11.1685 3.22036C10.339 1.60817 8.84464 0.468619 7.08591 0.106983C6.61142 0.00937972 5.68173 -0.0310051 5.2311 0.0264205ZM14.8454 0.439263C13.9188 0.781204 13.1281 1.9502 12.7126 3.59257C12.549 4.23901 12.4844 4.77827 12.4609 5.69174C12.4257 7.059 12.5429 7.95704 12.895 9.01823C13.1954 9.92361 13.5701 10.5691 14.0768 11.0541C14.8546 11.7987 15.6832 11.8647 16.5104 11.248C17.2589 10.6901 17.9116 9.37987 18.1865 7.88394C18.5842 5.71889 18.26 3.31361 17.3617 1.76611C17.0788 1.27871 16.517 0.709308 16.1335 0.521234C15.8793 0.396557 15.8064 0.380428 15.4546 0.370767C15.1486 0.36235 15.0123 0.37765 14.8454 0.439263ZM19.7633 1.05299C19.3915 1.45273 19.1107 2.6935 18.9868 4.48402C18.9241 5.38931 18.9592 7.55224 19.049 8.3193C19.3076 10.5278 19.7741 11.5103 20.2647 10.8799C20.5786 10.4766 20.859 9.1505 20.9684 7.55249C21.0314 6.63289 20.9951 4.4406 20.9039 3.65476C20.7088 1.97358 20.3559 0.938969 19.9774 0.938969C19.9126 0.938969 19.8268 0.984661 19.7633 1.05299Z"
        fill={color}
      />
    </svg>
  );
}
