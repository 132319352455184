import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { Box } from "root/components/design-system";
import styled from "styled-components";
import InnerSvg from "./InnerSvg";

const LogoWrapper = styled(Box)`
  &[data-dark="true"] {
    svg {
      fill: #fff;
    }
  }

  g {
    display: none;

    :first-of-type {
      display: block;
    }
  }
`;

interface LogoProps {
  dark?: boolean;
}

const Logo = ({ dark }: LogoProps) => {
  const route = useRouter();

  useEffect(() => {
    const rand = Math.floor(Math.random() * (12 - 0) + 0);

    document
      .querySelectorAll(".multi-logo g")
      .forEach((el: HTMLElement, index) => {
        // eslint-disable-next-line no-param-reassign
        el.style.display = index === rand ? "block" : "none";
      });
  }, [route.asPath]);

  return (
    <LogoWrapper data-dark={dark}>
      <InnerSvg width={{ _: "80px", md: "92px", lg: "135px" }} height={20} />
    </LogoWrapper>
  );
};

export default Logo;
