import React from "react";

export default function AboutUsIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3751 7.02454H7.03001H3.44134C4.00526 6.75798 4.54144 6.43989 5.03461 6.07237C6.11465 5.26752 6.99557 4.21849 7.5 2.95298C8.00443 4.21849 8.88535 5.26752 9.96539 6.07238C10.4586 6.43989 10.9947 6.75798 11.5587 7.02454H7.96999H7.6249H7.3751ZM3.42814 13C5.23056 12.1491 6.76224 10.769 7.5 8.91299C8.23777 10.769 9.76945 12.1491 11.5719 13H3.42814Z"
        stroke={color}
      />
    </svg>
  );
}
