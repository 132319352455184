import React, { forwardRef, Ref, ComponentPropsWithRef } from "react";
import { useId } from "@reach/auto-id";
import styled from "styled-components";
import { Flex } from "../design-system";

const Wrapper = styled.label`
  display: flex;
  align-items: baseline;
  position: relative;
  color: var(--color);

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  svg {
    margin: auto;
  }

  input[type="checkbox"]:checked + div {
    background: #000;
  }

  input[type="checkbox"]:focus + div {
    outline: 1px solid #000;
  }
`;

type CheckboxProps = ComponentPropsWithRef<"input"> & {
  children: React.ReactNode;
};

const Checkbox = forwardRef(
  ({ children, ...props }: CheckboxProps, ref: Ref<HTMLInputElement>) => {
    const id = useId();

    return (
      <Wrapper htmlFor={id}>
        <input ref={ref} type="checkbox" id={id} {...props} />
        <Flex
          width="1rem"
          height="1rem"
          border="1px solid #232323"
          borderRadius="3px"
          mr="0.5rem"
        >
          <svg
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.68721 0.354821C7.88247 0.550083 7.88247 0.866665 7.68721 1.06193L3.10388 5.64526C3.01011 5.73903 2.88293 5.79171 2.75033 5.79171C2.61772 5.79171 2.49054 5.73903 2.39677 5.64526L0.313439 3.56193C0.118177 3.36666 0.118177 3.05008 0.313439 2.85482C0.508701 2.65956 0.825283 2.65956 1.02055 2.85482L2.75033 4.5846L6.9801 0.354821C7.17537 0.159558 7.49195 0.159558 7.68721 0.354821Z"
              fill="white"
            />
          </svg>
        </Flex>
        {children}
      </Wrapper>
    );
  }
);

export default Checkbox;
