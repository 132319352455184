import React from "react";

export default function DropdownArrow() {
  return (
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 0.5L4.5 3.5L1 0.5" stroke="black" />
    </svg>
  );
}
