import React, { useState } from "react";
import Image from "next/image";
import styled from "styled-components";
import { AssetMetadataType } from "root/lib/graphqlDefs";
import getAssetMediaUrl from "root/utils/getAssetMediaUrl";
import getAssetMediaType from "root/utils/getAssetMediaType";
import MuxVideo from "./MuxVideo";
import MuxVideoOptimized from "./MuxVideoOptimized";
import SkeletonLoader from "./SkeletonLoader";
import CloudinaryImage from "./CloudinaryImage";

interface AssetMediaProps {
  assetMetadata: AssetMetadataType;
  optimized?: boolean;
  objectFit?: "fill" | "contain" | "cover";
}

const CommonStyles = `
  transition: transform 0.5s ease;

  :hover {
    transform: scale(1.05);
  }
`;

const OptimizedVideo = styled(MuxVideoOptimized)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${CommonStyles}
`;

const Video = styled(MuxVideo)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${CommonStyles}
`;

const StyledImage = styled(Image)`
  ${CommonStyles}
`;

const StyledCloudinaryImage = styled(CloudinaryImage)`
  ${CommonStyles}
`;

export default function AssetMedia({
  assetMetadata,
  optimized,
  objectFit = "cover",
}: AssetMediaProps) {
  const mediaType = getAssetMediaType(assetMetadata);
  const [loading, setLoading] = useState(true);

  function handleLoad() {
    setLoading(false);
  }

  return (
    <>
      {loading && <SkeletonLoader />}

      {mediaType === "video" && optimized && (
        <OptimizedVideo
          playbackId={assetMetadata.muxPlaybackId}
          fallbackSrc={getAssetMediaUrl(assetMetadata, "video")}
          autoPlay
          loop
          muted
          onCanPlay={handleLoad}
        />
      )}

      {mediaType === "video" && !optimized && (
        <Video
          playbackId={assetMetadata.muxPlaybackId}
          fallbackSrc={getAssetMediaUrl(assetMetadata, "video")}
          autoPlay
          loop
          muted
          onCanPlay={handleLoad}
        />
      )}

      {mediaType !== "video" && assetMetadata.cloudinaryId && (
        <StyledCloudinaryImage
          draggable={false}
          publicId={assetMetadata.cloudinaryId}
          alt={`${assetMetadata.name} by ${assetMetadata.author}`}
          layout="fill"
          objectFit={objectFit}
          decoding="async"
          onLoadingComplete={handleLoad}
        />
      )}

      {mediaType !== "video" && !assetMetadata.cloudinaryId && (
        <StyledImage
          draggable={false}
          src={getAssetMediaUrl(assetMetadata, "image")}
          alt={`${assetMetadata.name} by ${assetMetadata.author}`}
          layout="fill"
          objectFit={objectFit}
          quality="75"
          decoding="async"
          onLoadingComplete={handleLoad}
        />
      )}
    </>
  );
}
