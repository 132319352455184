import React from "react";

const SearchIcon = ({ stroke }: { stroke?: string }) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9.5"
      cy="9.5"
      r="8.5"
      stroke={stroke || "white"}
      strokeWidth="2"
    />
    <line
      x1="16.7071"
      y1="15.2929"
      x2="20.7071"
      y2="19.2929"
      stroke={stroke || "white"}
      strokeWidth="2"
    />
  </svg>
);

export default SearchIcon;
