import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { Flex, Box } from "root/components/design-system";
import { Img as ReactImage } from "react-image";
import "@reach/dialog/styles.css";
import styled from "styled-components";
import Close from "root/components/icons/Close";
import nextImageUrl from "root/utils/nextImageUrl";
import ArrowRight from "root/components/icons/ArrowRight";
import { enterAnimation, exitAnimation } from "./DialogModal";
import LoadingIndicator from "../LoadingIndicator";

const Overlay = styled(DialogOverlay)`
  position: relative;
  display: flex;
  z-index: 1000;
  transition: opacity 0.4s ease;
  height: 100vh;
`;

const DialogWrap = styled(DialogContent)`
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 0;
  &[data-closing="false"] {
    animation: ${enterAnimation} 0.5s ease-out forwards;
  }
  &[data-closing="true"] {
    animation: ${exitAnimation} 0.3s ease-out forwards;
  }
`;

const CloseButton = styled.button`
  position: sticky;
  border: 0;
  background: 0;
  z-index: 2;
  top: 3%;
`;

const NavControls = styled.div`
  position: absolute;
  z-index: 2000;
  height: 10px;
  margin: auto;
  bottom: 5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    display: flex;
    margin: 0 24px;
    border: 0;
    background: 0%;
    :first-of-type {
      transform: rotate(180deg);
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

interface DialogModalProps {
  label: string;
  images: string[];
  initial: number;
  onDismiss?: () => void;
  closing: boolean;
  isOpen: boolean;
  darkMode?: boolean;
  backToRef?: MutableRefObject<HTMLDivElement | undefined>;
}

export default function LightboxModal({
  label,
  onDismiss,
  images,
  initial,
  closing,
  isOpen,
  darkMode = false,
  backToRef,
}: DialogModalProps) {
  const [active, setActive] = useState(initial);
  const [overlayOpacity, setOverlayOpacity] = useState(0);

  const ref = useRef<HTMLDivElement>();

  function enterFullScreen() {
    setTimeout(() => {
      if (!ref.current) return;

      if (ref.current.requestFullscreen) {
        ref.current.requestFullscreen();
      } else if ((ref.current as any).webkitRequestFullscreen) {
        (ref.current as any).webkitRequestFullscreen();
      }
    });
  }

  function openModal() {
    setTimeout(() => setOverlayOpacity(1), 10);
  }

  function closeModal() {
    setOverlayOpacity(0);
    onDismiss();
  }
  function exitFullScreen() {
    setTimeout(() => {
      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
      }
    });
  }

  function onFullScreenChange() {
    if (!document.fullscreenElement) {
      closeModal();

      if (backToRef) {
        backToRef.current.scrollIntoView();
      }
    }
  }

  function handleNext() {
    setActive(active < images.length - 1 ? active + 1 : 0);
  }

  function handlePrev() {
    setActive(active > 0 ? active - 1 : images.length - 1);
  }
  function handleKeyPressed(event) {
    switch (event.key) {
      case "ArrowLeft":
        handlePrev();
        break;
      case "ArrowRight":
        handleNext();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressed);

    return () => {
      document.removeEventListener("keydown", handleKeyPressed);
    };
  }, [active]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      exitFullScreen();
      closeModal();
    } else {
      openModal();
      enterFullScreen();
    }
  }, [isOpen]);

  return (
    <Overlay
      onDismiss={closeModal}
      style={{
        opacity: overlayOpacity,
      }}
      ref={ref}
    >
      <DialogWrap aria-label={label} data-closing={closing}>
        {onDismiss && (
          <Box position="absolute" top="5%" right="2%" zIndex="2002">
            <CloseButton type="button" onClick={closeModal}>
              <Close stroke={darkMode ? "#ffffff" : "#000"} />
            </CloseButton>
          </Box>
        )}
        <Flex
          alignItems="center"
          height="100%"
          bg={darkMode ? "black" : "white"}
        >
          <Box
            position="absolute"
            m="auto"
            left="0"
            right="0"
            width="120px"
            height="120px"
          >
            <LoadingIndicator variant="big" color="white" autoWidth />
          </Box>
          <Flex
            height="100%"
            width="100%"
            textAlign="center"
            zIndex="1"
            alignItems="center"
            justifyContent="center"
          >
            <ReactImage
              key={images[active]}
              src={nextImageUrl(images[active])}
              style={{ maxWidth: "90%", maxHeight: "90%" }}
            />
          </Flex>
        </Flex>
        {images.length > 1 && (
          <NavControls>
            <>
              <button type="button" onClick={handlePrev}>
                <ArrowRight stroke={darkMode ? "#ffffff" : "#000"} />
              </button>
              <button type="button" onClick={handleNext}>
                <ArrowRight stroke={darkMode ? "#ffffff" : "#000"} />
              </button>
            </>
          </NavControls>
        )}
      </DialogWrap>
    </Overlay>
  );
}
