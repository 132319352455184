import React from "react";
import { DialogModal } from "root/components/design-system";
import create from "zustand";

interface ModalProps {
  content: React.ReactNode | ((closeModal: () => void) => React.ReactNode);
  label: string;
  disableDismiss?: boolean;
  color?: string;
  dialogStyle?: React.CSSProperties;
  withCloseButton?: boolean;
}

interface ModalStoreType {
  modal?: ModalProps;
  closing: boolean;
  renderModal: (modalOptions: ModalProps) => void;
  closeModal: () => void;
}

const useModals = create<ModalStoreType>((set) => ({
  modal: undefined,
  closing: false,
  renderModal: (modalProps: ModalProps) => set({ modal: modalProps }),
  closeModal: () => {
    set({ closing: true });
    setTimeout(() => set({ modal: null, closing: false }), 500);
  },
}));

export function ModalProvider({ children }) {
  const { modal, closeModal, closing } = useModals();

  return (
    <>
      {modal && (
        <DialogModal
          label={modal.label}
          onDismiss={modal.disableDismiss ? null : closeModal}
          color={modal.color}
          closing={closing}
          dialogStyle={modal.dialogStyle}
          withCloseButton={modal.withCloseButton}
        >
          {modal.content}
        </DialogModal>
      )}
      {children}
    </>
  );
}

export default useModals;
