import { AssetMetadataType } from "root/lib/graphqlDefs";
import cidToUrl from "./cidToUrl";
import { NormalizedMediaType } from "./normalizeMediaType";

export default function getAssetMediaUrl(
  assetMetadata: AssetMetadataType,
  mediaType: NormalizedMediaType
) {
  if (mediaType === "video") {
    if (assetMetadata.mediaCid) {
      return cidToUrl(assetMetadata.mediaCid);
    }

    return assetMetadata.animationUrl;
  }

  if (assetMetadata.imageCid) {
    return cidToUrl(assetMetadata.imageCid);
  }

  if (assetMetadata?.imageUrl?.match("^https?:\\/\\/(.*).arweave.net/")) {
    return `/api/imageProxy?url=${encodeURIComponent(assetMetadata.imageUrl)}`;
  }

  return assetMetadata.imageUrl;
}
