import React from "react";

export default function PenTool({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.1667L12.6667 8.5L14.6667 10.5L10 15.1667L8 13.1667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0002 9.16668L11.0002 4.16668L1.3335 1.83334L3.66683 11.5L8.66683 12.5L12.0002 9.16668Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.3335 1.83334L6.39083 6.89068"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33333 9.16667C8.06971 9.16667 8.66667 8.56971 8.66667 7.83333C8.66667 7.09695 8.06971 6.5 7.33333 6.5C6.59695 6.5 6 7.09695 6 7.83333C6 8.56971 6.59695 9.16667 7.33333 9.16667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
