import React, { ComponentPropsWithRef, forwardRef, Ref } from "react";
import useServerState from "root/hooks/useServerState";
import styled from "styled-components";
import theme from "root/config/theme";
import { up } from "styled-breakpoints";
import { useId } from "@reach/auto-id";
import Box from "./Box";

interface InputProps extends ComponentPropsWithRef<"input"> {
  variant: "flat" | "round";
  label: string;
  message?: string;
  currencyValue?: string;
  error?: string | null;
  inlineError?: boolean;
  dark?: boolean;
  fullWidth?: boolean;
  hideLabel?: boolean;
}

const Message = styled.span`
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: ${theme.colors["gray-60"]};
  text-align: center;

  ${up("x3l")} {
    font-size: 14px;
  }
`;

const Tip = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 26px;
  display: table;
  margin: auto;
  font-weight: ${theme.fontWeights.regular};
  font-size: 14px;
  height: fit-content;
`;

const ErrorMsg = styled.span`
  margin-top: 4px;
  color: ${theme.colors.red};
  font-size: 12px;
`;

const Wrapper = styled.div`
  &[data-disabled="true"] * {
    cursor: not-allowed;
  }

  div {
    position: relative;
  }

  input {
    display: block;
    background: none;
    border: none;
    width: 100%;
    font-family: ${theme.fonts.abcdiatype};

    :focus-visible {
      outline: none;
    }
  }

  &[data-full-width="true"] {
    width: 100%;
  }

  &.flat {
    label {
      font-size: 12px;
      color: ${theme.colors["gray-40"]};
    }

    input {
      font-size: 16px;
      font-weight: ${theme.fontWeights.regular};
      color: ${theme.colors.black};
      padding: 4px 0 10px;
      border-bottom: 1px solid ${theme.colors.black};

      &[data-dark="true"] {
        border-color: ${theme.colors.white};
        color: ${theme.colors.white};
      }
    }
  }

  &.round {
    label {
      font-size: 14px;
      font-weight: ${theme.fontWeights.bold};
      color: ${theme.colors.black};
    }

    input {
      margin-top: 16px;
      font-size: 24px;
      font-weight: ${theme.fontWeights.medium};
      padding: 11px 26px 10px;
      border: 1px solid #c4c4c4;
      border-radius: 100px;
      background: #fff;

      &[data-dark="true"] {
        border-color: ${theme.colors.white};
        color: ${theme.colors.white};
      }

      ${up("x3l")} {
        font-size: 26px;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    &[data-error="true"] {
      input {
        color: ${theme.colors["gray-50"]};
        border-color: ${theme.colors.red};
      }

      ${Message} {
        color: ${theme.colors.red};
      }

      ${Tip} {
        color: ${theme.colors["gray-50"]};
      }
    }

    &[data-dark="true"] {
      ${Tip} {
        color: ${theme.colors.white};
      }
    }
  }
`;

const Input = forwardRef(
  (
    {
      variant,
      label,
      error,
      message,
      currencyValue,
      dark,
      fullWidth,
      hideLabel,
      ...props
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const id = useId();
    const { ethPriceInUsd } = useServerState();

    function renderBottom() {
      if (variant === "round" && message) {
        return <Message>{message}</Message>;
      }

      if (error) return <ErrorMsg data-type="static">{error}</ErrorMsg>;

      return null;
    }

    const currencyConversion = () => {
      const asNumber = Number(currencyValue);

      if (Number.isNaN(asNumber)) return "";

      return new Intl.NumberFormat("en-US", {
        currency: "USD",
        style: "currency",
        currencyDisplay: "code",
      }).format(Number(asNumber) * ethPriceInUsd);
    };

    return (
      <Wrapper
        className={variant}
        data-error={!!error}
        data-full-width={fullWidth}
        data-disabled={props.disabled}
      >
        <label htmlFor={id}>
          {!hideLabel && label}
          <div>
            <input
              id={id}
              data-error={error}
              data-dark={dark}
              ref={ref}
              autoComplete="off"
              {...props}
            />
            {variant === "round" && <Tip>{currencyConversion()}</Tip>}
          </div>
        </label>

        <Box minHeight="16px" maxHeight="16px">
          {renderBottom()}
        </Box>
      </Wrapper>
    );
  }
);

export default Input;
