import React, { useRef, useState } from "react";
import {
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from "@reach/listbox";
import "@reach/listbox/styles.css";
import styled from "styled-components";
import theme from "root/config/theme";
import { up } from "styled-breakpoints";
import DropdownArrow from "../icons/DropdownArrow";
import Box from "./Box";

interface DropdownProps {
  label: string;
  buttonStyle?: React.CSSProperties;
  variant: "filter" | "flat" | "round" | "square";
  options: {
    text: string;
    value?: unknown;
    tip?: string;
    color?: string;
  }[];
  fullWidth?: boolean;
  action: (value: unknown) => void;
  selectedOptionIndex?: number;
}

const Container = styled(ListboxInput)`
  position: relative;
  display: inline;
`;

const Button = styled(ListboxButton)`
  position: relative;
  font-weight: ${theme.fontWeights.medium};
  background: ${theme.colors.white};
  border: 0;

  &:focus {
    outline: none;
  }

  &[data-full-width="true"] {
    width: 100%;
  }

  span {
    margin-left: 12px;
  }

  &[data-variant="filter"] {
    padding: 7px 11px 6px 11px;
    border: 1px solid #c4c4c4;
    border-radius: 48px;
    font-size: 13px;

    ${up("xxl")} {
      font-size: 16px;
      padding: 13px 22px;
    }

    ${up("x3l")} {
      font-size: 18px;
    }
  }

  &[data-variant="flat"] {
    padding: 9px 10px;
    padding-left: 0;
    border-bottom: 1px solid ${theme.colors["gray-70"]};
    color: ${theme.colors.black};

    &[aria-expanded="true"] {
      border-color: ${theme.colors.black};
    }
  }

  &[data-variant="round"] {
    padding: 18px 26px 18px 26px;
    border: 1px solid #c4c4c4;
    border-radius: 48px;
    font-size: 13px;

    ${up("xxl")} {
      font-size: 16px;
      padding: 17px 22px;
    }

    ${up("x3l")} {
      font-size: 18px;
    }
  }

  &[data-variant="square"] {
    align-items: space-between;
    padding: 13.5px 15px;
    font-size: 20px;
    line-height: 24px;
    background: #f9fcff;
    border-radius: 5px;
    color: #212121;

    border: 1px solid #6e7378;

    ${up("md")} {
      font-size: 16px;
      line-height: 20px;
      border: 0;
    }

    &[aria-expanded="true"] {
      border-color: ${theme.colors.black};
    }

    span {
      margin-left: 20px;
    }
  }

  &[aria-expanded="true"] {
    z-index: 2;
  }
`;

const Popover = styled(ListboxPopover)`
  padding: 8px 0;
  border: 1px solid #cacaca;
  z-index: 1;
  min-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: ${theme.colors.white};

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 6px;
    border: 3px solid ${theme.colors.white};
  }

  &[data-variant="filter"] {
    top: 8px;
    right: 0;
    padding-top: 24px;
    border-radius: 3px;

    ${up("xxl")} {
      right: 8px;
      min-width: 120%;
      padding-top: 36px;
      padding-bottom: 16px;
    }
  }

  &[data-variant="round"] {
    padding-top: 28px;
    top: 8px;
    border-radius: 0 0 14px 14px;
  }

  &[data-reach-listbox-popover]:focus-within {
    outline: none;
    box-shadow: none;
  }

  &[data-variant="square"] {
    margin-top: 3px;
    padding: 26px 15px;
    max-height: unset;
    border: 0;
    border-radius: 5px;

    :focus-within {
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
    }
  }

  li {
    padding: 8px 20px;
    font-weight: ${theme.fontWeights.medium};

    &[data-reach-listbox-option][data-current-nav] {
      color: ${theme.colors.blue};
      background: none;
    }

    &[data-variant="filter"] {
      text-align: right;
      font-size: 13px;

      ${up("xxl")} {
        font-size: 16px;
      }

      ${up("x3l")} {
        font-size: 18px;
      }
    }

    &[data-variant="flat"] {
      font-size: 13px;

      ${up("xxl")} {
        font-size: 16px;
      }

      ${up("x3l")} {
        font-size: 18px;
      }
    }

    &[data-variant="round"] {
      display: flex;
      justify-content: space-between;
      padding: 8px 28px 8px 20px;
      font-size: 13px;

      ${up("xxl")} {
        font-size: 16px;
      }

      ${up("x3l")} {
        font-size: 18px;
      }
    }

    &[data-variant="square"] {
      margin-bottom: 4px;
      color: #212121;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;

      ${up("md")} {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;

const Tip = styled.p`
  margin: 0 0 0 auto;
  font-weight: ${theme.fontWeights.light};
`;

export default function Dropdown({
  label,
  options,
  action,
  variant,
  fullWidth,
  selectedOptionIndex,
  buttonStyle,
}: DropdownProps) {
  const [active, setActive] = useState(
    options[selectedOptionIndex > 0 ? selectedOptionIndex : 0]
  );

  const triggerRef = useRef<HTMLDivElement>(null);

  function handleChange(newValue: string) {
    const selected = options.find((option) => option.text === newValue);

    setActive(selected);

    action(selected.value || selected.text);
  }

  const icon = () => {
    switch (variant) {
      case "flat":
      case "square":
        return <DropdownArrow />;

      default:
        return "▼";
    }
  };

  const edgePosition = () => {
    // Check if the trigger is close to the edges of the page,
    // if so, reposition tooltip for better visibility

    if (!triggerRef?.current) return null;

    const rect = triggerRef?.current?.getBoundingClientRect();

    const reposH = window.innerWidth - (rect.left + rect.width) < 200;

    return {
      left: reposH ? "unset" : "4px",
      right: reposH ? "4px" : "unset",
    };
  };

  return (
    <Container
      aria-label={label}
      value={active.text}
      onChange={handleChange}
      ref={triggerRef}
    >
      {variant === "flat" && (
        <Box fontSize="12" color="gray-40">
          {label}
        </Box>
      )}
      <Button
        style={buttonStyle}
        data-cursor-pointer
        arrow={icon()}
        data-variant={variant}
        data-full-width={fullWidth}
      >
        {active.text}
        {active.tip && <Tip>{active.tip}</Tip>}
      </Button>
      <Popover portal={false} data-variant={variant} style={edgePosition()}>
        <ListboxList data-cursor-pointer>
          {options.map((option) => (
            <ListboxOption
              key={option.text}
              value={option.text}
              data-variant={variant}
              style={{ color: option.color }}
            >
              {option.text}
              {option.tip && <Tip>{option.tip}</Tip>}
            </ListboxOption>
          ))}
        </ListboxList>
      </Popover>
    </Container>
  );
}
