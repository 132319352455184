import React, { useEffect, useState } from "react";
import useHeaderLayoutStore from "root/hooks/useHeaderLayoutStore";
import styled from "styled-components";

interface MenuToggleProps {
  menuIsVisible: boolean;
  toggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const Wrapper = styled.button`
  position: relative;
  width: 25px;
  height: 16px;
  padding: 0;

  background: transparent;
  border: none;
  z-index: 40;

  span {
    position: absolute;
    width: 25px;
    height: 2px;
    background-color: black;
    left: 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;

    &:first-child {
      top: 0;
      transform-origin: 0% 0%;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      bottom: 0;
      transform-origin: 0% 0%;
    }
  }

  &[data-menu-open="true"] {
    span {
      &:first-child {
        transform: rotate(45deg) translate(-2px, -1px);
      }

      &:nth-child(2) {
        transform: scale(0.2, 0.2);
        opacity: 0;
      }

      &:last-child {
        transform: rotate(-45deg) translate(-2px, -1px);
      }
    }
  }

  &[data-menu-open="false"] {
    &[data-dark="true"] span {
      background-color: #fff;
    }
  }
`;

export default function MenuToggle({
  toggleMenu,
  menuIsVisible,
}: MenuToggleProps) {
  const [closingMenu, setClosingMenu] = useState(true);
  const { darkMode } = useHeaderLayoutStore();

  function handleClick() {
    if (!menuIsVisible) {
      toggleMenu(true);
    } else {
      toggleMenu(false);
    }
  }

  useEffect(() => {
    if (!menuIsVisible) {
      setClosingMenu(true);
    } else {
      setTimeout(() => setClosingMenu(false), 10);
    }
  }, [menuIsVisible]);

  return (
    <Wrapper
      type="button"
      data-dark={darkMode}
      data-menu-open={!closingMenu}
      onClick={handleClick}
    >
      <span />
      <span />
      <span />
    </Wrapper>
  );
}
