import React from "react";
import Head from "next/head";
import StdInfoHeader from "root/components/StdInfoHeader";
import { Box, Typography } from "root/components/design-system";

export default function Custom500() {
  return (
    <>
      <Head>
        <title>500 | Dissrup</title>
      </Head>
      <Box
        position="absolute"
        top="0"
        height="100vh"
        width={1}
        background="linear-gradient(180deg, #BCBCBC 0%, #FFFFFF 26.79%)"
        zIndex="0"
      />
      <Box position="relative" pt="120px" height="100vh">
        <Box maxWidth="517px" m="auto">
          <Typography
            fontSize="24px"
            fontWeight="bold"
            textAlign="center"
            mb="36px"
          >
            An error has occured and we&apos;re working to fix the problem.
            We&apos;ll be up and running shortly.
          </Typography>
        </Box>
        <StdInfoHeader text="500" xl color="#969696" />
      </Box>
    </>
  );
}

// Force page to be statically-rendered
export async function getStaticProps() {
  return {
    props: {
      loginDisabled: true,
    },
  };
}
