import React, { createContext, ReactNode, useContext } from "react";
import {
  CurrentUserType,
  ContractsInfo,
  RegisteredContract,
} from "root/lib/graphqlDefs";

export interface ServerStateType {
  user?: CurrentUserType;
  contractsInfo: ContractsInfo;
  ethPriceInUsd: number;
  registeredContracts: RegisteredContract[];
}

interface ServerStateProviderProps {
  children: ReactNode;
  state: ServerStateType;
}

const ServerStateContext = createContext<ServerStateType>(null);

export function ServerStateProvider({
  children,
  state,
}: ServerStateProviderProps): JSX.Element {
  return (
    <ServerStateContext.Provider value={state}>
      {children}
    </ServerStateContext.Provider>
  );
}

export default function useServerState() {
  const context = useContext(ServerStateContext);

  return context || ({} as ServerStateType);
}
