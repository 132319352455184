import React, { useRef, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const DraggerContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const slideLeft = keyframes`
  0% {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`;

const InnerList = styled.ul`
  padding: 0;
  display: flex;
  justify-content: center;
  min-width: 100%;
  width: fit-content;
  list-style: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  img {
    pointer-events: none;
  }
`;

const ListInstance = styled.div`
  display: flex;
  width: max-content;

  animation: ${slideLeft} var(--speed) linear infinite var(--direction);
`;

interface InfiniteDraggerProps {
  children: React.ReactNode;
  direction?: "right" | "left";
  speed?: number;
}

export default function InfiniteLooper({
  children,
  direction = "left",
  speed = 40,
}: InfiniteDraggerProps) {
  const innerRef = useRef<null | HTMLUListElement>(null);
  const [looperInstances, setLooperInstances] = useState(3);

  // Set initial state on infinite nav. Checks if nav width is smaller thant window,
  // if so, add instances of rendered nav
  useEffect(() => {
    if (!innerRef || !innerRef.current) return;

    const { width } = innerRef.current.getBoundingClientRect();

    if (width === document.documentElement.clientWidth) return;

    if (width < window.innerWidth) {
      setLooperInstances(6);
    }
  }, [innerRef.current?.children.length]);

  return (
    <DraggerContainer>
      <InnerList
        ref={innerRef}
        style={{
          "--speed": `${speed}s`,
          "--direction": direction === "right" ? "reverse" : "normal",
        }}
      >
        {[...Array(looperInstances)].map((_, infIndex) => (
          <ListInstance
            // eslint-disable-next-line react/no-array-index-key
            key={infIndex}
          >
            {children}
          </ListInstance>
        ))}
      </InnerList>
    </DraggerContainer>
  );
}
