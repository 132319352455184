import { gql } from "graphql-request";
import { AssetGql, AssetType, NotificationType } from "./graphqlDefs";

export const ASSET_TYPE_TO_CODE = {
  ARTWORK: "0",
  DIGITAL_COLLECTIBLE: "1",
  PHYGITAL_COLLECTIBLE: "2",
  PHYGITAL_ARTWORK: "3",
  EXTRA: "4",
};

export interface SearchAssetsVariables {
  first?: number;
  skip?: number;
  search?: string;
  where?: Record<string, unknown>;
  types?: (keyof typeof ASSET_TYPE_TO_CODE)[];
}

function parseTypes(types) {
  if (!types) return Object.values(ASSET_TYPE_TO_CODE);

  return types.map((type) => ASSET_TYPE_TO_CODE[type]);
}

export async function searchAssets(
  variables: SearchAssetsVariables = {}
): Promise<AssetType[]> {
  const response = await graphqlClient.request(
    gql`
      query ($first: Int, $skip: Int, $search: String, $types: [String!]) {
        searchAssets(
          first: $first
          skip: $skip
          search: $search
          where: { type_in: $types, totalSupply_gt: 0 }
        ) {
          ${AssetGql}
        }
      }
    `,
    {
      first: 9,
      ...variables,
      types: parseTypes(variables.types),
    }
  );

  return response.searchAssets;
}

export async function getNotifications(): Promise<NotificationType[]> {
  const response = await graphqlClient.request(gql`
    query {
      getCurrentUser {
        notifications {
          id
          message
          isRead
          payload
          type
          createdAt
          updatedAt
        }
      }
    }
  `);

  return response?.getCurrentUser?.notifications || [];
}

export async function isUserOwnAssets(
  userAddress: string,
  blockchainIds: string[]
): Promise<boolean> {
  const response = await graphqlClient.request(
    gql`
      query ($blockchainIds: [String!], $userAddress: String) {
        balances(
          where: {
            asset_in: $blockchainIds
            account: $userAddress
            value_gt: 0
          }
        ) {
          id
          value
        }
      }
    `,
    {
      blockchainIds: blockchainIds.map((id) => id.toLowerCase()),
      userAddress: userAddress.toLowerCase(),
    }
  );

  return !!response?.balances?.length;
}
