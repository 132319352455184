import useEthereum from "root/hooks/useEthereum";
import { useRouter } from "next/router";
import { sign } from "root/lib/sign";
import { saveToken } from "root/lib/clientAuth";
import { useEffect, useState } from "react";
import useServerRefresher from "root/hooks/useServerRefresher";
import useConnectModal from "root/hooks/useConnectModal";
import {
  ANALYTICS_EVENTS,
  emitEvent,
  identifyUser,
} from "root/lib/analyticsReporter";

export default function useMetamaskLogin() {
  const { ethersProvider, ethAddress, error: ethError } = useEthereum();
  const [status, setStatus] = useState<
    "idle" | "loading" | "error" | "success"
  >(ethError ? "error" : "idle");
  const [error, setError] = useState(ethError);
  const router = useRouter();
  const refresh = useServerRefresher();

  const { endConnect, setShowSignUp } = useConnectModal();

  useEffect(() => {
    if (error) {
      setStatus("error");
      setError(error);
    }
  }, [error]);

  const handleLogin = async () => {
    setStatus("loading");

    try {
      const nonce = await authenticatedRedaxios.post("/nonces", {
        ethAddress,
      });

      const signature = await sign(ethersProvider, nonce.data.data);

      const sessionsResponse = await authenticatedRedaxios.post("/sessions", {
        ethAddress,
        signature,
      });

      if (sessionsResponse?.data?.countryError) {
        setStatus("error");
        setError("Sorry, currently your country is not supported.");

        return false;
      }

      await saveToken(sessionsResponse.data.token);

      setStatus("success");

      endConnect();

      identifyUser(ethAddress);
      emitEvent(ANALYTICS_EVENTS.LOGIN);
      refresh();

      return true;
    } catch (newError) {
      if (newError.status === 404) {
        setShowSignUp(true);
      } else if (newError.code === -32602) {
        router.reload();
      } else {
        setStatus("error");
        setError(newError.message || newError.statusText);
      }

      return false;
    }
  };

  return { handleLogin, status, error };
}
