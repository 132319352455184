import React, { useState } from "react";
import Image from "next/image";
import { sign } from "root/lib/sign";
import useEthereum from "root/hooks/useEthereum";
import { saveToken } from "root/lib/clientAuth";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import theme from "root/config/theme";
import { Box, Flex, Typography } from "root/components/design-system";
import useServerRefresher from "root/hooks/useServerRefresher";
import useConnectModal from "root/hooks/useConnectModal";
import {
  ANALYTICS_EVENTS,
  emitEvent,
  identifyUser,
} from "root/lib/analyticsReporter";
import Input from "../design-v2/Input";
import Checkbox from "../design-v2/Checkbox";

const SubmitButton = styled.button`
  padding: 0.875rem 3.4rem;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  color: #fff;
  border: 1px solid #000;
  border-radius: 50px;
  background: ${theme.colors.shadeBlack};
`;

export default function SignUpForm({
  onError,
}: {
  onError: (singError: string) => void;
}) {
  const refresh = useServerRefresher();
  const [showForm, setShowForm] = useState(true);
  const { ethersProvider, ethAddress } = useEthereum();
  const { endConnect } = useConnectModal();
  const { mutateAsync: createUserMutation, error } = useMutation((body) =>
    authenticatedRedaxios.post("/users", body)
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  async function handleSignUp(params) {
    setShowForm(false);

    const nonce = await authenticatedRedaxios.post("/nonces", { ethAddress });
    const signature = await sign(ethersProvider, nonce.data.data);
    const signUpResponse = await createUserMutation({
      ...params,
      ethAddress,
      signature,
    });

    if (signUpResponse?.data?.countryError) {
      onError("Sorry, currently your country is not supported.");
      emitEvent(ANALYTICS_EVENTS.COUNTRY_IS_NOT_SUPPORTED);

      return;
    }

    await saveToken(signUpResponse.data.token);

    endConnect();

    identifyUser(ethAddress);

    emitEvent(ANALYTICS_EVENTS.SIGNUP);

    refresh();
  }

  return (
    <Flex flexDirection="column" flex="1 0 auto">
      <Typography
        fontSize="1.56rem"
        fontWeight="bold"
        lineHeight="1.88rem"
        letterSpacing="-0.02em"
        textAlign="center"
        my="1.5rem"
      >
        Setup an account
      </Typography>
      <Box m="auto" mb="2.5rem" height="1px" width="59px" bg="#9299A0" />
      <Box width={1} flex="1 0 auto" mb="2rem">
        {showForm && (
          <form onSubmit={handleSubmit(handleSignUp)}>
            <Input
              control={control}
              type="text"
              label="Username"
              error={errors?.username?.message}
              {...register("username", {
                required: "Please provide an username",
                pattern: {
                  value: /^([0-9A-Za-z\\-_\\.])+$/,
                  message: "Unallowed characters in username",
                },
              })}
            />
            <Input
              control={control}
              label="Email"
              error={errors?.email?.message}
              {...register("email", {
                required: "Please provide an email",
                pattern: {
                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Please input a valid email address",
                },
              })}
            />
            <Box mt="1rem">
              <Checkbox
                color="black"
                name="allowMarketing"
                defaultChecked
                {...register("allowMarketing")}
              >
                <Typography fontSize="0.75rem">
                  Yes, I would like to receive Dissrup Drop announcements via
                  email
                </Typography>
              </Checkbox>
            </Box>
            <Box my="1rem">
              <SubmitButton type="submit">Sign Up</SubmitButton>
            </Box>
          </form>
        )}

        {!showForm && !error && (
          <Box textAlign="center" my="4rem">
            <Image
              src="/images/LoadingAnimation.gif"
              width="80px"
              height="46px"
            />
            <Typography letterSpacing="-0.02em" mt="1.75rem">
              Setting up your account
            </Typography>
          </Box>
        )}

        {error && (
          <Typography textAlign="center">
            Something went wrong. Please try again later.
          </Typography>
        )}
      </Box>
      <Typography textAlign="center" fontSize="0.75rem" color="#6c6c6c">
        We do not use your data outside platform necessities or any marketing
        related areas without your open permission.
      </Typography>
    </Flex>
  );
}
