import React, { ComponentPropsWithRef, Ref } from "react";
import styled from "styled-components";
import theme from "root/config/theme";
import { up } from "styled-breakpoints";

interface CustomButtonProps extends ComponentPropsWithRef<"button"> {
  variant?:
    | "big"
    | "medium"
    | "small"
    | "underline"
    | "icon"
    | "blank"
    | "medium-quiet";
  color?: "black" | "blue" | "white" | "green" | "";
  outline?: boolean;
  skeleton?: boolean;
  autoWidth?: boolean;
  as?: string;
  // fallback
  [key: string]: unknown;
}

const Container = styled.button`
  display: block;
  appearance: none;
  outline: none;
  box-shadow: none;
  border: none;
  text-transform: uppercase;
  text-align: center;
  border-radius: 100px;
  background: none;
  transition: all 0.3s ease;
  width: fit-content;
  max-width: 100%;

  svg path {
    transition: all 0.3s ease;
  }

  :disabled {
    cursor: not-allowed;
  }

  &[data-variant="big"] {
    font-family: ${theme.fonts.rhode};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: 0.01em;
    padding: 18px 28px 16px;
    font-size: 14px;

    ${up("md")} {
      font-size: 16px;
    }

    ${up("xxl")} {
      padding: 23px 28px 21px;
    }
  }

  &[data-variant="medium"] {
    font-family: ${theme.fonts.rhode};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: 0.01em;
    padding: 14px 24px 12px;
    font-size: 12px;

    ${up("md")} {
      font-size: 14px;
    }

    ${up("xxl")} {
      font-size: 16px;
      padding: 14px 36px 12px;
    }
  }

  &[data-variant="medium-quiet"] {
    font-weight: ${theme.fontWeights.bold};
    letter-spacing: 0.1em;
    padding: 14px 24px 12px;
    font-size: 12px;

    ${up("md")} {
      font-size: 14px;
    }

    ${up("xxl")} {
      font-size: 16px;
    }
  }

  &[data-variant="small"] {
    font-family: ${theme.fonts.abcdiatype};
    font-weight: ${theme.fontWeights.bold};
    letter-spacing: 0.1em;
    padding: 8px 16px 8px;

    font-size: 10px;

    ${up("md")} {
      font-size: 12px;
    }

    ${up("xxl")} {
      font-size: 14px;
    }
  }

  &[data-variant="big"]:disabled,
  &[data-variant="medium"]:disabled,
  &[data-variant="small"]:disabled {
    background: ${theme.colors.grays[40]};
    color: ${theme.colors.grays[70]};
  }

  &[data-color="black"] {
    color: ${theme.colors.white};
    background: ${theme.colors.black};

    :hover:enabled,
    :focus:enabled,
    :hover[href] {
      background: ${theme.colors.blue};
    }
  }

  &[data-color="blue"] {
    color: ${theme.colors.white};
    background: ${theme.colors.blue};

    :hover:enabled,
    :focus:enabled,
    :hover[href] {
      background: ${theme.colors.black};
    }
  }

  &[data-color="white"] {
    color: ${theme.colors.black};
    background: ${theme.colors.white};

    :hover:enabled,
    :focus:enabled,
    :hover[href] {
      background: ${theme.colors.blue};
      color: ${theme.colors.white};
    }
  }

  &[data-color="green"] {
    color: ${theme.colors.black};
    background: ${theme.colors.green};

    :hover:enabled,
    :focus:enabled,
    :hover[href] {
      background: ${theme.colors.white};
    }
  }

  &[data-auto-width="true"] {
    width: 100%;
  }

  &[data-outline="true"] {
    border: 1px solid ${theme.colors.black};
    background-color: transparent;

    &[data-variant="medium"],
    &[data-variant="medium-quiet"] {
      padding: 10px 24px 8px;
    }

    :hover:enabled,
    :focus:enabled,
    :hover[href] {
      color: ${theme.colors.white};
      background: ${theme.colors.black};
    }

    :disabled {
      color: ${theme.colors.grays[40]};
      border-color: ${theme.colors.grays[30]};
      background: transparent;
    }

    &[data-color="white"] {
      color: ${theme.colors.white};
      border-color: ${theme.colors.white};

      :hover:enabled,
      :focus:enabled,
      :hover[href] {
        background: ${theme.colors.white};
        color: ${theme.colors.black};
      }
    }

    &[data-color="black"] {
      color: ${theme.colors.black};
      border-color: ${theme.colors.black};

      :hover:enabled,
      :focus:enabled,
      :hover[href] {
        background: ${theme.colors.black};
        color: ${theme.colors.white};
      }
    }
  }

  &[data-variant="underline"] {
    display: flex;
    align-items: center;
    font-family: ${theme.fonts.abcdiatype};
    font-weight: ${theme.fontWeights.bold};
    font-size: 9px;
    letter-spacing: 0.1em;
    padding: 0 8px 6px;
    border-bottom: 1px solid ${theme.colors.black};
    border-radius: 0;
    height: 22px;

    ${up("xxl")} {
      font-size: 12px;
      padding: 7px 11px 8px;
    }

    ${up("x3l")} {
      font-size: 14px;
      padding: 7px 12px 12px;
    }

    :hover:enabled,
    :focus:enabled,
    :hover[href] {
      color: ${theme.colors.blue};
      border-color: ${theme.colors.blue};
    }

    :disabled {
      color: ${theme.colors.grays[40]};
      border-color: ${theme.colors.grays[40]};
    }
  }

  &[data-variant="icon"] {
    display: flex;
    align-items: center;
    text-transform: lowercase;
    font-family: ${theme.fonts.abcdiatype};
    font-weight: ${theme.fontWeights.medium};
    font-size: 13px;
    letter-spacing: 0.1em;
    padding: 5px 10px 4px;
    border: 1px solid ${theme.colors.grays[30]};

    svg {
      margin-right: 6px;
    }

    :hover:enabled,
    :focus:enabled {
      color: ${theme.colors.blue};
      border-color: ${theme.colors.blue};

      path {
        fill: ${theme.colors.blue};
      }
      line {
        stroke: ${theme.colors.blue};
      }
    }

    ${up("xxl")} {
      font-size: 13px;
      padding: 5px 18px 5px;
    }

    ${up("x3l")} {
      font-size: 15px;
    }

    :disabled,
    :disabled:hover {
      background-color: white;
      color: ${theme.colors.grays[30]};
      border-color: ${theme.colors.grays[30]};
    }
  }

  &[data-variant="blank"] {
    border: 0;
    background: none;
    padding: 0;
  }

  &[data-skeleton="true"] {
    background: #eaeaea;
    color: #ddd;
    pointer-events: none;
  }

  :focus {
    outline: none;
  }
`;

const Button = React.forwardRef(
  (
    {
      variant,
      color,
      autoWidth,
      outline,
      skeleton,
      children,
      as,
      ...props
    }: CustomButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <Container
      type={as === "button" ? "button" || props.type : props.type}
      data-variant={variant}
      data-outline={outline}
      data-skeleton={skeleton}
      data-color={color}
      data-auto-width={autoWidth}
      ref={ref}
      as={as as any}
      {...props}
    >
      {children}
    </Container>
  )
) as any;

Button.Styled = Container;

interface ExtendedProps extends React.FC<CustomButtonProps> {
  Styled: any;
}

export default Button as ExtendedProps;
