import React, { useCallback, useMemo } from "react";
import Link from "next/link";
import { AssetType, PublicUserType } from "root/lib/graphqlDefs";
import { Routes } from "types/routeTypes";
import theme from "root/config/theme";
import { Typography, Flex, Box } from "./design-system";
import UserAvatar from "./UserAvatar";

interface UserTagProps {
  assets?: AssetType[];
  user?: PublicUserType;
  noLink?: boolean;
  dark?: boolean;
  avatarSize?: string;
  collectionTotalOwners?: number;
  marginAvatarToText?: number;
}

export default function UserTag({
  collectionTotalOwners,
  assets,
  user,
  noLink,
  dark,
  avatarSize = "20px",
  marginAvatarToText = 5,
}: UserTagProps) {
  const concatOwners = useCallback(() => {
    const balances = [];
    const addresses = [];

    assets?.map((asset) =>
      asset.balances?.forEach((balance) => {
        const userValue = balance.account.user;

        if (!addresses.includes(userValue?.ethAddress)) {
          balances.push(userValue);
          addresses.push(userValue.ethAddress);
        }
      })
    );

    return balances;
  }, [assets]);
  const owners = useMemo(
    () => (user ? [user] : concatOwners()),
    [concatOwners, user]
  );

  if (owners.length === 0) return null;

  if (owners.length > 1) {
    const returnedOwners = owners.slice(0, 3);
    const totalOwners =
      collectionTotalOwners ||
      assets?.reduce(
        (accum, asset) => accum + parseInt(asset.totalOwners, 10),
        0
      );
    const additionalOwners = totalOwners - 3;

    return (
      <Flex pl="10px">
        {returnedOwners.map((item) => (
          <Box
            key={item.ethAddress}
            ml={`-${10}px`}
            border="2px solid #fff"
            borderRadius="50%"
          >
            <UserAvatar user={item} size={avatarSize} />
          </Box>
        ))}
        {additionalOwners > 0 && (
          <Box
            border="2px solid #fff"
            borderRadius="50%"
            ml={`-${10}px`}
            zIndex="1"
          >
            <Flex
              alignItems="center"
              width={avatarSize}
              height={avatarSize}
              borderRadius="50%"
              bg="#2A5E52"
              color="#fff"
            >
              <Typography
                fontSize="9px"
                mx="auto"
                lineHeight="1"
                pt="1px"
                textAlign="center"
              >
                +{additionalOwners}
              </Typography>
            </Flex>
          </Box>
        )}
      </Flex>
    );
  }

  const inner = (
    <Flex
      alignItems="center"
      width="100%"
      flexGrow={1}
      overflow="hidden"
      textOverflow="ellipsis"
      color="inherit"
      hover={{
        color: theme.colors.blue,
      }}
    >
      <UserAvatar size={avatarSize} user={owners[0]} />

      <Typography
        css="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
        letterSpacing="-0.02em"
        ml={marginAvatarToText}
        color={dark ? "#fff" : "#000"}
        fontSize="inherit"
      >
        {owners[0].username && "@"}
        {owners[0].username || owners[0].name || owners[0].ethAddress}
      </Typography>
    </Flex>
  );

  if (owners[0].username && !noLink) {
    return (
      <Link href={Routes.USERS(owners[0])} passHref>
        <a>{inner}</a>
      </Link>
    );
  }

  return inner;
}
