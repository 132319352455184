import React, { useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import styled from "styled-components";
import theme from "root/config/theme";
import { Flex, Typography } from "root/components/design-system";
import { Routes } from "types/routeTypes";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import useEthereum, { useEthereumInitializer } from "root/hooks/useEthereum";
import useConnectModal from "root/hooks/useConnectModal";
import useServerState from "root/hooks/useServerState";
import ErrorModal from "root/components/ErrorModal";
import useModals from "root/hooks/useModals";
import SignUpForm from "./SignUpForm";
import useMetamaskLogin from "./useMetamaskLogin";

const StyledOverlay = styled(DialogOverlay)`
  display: flex;
  backdrop-filter: blur(5px);
  background: hsla(0, 0%, 0%, 0.65);
  z-index: 3;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1.88rem 1.56rem;
  width: 430px;
  max-width: 90%;
  min-height: 470px;
  border-radius: 20px;
  background: #fff;
`;

const ConnectButton = styled.button`
  padding: 0.875rem 3.4rem;
  width: auto;
  max-width: 19.81rem;
  font-size: 1.13rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  border: 1px solid #000;
  border-radius: 50px;
  background: #fff;

  transition: all 0.3s ease;

  :hover {
    background: ${theme.colors.shadeBlack};
    color: #fff;
  }
`;

export default function ConnectModal() {
  const { contractsInfo } = useServerState();
  const { ethAddress } = useEthereum();
  const initializeEthereum = useEthereumInitializer();
  const { handleLogin, status, error } = useMetamaskLogin();
  const { endConnect, showSignUp } = useConnectModal();
  const { renderModal, closing: errorModalClosing } = useModals();

  function renderErrorModal(message) {
    renderModal({
      content: <ErrorModal message={message} showTryLaterMessage={false} />,
      label: "Error",
    });
  }
  useEffect(() => {
    initializeEthereum(contractsInfo);
  }, []);

  useEffect(() => {
    if (status === "error") {
      renderErrorModal(error);
    }
  }, [error, status]);

  useEffect(() => {
    if (errorModalClosing) {
      endConnect();
    }
  }, [errorModalClosing]);

  const readyToLogin = ethAddress && status === "idle";

  const LoginView = () => (
    <Flex flexDirection="column" flex="1" alignItems="center">
      <Image
        src="/images/MetamaskConnectLogo.png"
        width="136px"
        height="108px"
        alt="Metamask logo"
      />

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex="1"
      >
        {readyToLogin ? (
          <>
            <ConnectButton type="button" onClick={handleLogin}>
              Connect Metamask Wallet
            </ConnectButton>
            <Typography fontSize="0.75rem" color="#6E7378" mt="0.75rem">
              Chrome, Brave, Firefox
            </Typography>
          </>
        ) : (
          <>
            <Image
              src="/images/LoadingAnimation.gif"
              width="80px"
              height="46px"
            />
            <Typography letterSpacing="-0.02em" mt="1.75rem">
              Connecting the wallet
            </Typography>
          </>
        )}
      </Flex>

      <Typography fontSize="0.75rem" color="shadeBlack" textAlign="center">
        By connecting your wallet, you agree to our{" "}
        <Link href={Routes.TERMS_OF_SERVICE} passHref>
          <a>Terms of Use</a>
        </Link>
        &nbsp; and our&nbsp;
        <Link href={Routes.PRIVACY_POLICY} passHref>
          <a>Privacy Policy</a>
        </Link>
        .
      </Typography>
    </Flex>
  );

  const ModalContent = () => {
    if (showSignUp) {
      return (
        <SignUpForm
          onError={(singUpError: string) => renderErrorModal(singUpError)}
        />
      );
    }

    return <LoginView />;
  };

  return (
    <StyledOverlay onDismiss={endConnect}>
      <StyledDialogContent aria-label="Connect Metamask Wallet">
        <ModalContent />
      </StyledDialogContent>
    </StyledOverlay>
  );
}
