import React from "react";

const HappyFace = () => (
  <span role="img" aria-label="Smiling Face">
    <svg
      width="22"
      height="22"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.5" cy="20.5" r="19.5" stroke="black" strokeWidth="2" />
      <path
        d="M28 23C28 27.4183 24.6421 31 20.5 31C16.3579 31 13 27.4183 13 23"
        stroke="black"
        strokeWidth="2"
      />
      <circle cx="12.9167" cy="15.5" r="2.91667" fill="black" />
      <circle cx="28.0834" cy="15.5" r="2.91667" fill="black" />
    </svg>
  </span>
);

export default HappyFace;
