import FontFaceObserver from "fontfaceobserver";
import create, { SetState } from "zustand";
import Router from "next/router";
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from "react-dom";

interface PageTransitionStore {
  transitioning: boolean;
  transitioningTo: string;
  fontsLoaded: boolean;
  setTransitioning: (value: boolean) => void;
  setFontsLoaded: (value: boolean) => void;
  setTransitioningTo: (value: string) => void;
}

export const isTransitioningStore = create(
  (set: SetState<PageTransitionStore>) => ({
    transitioning: true,
    fontsLoaded: false,
    transitioningTo: "",
    setTransitioning: (value) => set({ transitioning: value }),
    setFontsLoaded: (value) => set({ fontsLoaded: value }),
    setTransitioningTo: (value) => set({ transitioningTo: value }),
  })
);

export function initializeUseIsTransitioning() {
  Router.events.on("routeChangeStart", (path, { shallow }) => {
    if (!shallow) {
      unstable_batchedUpdates(() => {
        isTransitioningStore.getState().setTransitioning(true);
        isTransitioningStore.getState().setTransitioningTo(path);
      });
    }
  });

  Router.events.on("routeChangeComplete", () => {
    if (isTransitioningStore.getState().fontsLoaded) {
      unstable_batchedUpdates(() => {
        isTransitioningStore.getState().setTransitioning(false);
      });
    }
  });

  Router.events.on("routeChangeError", () => {
    if (isTransitioningStore.getState().fontsLoaded) {
      unstable_batchedUpdates(() => {
        isTransitioningStore.getState().setTransitioning(false);
      });
    }
  });

  let firstLoadDone = false;
  const finishFirstLoading = () => {
    unstable_batchedUpdates(() => {
      isTransitioningStore.getState().setFontsLoaded(true);
    });

    unstable_batchedUpdates(() => {
      isTransitioningStore.getState().setTransitioning(false);
    });

    firstLoadDone = true;
  };

  const FONTS = [
    new FontFaceObserver("ABCDiatype", { weight: 100 }),
    new FontFaceObserver("ABCDiatype", { weight: 400 }),
    new FontFaceObserver("ABCDiatype", { weight: 500 }),
    new FontFaceObserver("ABCDiatype", { weight: 700 }),
    new FontFaceObserver("RecklessNeue", { weight: 400 }),
    new FontFaceObserver("RecklessNeue", { weight: 700 }),
    new FontFaceObserver("Rhode"),
  ];

  Promise.allSettled(FONTS.map((font) => font.load().catch())).finally(() =>
    finishFirstLoading()
  );

  // force load the website after 5 seconds to avoid infinite loops
  setTimeout(() => {
    if (!firstLoadDone) finishFirstLoading();
  }, 5000);
}

export default function useIsTransitioning() {
  const { transitioning, transitioningTo, fontsLoaded } =
    isTransitioningStore();

  return { transitioning, transitioningTo, fontsLoaded };
}
