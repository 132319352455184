import React from "react";
import { Box, Flex, Button, Typography } from "./design-system";
import HappyFace from "./icons/HappyFace";

export interface SuccessModalProps {
  onClose?: () => void;
  message?: string;
}

export default function SuccessModal({ onClose, message }: SuccessModalProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="48"
      paddingBottom="32"
      maxWidth="500px"
      margin="0 auto"
    >
      <Flex alignItems="center">
        <Typography
          fontSize={{ _: "18px", xxl: "20px", x3l: "22px" }}
          fontFamily="rhode"
          fontWeight="500"
          textAlign="center"
          mr="16px"
          mt="2px"
        >
          SUCCESS
        </Typography>
        <HappyFace />
      </Flex>

      <Box mt="14" />

      <Typography
        textAlign="center"
        fontSize={{ _: "14px", xxl: "16px", x3l: "18px" }}
      >
        Operation completed.
      </Typography>

      {message && (
        <Typography
          color="gray-60"
          textAlign="center"
          fontSize={{ _: "12px", xxl: "14px", x3l: "16px" }}
        >
          {message}
        </Typography>
      )}

      <Box mt="32" />

      {onClose && (
        <Button autoWidth color="blue" variant="big" onClick={onClose}>
          CLOSE
        </Button>
      )}
    </Box>
  );
}
