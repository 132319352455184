import React from "react";
import range from "lodash/range";
import { Row, Col, Box, Flex } from "./design-system";

export default function AssetSkeleton({ quantity }: { quantity: number }) {
  return (
    <Row>
      {range(0, quantity + 1).map((asset) => (
        <Col md={6} xl={4} key={asset}>
          <Box
            mb={{ _: "24px", xl: "42px" }}
            pt="18px"
            bg="#fff"
            borderRadius="10px"
            boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
          >
            <Box
              height="27px"
              width="27px"
              mb="18px"
              mx="17px"
              borderRadius="50%"
              bg="#EDF2F7"
            />
            <Box height="332px" bg="#EDF2F7" mb="15px" />
            <Box p="22px 18px">
              <Box
                width="55px"
                height="15px"
                bg="#EDF2F7"
                borderRadius="10px"
              />
              <Box
                width="234px"
                height="19px"
                mt="10px"
                mb="18px"
                bg="#EDF2F7"
                borderRadius="10px"
              />
              <Flex justifyContent="space-between">
                <Box mr="28px">
                  <Box
                    width="55px"
                    height="15px"
                    bg="#EDF2F7"
                    borderRadius="10px"
                  />
                  <Flex alignItems="center" mt="8px">
                    <Box
                      height="27px"
                      width="27px"
                      mr="8px"
                      borderRadius="50%"
                      bg="#DBE6F0"
                    />
                    <Box
                      width="55px"
                      height="15px"
                      bg="#DBE6F0"
                      borderRadius="10px"
                    />
                  </Flex>
                </Box>
                <Box mr="34px">
                  <Box
                    width="55px"
                    height="15px"
                    bg="#EDF2F7"
                    borderRadius="10px"
                  />
                  <Box
                    mt="13px"
                    width="83px"
                    height="15px"
                    bg="#DBE6F0"
                    borderRadius="10px"
                  />
                </Box>
                <Box width="139px" borderRadius="50px" bg="#DBE6F0" />
              </Flex>
            </Box>
          </Box>
        </Col>
      ))}
    </Row>
  );
}
