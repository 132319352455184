import React from "react";
import Link from "next/link";
import UserAvatar from "root/components/UserAvatar";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { Routes } from "types/routeTypes";
import theme from "root/config/theme";
import { Typography } from "root/components/design-system";
import useHeaderLayoutStore from "root/hooks/useHeaderLayoutStore";

const StyledButton = styled.a`
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid ${theme.colors.black};
  border-radius: 50px;
  height: 28px;

  transition: all 0.3s ease;

  &[data-dark="true"] {
    border-color: #fff;
  }

  ${up("xl")} {
    height: 42px;
  }

  ${up("xxl")} {
    height: 44px;
  }

  ${up("x3l")} {
    height: 54px;
  }

  &:focus,
  &:hover {
    outline: none;
    border-color: ${theme.colors.blue};
    color: ${theme.colors.blue};
  }

  &[data-dark="true"] {
    color: #fff;
    border-color: #fff;
  }
`;

const AvatarWrapper = styled.div`
  height: 100%;
  aspect-ratio: 1;
`;

export default function AccountButton({ user }) {
  const { darkMode } = useHeaderLayoutStore();

  return (
    <Link href={Routes.USERS(user)} passHref>
      <StyledButton data-dark={darkMode}>
        <AvatarWrapper>
          <UserAvatar user={user} />
        </AvatarWrapper>
        <Typography
          as="span"
          letterSpacing="-0.02em"
          fontSize={{ _: "18px", md: "12px", lg: "14px", xl: "20px" }}
          fontWeight="medium"
          lineHeight={{ _: "15px", xl: "24px" }}
          mx="4px"
          pr="8px"
          color={darkMode ? "#fff" : "shadeBlack"}
          css={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          @{user.username}
        </Typography>
      </StyledButton>
    </Link>
  );
}
