import React, { ComponentPropsWithRef, forwardRef, Ref } from "react";
import styled from "styled-components";
import { useId } from "@reach/auto-id";
import theme from "root/config/theme";
import Box from "./Box";

interface TextareaProps extends ComponentPropsWithRef<"textarea"> {
  variant: "flat" | "round";
  label: string;
  error?: string | null;
  fullWidth?: boolean;
  fullHeight?: boolean;
  bottomBorderOnly?: boolean;
}

const Wrapper = styled.div`
  div {
    position: relative;
  }

  textarea {
    display: block;
    padding: ${theme.space["16"]}px;
    background: none;
    width: 100%;

    :focus-visible {
      outline: none;
    }
  }

  label {
    font-size: 12px;
    color: ${theme.colors["gray-40"]};
  }

  &[data-bottom-border-only="false"] {
    textarea {
      border: 1px solid black;
      border-radius: 4px;
    }
  }

  &[data-bottom-border-only="true"] {
    textarea {
      border: 0px;
      border-radius: 0px;
      border-bottom: 1px solid black;
    }
  }

  &[data-full-width="true"] {
    width: 100%;
  }

  &[data-full-height="true"] {
    height: 100%;

    label {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    textarea {
      flex-grow: 1;
    }
  }
`;

const Textarea = forwardRef(
  (
    {
      label,
      error,
      fullWidth,
      fullHeight,
      bottomBorderOnly,
      ...props
    }: TextareaProps,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const id = useId();

    return (
      <Wrapper
        data-error={!!error}
        data-full-width={fullWidth}
        data-full-height={fullHeight}
        data-bottom-border-only={!!bottomBorderOnly}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <label htmlFor={id}>
            {label}

            <Box marginTop="8" />

            <textarea
              id={id}
              data-error={error}
              ref={ref}
              autoComplete="off"
              {...props}
            />
          </label>

          {error ? (
            <Box marginTop="4" fontSize="12" data-type="static" color="red">
              {error}
            </Box>
          ) : (
            <Box height="15px" />
          )}
        </Box>
      </Wrapper>
    );
  }
);

export default Textarea;
