import {
  ContractsInfo,
  ListingType,
  ProposalType,
  RegisteredContract,
  RegisteredContractsGql,
} from "root/lib/graphqlDefs";
import { gql } from "graphql-request";
import { ethers, providers } from "ethers";
import buildOldDissrupContracts from "root/hooks/useEthereum/buildOldDissrupContracts";
import buildMarketplaceV1Contract, {
  BaseInput,
  BidInput,
  BuyDirectSaleInput,
  ListAuctionSaleInput,
  ListDirectSaleInput,
  UpdateAuctionSaleInput,
  UpdateDirectSaleInput,
} from "root/hooks/useEthereum/buildMarketplaceV1Contract";
import buildNFTContracts from "root/hooks/useEthereum/buildNFTContracts";

export async function getRegisteredContracts(): Promise<RegisteredContract[]> {
  const response = await graphqlClient.request(
    gql`
      query {
        registeredContracts {
        ${RegisteredContractsGql}
        }
      }
    `
  );

  return response.registeredContracts;
}

export interface CombinedContracts {
  mint: (
    quantity: number,
    type?: string
  ) => Promise<providers.TransactionResponse>;

  burn: (
    assetId: string,
    quantity: number
  ) => Promise<providers.TransactionResponse>;

  transfer: (
    recipientAddress: string,
    assetId: string,
    quantity: number
  ) => Promise<providers.TransactionResponse>;

  addMinterRole: (
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  revokeMinterRole: (
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  addModeratorRole: (
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  revokeModeratorRole: (
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  hasRole: (
    role: string,
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  listProposalSale: (
    assetId: string,
    quantity: number
  ) => Promise<providers.TransactionResponse>;

  cancelProposalSale: (
    listing: ListingType
  ) => Promise<providers.TransactionResponse>;

  makeProposal: (
    listing: ListingType,
    quantity: number,
    price: number | string
  ) => Promise<providers.TransactionResponse>;

  acceptProposal: (
    proposal: ProposalType
  ) => Promise<providers.TransactionResponse>;

  cancelProposal: (
    proposal: ProposalType
  ) => Promise<providers.TransactionResponse>;

  auctionMinBid: (
    listing: ListingType
  ) => Promise<providers.TransactionResponse>;

  listDirectSale: (
    params: ListDirectSaleInput
  ) => Promise<providers.TransactionResponse>;
  buyDirectSale: (
    params: BuyDirectSaleInput
  ) => Promise<providers.TransactionResponse>;
  cancelDirectSale: (
    params: BaseInput
  ) => Promise<providers.TransactionResponse>;
  updateDirectSale: (
    params: UpdateDirectSaleInput
  ) => Promise<providers.TransactionResponse | null>;
  listAuctionSale: (
    params: ListAuctionSaleInput
  ) => Promise<providers.TransactionResponse>;
  updateAuctionSale: (
    params: UpdateAuctionSaleInput
  ) => Promise<providers.TransactionResponse | null>;
  placeBid: (params: BidInput) => Promise<providers.TransactionResponse>;

  settleAuctionSale: (
    params: BaseInput
  ) => Promise<providers.TransactionResponse>;

  cancelAuctionSale: (
    params: BaseInput
  ) => Promise<providers.TransactionResponse>;

  isApproveForAll: (contractAddress: string) => Promise<boolean>;
  setApproveForAll: (
    contractAddress: string
  ) => Promise<providers.TransactionResponse | null>;
}

export default async function buildCombinedContracts(
  provider: ethers.providers.Web3Provider,
  contractsInfo: ContractsInfo
): Promise<CombinedContracts> {
  const oldContracts = await buildOldDissrupContracts(provider, contractsInfo);

  const marketplaceV1Contract = await buildMarketplaceV1Contract(
    provider,
    contractsInfo
  );

  const nftContracts = await buildNFTContracts(provider);

  const registeredContracts = await getRegisteredContracts();

  function getRegisterContract(
    assetContractAddress: string
  ): RegisteredContract {
    return registeredContracts.find(
      (contract) =>
        contract.address.toLowerCase() === assetContractAddress.toLowerCase()
    );
  }

  return {
    ...oldContracts,
    listAuctionSale(params) {
      if (getRegisterContract(params.nftAddress)) {
        return marketplaceV1Contract.listAuctionSale(params);
      }

      return oldContracts.listAuctionSale(
        params.tokenId,
        params.amount,
        params.reservePrice,
        params.duration
      );
    },
    updateAuctionSale(params) {
      if (getRegisterContract(params.listing.asset.contractAddress)) {
        return marketplaceV1Contract.updateAuctionSale(params);
      }

      return null;
    },
    cancelAuctionSale(params) {
      if (getRegisterContract(params.listing.asset.contractAddress)) {
        return marketplaceV1Contract.cancelAuctionSale(params);
      }

      return oldContracts.cancelAuctionSale(params.listing);
    },
    listDirectSale(params) {
      if (getRegisterContract(params.nftAddress)) {
        return marketplaceV1Contract.listDirectSale(params);
      }

      return oldContracts.listDirectSale(
        params.tokenId,
        params.amount,
        params.price
      );
    },
    updateDirectSale(params) {
      if (getRegisterContract(params.listing.asset.contractAddress)) {
        return marketplaceV1Contract.updateDirectSale(params);
      }

      return null;
    },
    cancelDirectSale(params) {
      if (getRegisterContract(params.listing.asset.contractAddress)) {
        return marketplaceV1Contract.cancelDirectSale(params);
      }

      return oldContracts.cancelDirectSale(params.listing);
    },
    buyDirectSale(params) {
      if (getRegisterContract(params.listing.asset.contractAddress)) {
        return marketplaceV1Contract.buyDirectSale(params);
      }

      return oldContracts.buyDirectSale(params.listing, params.amount);
    },
    placeBid(params) {
      if (getRegisterContract(params.listing.asset.contractAddress)) {
        return marketplaceV1Contract.placeBid(params);
      }

      return oldContracts.placeBid(params.listing, params.price);
    },
    settleAuctionSale(params) {
      if (getRegisterContract(params.listing.asset.contractAddress)) {
        return marketplaceV1Contract.settleAuctionSale(params);
      }

      return oldContracts.settleAuctionSale(params.listing);
    },
    isApproveForAll(contractAddress) {
      const registerContract = getRegisterContract(contractAddress);

      if (registerContract) {
        return nftContracts.isApproveForAll({
          ...registerContract,
          saleContractAddress: contractsInfo.marketplaceV1SaleContract,
        });
      }

      return new Promise((resolve) => resolve(true));
    },
    setApproveForAll(contractAddress) {
      const registerContract = getRegisterContract(contractAddress);

      if (registerContract) {
        return nftContracts.setApproveForAll({
          ...registerContract,
          saleContractAddress: contractsInfo.marketplaceV1SaleContract,
        });
      }

      return null;
    },
  };
}
