import Head from "next/head";
import React from "react";
import { SanitySocialMetadata } from "types/cmsTypes";
import BaseMetatags from "./BaseMetatags";

interface BaseHead {
  metadata: SanitySocialMetadata;
  noFavicons?: boolean;
}

export default function BaseHead({ metadata, noFavicons }: BaseHead) {
  const FALLBACK = {
    title: "Dissrup",
    image: `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/images/social/Homepage.png`,
    description:
      "Dissrup uses technology and creativity to bring digital and physical worlds closer together",
  };

  return (
    <Head>
      {!noFavicons && <BaseMetatags />}

      <title>{metadata?.socialTitle || FALLBACK.title}</title>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@dissrup1" />
      <meta name="twitter:title" content="Dissrup" />
      <meta
        name="og:image"
        content={metadata?.socialImage?.asset?.url || FALLBACK.image}
      />
      <meta
        name="twitter:image"
        content={metadata?.socialImage?.asset?.url || FALLBACK.image}
      />
      <meta
        name="description"
        content={metadata?.socialDescription || FALLBACK.description}
      />
      <meta
        name="og:description"
        content={metadata?.socialDescription || FALLBACK.description}
      />
      <meta
        name="twitter:description"
        content={metadata?.socialDescription || FALLBACK.description}
      />
    </Head>
  );
}
