import styled from "styled-components";
import { Box, Flex, Typography } from "root/components/design-system";
import { subscribeMailchimp } from "root/lib/api";
import SuccessModal from "root/components/SuccessModal";
import ErrorModal from "root/components/ErrorModal";
import React, { useState } from "react";
import useModals from "root/hooks/useModals";
import { useForm } from "react-hook-form";
import Link from "next/link";
import { down, up } from "styled-breakpoints";
import { ExternalRoutes, Routes } from "../../../types/routeTypes";
import Container from "../design-system/grid/Container";
import theme from "@/config/theme";

const sections = [
  {
    name: "For Artists",
    key: "artists",
    links: [
      {
        name: "Submit Artist Profile",
        href: ExternalRoutes.ARTIST_TYPEFORM,
      },
      {
        name: "Collaborate",
        href: ExternalRoutes.COLAB_TYPEFORM,
      },
    ],
  },
  {
    name: "Community",
    key: "community",
    links: [
      {
        name: "About Us",
        href: Routes.MANIFESTO,
      },
      {
        name: "Explore",
        href: Routes.TRENDING,
      },
      {
        name: "Editorial",
        href: Routes.EDITORIAL,
      },
      {
        name: "Help",
        href: Routes.FAQ,
      },
      {
        name: "Careers",
        href: Routes.CAREERS,
      },
    ],
  },
  {
    name: "Legal",
    key: "legal",
    links: [
      {
        name: "Privacy Policy",
        href: Routes.PRIVACY_POLICY,
      },
      {
        name: "Terms of Use",
        href: Routes.TERMS_OF_SERVICE,
      },
    ],
  },
];

const FooterContainer = styled(Box)`
  background: #232323;
  padding-top: 6.75rem;
  padding-bottom: 2.69rem;

  ${down("md")} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  ${down("sm")} {
    padding-left: 1.25rem;
    padding-right: 1.25em;
  }
`;

const TopWrapper = styled(Box)`
  ${up("lg")} {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
`;

const FormWrapper = styled(Box)`
  width: 100%;
  text-align: center;

  ${up("lg")} {
    text-align: right;
    width: 25rem;
  }

  h4 {
    font-family: ABCDiatype;
    color: white;
    font-size: 1.56rem;
    line-height: 1.88rem;
    text-align: left;

    ${down("lg")} {
      text-align: center;
    }
  }

  input {
    height: 3.81rem;
    width: 25rem;
    max-width: 100%;
    outline: none;
    background-color: #edf2f7;
    border-radius: 10px;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2.69rem;
    :focus {
      outline: none;
    }
  }

  button {
    font-family: Rhode;
    border: none;
    width: 25rem;
    max-width: 100%;
    font-size: 0.81rem;
    line-height: 196.8%;
    letter-spacing: 0.1em;

    height: 3.13rem;
    background-color: #0019ff;
    color: white;
    text-transform: uppercase;
    border-radius: 6.25rem;
    margin-top: 2.19rem;
    :hover {
      color: #0019ff;
      background-color: white;
    }
  }
`;

function FormSection() {
  const { renderModal } = useModals();
  const [submittingEmail, setSubmittingEmail] = useState(false);
  const { register, handleSubmit } = useForm();

  async function handleFormSubmit(params: { email: string }) {
    if (submittingEmail) return;

    setSubmittingEmail(true);

    try {
      await subscribeMailchimp(params.email);
      renderModal({
        content: <SuccessModal message="Subscribed to the newsletter!" />,
        label: "Subscribed successfully!",
      });
    } catch (error) {
      renderModal({
        content: (
          <ErrorModal message="Could not subscribe to the newsletter. Please try again later!" />
        ),
        label: "Could not subscribe!",
      });
    } finally {
      setSubmittingEmail(false);
    }
  }

  return (
    <FormWrapper>
      <h4>DISSRUP Newsletter</h4>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <input
          type="email"
          placeholder="Your email address"
          autoComplete="off"
          {...register("email", {
            required: "Please provide an email",
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
              message: "Please input a valid email address",
            },
          })}
        />
        <button type="submit" disabled={submittingEmail}>
          KEEP ME UPDATED
        </button>
      </form>
    </FormWrapper>
  );
}

const MenuWrapper = styled(Box)`
  display: flex;
  ${down("md")} {
    margin-top: 4.94rem;
    justify-content: space-between;
  }
  ${down("sm")} {
    display: block;
    margin-top: 4.94rem;
  }
`;

const MenuSection = styled(Box)`
  min-width: 12.63rem;
`;

const MenuLabel = styled(Typography)`
  font-family: ABCDiatype;
  font-style: normal;
  font-weight: bold;
  font-size: 1.56rem;
  line-height: 1.88rem;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 1.7rem;

  ${down("sm")} {
    margin-top: 2.5rem;
  }
`;

const MenuItem = styled.a`
  font-style: normal;
  font-size: 1.25rem;
  line-height: 2.44rem;
  letter-spacing: -0.02em;
  color: #e1eaf2;

  :hover {
    color: #0019ff;
  }
`;

function BottomMenu() {
  return (
    <MenuWrapper>
      {Object.values(sections).map((section) => (
        <MenuSection key={section.key}>
          <MenuLabel>{section.name}</MenuLabel>
          {section.links.map((item) => (
            <Box key={item.name}>
              <MenuItem href={item.href} key={item.href}>
                {item.name}
              </MenuItem>
            </Box>
          ))}
        </MenuSection>
      ))}
    </MenuWrapper>
  );
}

const SocialWrapper = styled(Flex)`
  color: white;
  font-size: 1rem;
  line-height: 1.25rem;

  ${down("sm")} {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
  }

  span {
    position: relative;
    top: 0.6rem;
    display: block;
    margin: 0 6px;
    width: 15px;
    height: 1.5px;
    background: ${theme.colors.white};
  }

  a {
    font-size: 1rem;

    font-family: ABCDiatype;
    color: white;
    text-transform: uppercase;
    :hover {
      color: #0019ff;
    }
  }
`;

const CopyrightWrapper = styled(Flex)`
  ${up("lg")} {
    border-top: 0.3px solid #b6c0c8;
    padding-top: 2.19rem;
    margin-top: 2.19rem;
    line-height: 1.6rem;
  }
  margin-top: -5px;
  color: white;
  line-height: 1.6rem;
  ${down("sm")} {
    width: 100%;
    justify-content: center;
    margin-top: 5.44rem;
  }
`;

const DuckIcon = styled(Box)`
  background-image: url("/images/duck-icon.png");
  background-size: contain;
  width: 25px;
  height: 24px;
  margin-right: 4px;
`;

export default function Footer() {
  return (
    <Container style={{ background: " #232323" }} fullWidth>
      <Container>
        <FooterContainer>
          <TopWrapper>
            <FormSection />
            <BottomMenu />
          </TopWrapper>
          <Box
            marginTop={{ _: "5rem", lg: "8.63rem" }}
            display={{ sm: "block", md: "flex", lg: "block" }}
            justifyContent={{ _: "space-between" }}
          >
            <SocialWrapper>
              <Link href={ExternalRoutes.DISCORD} passHref>
                <a>Discord</a>
              </Link>
              <span />
              <Link href={ExternalRoutes.TWITTER} passHref>
                <a>Twitter</a>
              </Link>
              <span />
              <Link href={ExternalRoutes.INSTAGRAM} passHref>
                <a>Instagram</a>
              </Link>
            </SocialWrapper>
            <CopyrightWrapper>
              <DuckIcon />© Dissrup Labs {new Date().getFullYear()}
            </CopyrightWrapper>
          </Box>
        </FooterContainer>
      </Container>
    </Container>
  );
}
