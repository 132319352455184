import React, { CSSProperties, ReactNode, Ref } from "react";
import { up } from "styled-breakpoints";
import styled from "styled-components";

interface ContainerProps {
  children: ReactNode;
  fullWidth?: boolean;
  className?: string;
  style?: CSSProperties;
}

const ContainerEl = styled.div`
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  padding-left: 16px;
  padding-right: 16px;

  ${up("xl")} {
    padding-left: 80px;
    padding-right: 80px;
  }

  max-width: 1440px;

  &[data-full-width="true"] {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Container = React.forwardRef(
  (
    { fullWidth, className, style, children }: ContainerProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <ContainerEl
      className={className}
      data-full-width={fullWidth}
      ref={ref}
      style={style}
    >
      {children}
    </ContainerEl>
  )
) as any;

export default Container;
