import { Box, Flex, Typography } from "root/components/design-system";
import { NotificationType, PrivateUserType } from "root/lib/graphqlDefs";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { MenuPopover, useMenuButtonContext } from "@reach/menu-button";
import useEthBalance from "root/queries/useEthBalance";
import Link from "next/link";
import useServerRefresher from "root/hooks/useServerRefresher";
import { deleteToken } from "root/lib/clientAuth";
import { up } from "styled-breakpoints";
import NotificationLine from "root/components/UserMenu/NotificationLine";
import useNotifications from "root/hooks/useNotifications";
import theme from "@/config/theme";
import { Routes } from "../../../types/routeTypes";

const UserMenuTabs = {
  Account: "Account",
  Notifications: "Notifications",
};

const NotificationsCount = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 7px;
  font-weight: ${theme.fontWeights.bold};
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  border-radius: 50%;
  z-index: 2;
  background-color: #ffb249;
  color: white;

  right: 36px;
  bottom: 3px;
  font-size: 10px;
  width: 10px;
  height: 10px;
`;

const NotificationSection = styled(Flex)`
  white-space: normal;
  overflow-wrap: break-word;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 6px;
    border: 3px solid ${theme.colors.white};
  }
`;

const NotificationWrapper = styled(Box)`
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0;

  ${up("x3l")} {
    padding: 16px 0;
  }

  &[data-is-last="true"] {
    border: 0;
    padding: 0;
  }

  &[data-is-first="true"] {
    padding-top: 0;
  }
`;

const StyledMenuPopover = styled(MenuPopover)`
  top: 40px;
  right: 10px;
  display: flex;
  flex-direction: column;
  padding: 28px;
  margin-top: ${theme.space["4"]}px;
  width: 300px;
  background: ${theme.colors.white};
  white-space: nowrap;
  border-radius: 15px;
  z-index: 1;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
`;

function TabButton({
  tab,
  setCurrentTab,
  currentTab,
  unreadNotificationCount,
}: {
  tab: string;
  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;
  unreadNotificationCount?: number;
}) {
  const isActive = currentTab === tab;

  return (
    <>
      <a>
        <Typography
          color={isActive ? "black" : "#B6C0C8"}
          mr={20}
          fontWeight="bold"
          onClick={() => setCurrentTab(tab)}
        >
          {tab}
        </Typography>
      </a>
      {!!unreadNotificationCount && (
        <NotificationsCount>{unreadNotificationCount}</NotificationsCount>
      )}
    </>
  );
}

function TabsMenu({
  currentTab,
  setCurrentTab,
  unreadNotificationCount,
}: {
  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;
  unreadNotificationCount: number;
}) {
  return (
    <Flex>
      {Object.values(UserMenuTabs).map((tab) => (
        <TabButton
          key={tab}
          tab={tab}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          unreadNotificationCount={
            tab === UserMenuTabs.Notifications ? unreadNotificationCount : 0
          }
        />
      ))}
    </Flex>
  );
}

const LinkButton = styled.a`
  margin-bottom: 15px;
  font-size: 15px;
  color: ${theme.colors.shadeBlack};

  &:focus,
  &:hover {
    color: ${theme.colors.blue};
  }

  &[data-red="true"] {
    color: #fc4444;

    &:focus,
    &:hover {
      color: ${theme.colors.blue};
    }
  }
`;

function AccountMenu({ user }: { user: PrivateUserType }) {
  const { data: etherBalance } = useEthBalance();

  const refresh = useServerRefresher();

  async function handleDisconnect() {
    await deleteToken();

    refresh();
  }

  return (
    <>
      <Box mt={40} mb={20}>
        <Typography fontSize="14px" fontWeight={500} color="#848484">
          Balance
        </Typography>
        <Typography fontSize="23px" lineHeight={1} fontWeight="bold">
          {etherBalance?.eth} ETH
        </Typography>

        <Typography lineHeight={1.5} fontSize="13px" color="#848484">
          $ {etherBalance?.usd}
        </Typography>
      </Box>
      {(user?.role === "ADMIN" || user?.role === "CREATOR") && (
        <>
          <Link href={Routes.MINT} passHref>
            <LinkButton>Create NFT</LinkButton>
          </Link>
        </>
      )}
      {["ADMIN", "CREATOR", "CURATOR"].includes(user?.role) && (
        <>
          <Link href={Routes.CREATE_COLLECTION} passHref>
            <LinkButton>Create Collection</LinkButton>
          </Link>
        </>
      )}
      <Link href={Routes.USERS(user)} passHref>
        <LinkButton>Profile</LinkButton>
      </Link>
      {user.role === "ADMIN" && (
        <Link href={Routes.ADMIN} passHref>
          <LinkButton>Admin Dashboard</LinkButton>
        </Link>
      )}
      <Link href={Routes.ACCOUNT} passHref>
        <LinkButton>NFT Dashboard </LinkButton>
      </Link>
      <Link href={Routes.EDIT_ACCOUNT} passHref>
        <LinkButton>Account</LinkButton>
      </Link>
      <LinkButton onClick={handleDisconnect} data-red>
        Disconnect Wallet
      </LinkButton>
    </>
  );
}

function NotificationTab({
  notifications,
  initialUnread,
}: {
  notifications: NotificationType[];
  initialUnread: string[];
}) {
  return (
    <Box mt={40} mb={20}>
      {notifications.length > 0 && (
        <>
          <NotificationSection
            flexDirection="column"
            maxHeight="300px"
            overflow="auto"
          >
            {notifications.map((notification, index) => (
              <NotificationWrapper
                key={notification.id}
                data-is-last={index === notifications.length - 1}
                data-is-first={index === 0}
              >
                <NotificationLine
                  notification={notification}
                  isNew={initialUnread.includes(notification.id)}
                />
              </NotificationWrapper>
            ))}
          </NotificationSection>
        </>
      )}
    </Box>
  );
}

export default function UserMenuPopOver({ user }: { user: PrivateUserType }) {
  const [currentTab, setCurrentTab] = useState(UserMenuTabs.Account);

  const { notifications, unreadNotifications, markAllAsRead } =
    useNotifications();

  const [initialUnread, setInitialUnread] = useState([]);

  useEffect(() => {
    if (currentTab === UserMenuTabs.Notifications) {
      let newUnread = [];

      newUnread = unreadNotifications.map((item) => item.id);
      markAllAsRead();

      setInitialUnread(newUnread);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const { isExpanded } = useMenuButtonContext();

  useEffect(() => {
    if (!isExpanded) {
      setCurrentTab(UserMenuTabs.Account);
    }
  }, [isExpanded]);

  function SelectedTab() {
    if (currentTab === UserMenuTabs.Account) {
      return <AccountMenu user={user} />;
    }

    return (
      <NotificationTab
        notifications={notifications}
        initialUnread={initialUnread}
      />
    );
  }

  return (
    <StyledMenuPopover portal={false}>
      <TabsMenu
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        unreadNotificationCount={unreadNotifications.length}
      />
      <SelectedTab />
    </StyledMenuPopover>
  );
}
