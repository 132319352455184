import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import fileToBase64 from "root/utils/fileToBase64";
import CircledClose from "../icons/CircledClose";
import Box from "./Box";
import Flex from "./Flex";
import Typography from "./Typography";

export interface RemoteFile {
  url: string;
  name: string;
  type?: string;
}

type SingleFileUploadBoxProps = {
  initialFile?: RemoteFile;
  initialFiles?: undefined;
  label: string;
  customMessage?: string;
  onChange: (files: File) => void;
  onError: (error: string) => void;
  multiple?: false | undefined | null;
  accept?: string | string[];
  description: string | string[];
  maxSize?: number;
  maxFiles?: number;
  error?: string;
  singleBox?: boolean;
};

type MultipleFileUploadBoxProps = {
  initialFile?: undefined;
  initialFiles?: RemoteFile[];
  label: string;
  customMessage?: string;
  onChange: (files: FileList) => void;
  onError: (error: string) => void;
  multiple: true;
  accept?: string | string[];
  description: string | string[];
  maxSize?: number;
  maxFiles?: number;
  error?: string;
  singleBox?: boolean;
};

export type FileUploadBoxProps =
  | SingleFileUploadBoxProps
  | MultipleFileUploadBoxProps;

const FileRemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background: transparent;
  border: none;
`;

function FilePreview({ file, handleFileRemove, multiple }) {
  const [preview, setPreview] = useState<string | undefined>();

  useEffect(() => {
    if (file instanceof File && file.type?.startsWith("image/")) {
      fileToBase64(file).then((base64) => setPreview(base64));
    } else {
      const src = URL.createObjectURL(new Blob([file], { type: "video/mp4" }));

      setPreview(src);
    }
  }, [file, file.name]);

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleFileRemove(file.name);

    event.stopPropagation();
  }

  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      backgroundColor="gray-40"
    >
      <Box
        as={file.type?.startsWith("video/") ? "video" : "img"}
        key={file.name}
        src={file.url || preview}
        width={multiple ? "64px" : "100%"}
        height={multiple ? "64px" : "100%"}
        css="object-fit: cover;"
      />

      <FileRemoveButton type="button" onClick={handleClick}>
        <CircledClose />
      </FileRemoveButton>
    </Box>
  );
}

export default function FileUploadBox({
  accept,
  label,
  customMessage,
  error,
  description,
  onChange,
  onError,
  multiple,
  maxSize,
  maxFiles,
  singleBox,
  ...props
}: FileUploadBoxProps) {
  const initialFiles = props.initialFile
    ? [props.initialFile]
    : props.initialFiles || [];
  const [files, setFiles] = useState<File[]>(
    (initialFiles.map((file) => ({ ...file, fake: true })) as never) || []
  );

  const dropzoneProps = useDropzone({
    onDrop: (newFiles, newFileRejections) => {
      // File rejections are only due to file size
      if (newFileRejections.length > 0) {
        onError("Files are too big!");

        return;
      }

      const arrayFiles = Array.from(newFiles as unknown as FileList);

      if (multiple) {
        const filesToSet = [...files, ...arrayFiles];

        if (filesToSet.length > maxFiles) {
          onError("Too many files!");

          return;
        }

        onError(null);
        setFiles(filesToSet);
      } else {
        onError(null);
        setFiles(arrayFiles);
      }
    },
    multiple,
    accept,
    maxSize,
    disabled: files.length >= maxFiles,
  });
  const ableToUpload = multiple ? files.length < maxFiles : files.length < 1;

  useEffect(() => {
    if (multiple) {
      onChange(files as never);
    } else {
      onChange(files[0] as never);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, multiple]);

  function handleFileRemove(fileName: string) {
    setFiles(files.filter((file) => file.name !== fileName));
  }

  return (
    <Box minWidth="280px">
      <Typography fontSize="14px" color="gray-40" fontWeight="500">
        {label}
      </Typography>

      <Flex gap="32">
        <Box
          minWidth="280px"
          minHeight="224px"
          border={ableToUpload ? "1px dashed" : "1px solid"}
          borderColor={ableToUpload ? "gray-40" : "blue"}
          borderRadius="8px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          {...dropzoneProps.getRootProps()}
          data-cursor-pointer
        >
          <input type="file" {...dropzoneProps.getInputProps()} />

          {files.length !== 0 && (
            <>
              <Box
                display={multiple ? "grid" : "inline"}
                gridTemplateColumns="64px 64px 64px"
                gridTemplateRows="repeat(auto-fill, 64px)"
                gridGap="20"
                padding="20px"
                margin="0 auto"
                flexGrow={1}
              >
                {files.map((file, index) => (
                  <FilePreview
                    // eslint-disable-next-line react/no-array-index-key
                    key={file.name + index}
                    file={file}
                    handleFileRemove={handleFileRemove}
                    multiple={multiple}
                  />
                ))}
              </Box>

              {ableToUpload && (
                <>
                  <svg
                    width="24"
                    height="28"
                    viewBox="0 0 24 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0607 0.939341C12.4749 0.353554 11.5251 0.353554 10.9393 0.939341L1.3934 10.4853C0.807612 11.0711 0.807612 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939341ZM13.5 28L13.5 2L10.5 2L10.5 28L13.5 28Z"
                      fill="black"
                    />
                  </svg>
                  <Box paddingY="20">
                    <Typography fontSize="18px" fontWeight="500">
                      {customMessage || "Upload Image"}
                    </Typography>
                  </Box>
                </>
              )}
            </>
          )}

          {files.length === 0 && (
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              <Box top="20" position="absolute">
                <svg
                  width="24"
                  height="28"
                  viewBox="0 0 24 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.0607 0.939341C12.4749 0.353554 11.5251 0.353554 10.9393 0.939341L1.3934 10.4853C0.807612 11.0711 0.807612 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939341ZM13.5 28L13.5 2L10.5 2L10.5 28L13.5 28Z"
                    fill="black"
                  />
                </svg>
              </Box>

              <Typography fontSize="18px" fontWeight="500">
                {customMessage || "Upload Image"}
              </Typography>

              {singleBox && (
                <Typography fontSize="12px" color="gray-40" mb="8px" mt="8px">
                  Drag and Drop
                </Typography>
              )}

              {Array.isArray(description) &&
                singleBox &&
                description.map((line, index) => (
                  <Typography
                    fontSize="12px"
                    color="#6E7378"
                    // eslint-disable-next-line react/no-array-index-key
                    key={line + index}
                  >
                    {line}
                  </Typography>
                ))}

              {!Array.isArray(description) && description && (
                <Typography fontSize="12px" color="#6E7378">
                  {description}
                </Typography>
              )}
            </Box>
          )}
          {!singleBox && (
            <Typography fontSize="12px" color="gray-40" mb="8px" mt="-16px">
              Drag and Drop
            </Typography>
          )}
        </Box>

        {((description && !singleBox) || multiple) && (
          <Box display="flex" flexDirection="column" whiteSpace="nowrap">
            {Array.isArray(description) &&
              description.map((line, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Typography key={line + index}>{line}</Typography>
              ))}

            {!Array.isArray(description) && description && (
              <Typography>{description}</Typography>
            )}

            {multiple && (
              <Typography color="blue">
                {files.length} / {maxFiles} files
              </Typography>
            )}
          </Box>
        )}
      </Flex>

      <Typography fontSize="12px" color="red">
        {error}
      </Typography>
    </Box>
  );
}
