import React from "react";
import AssetSkeleton from "root/components/AssetSkeleton";
import { Box, Button, Container, Flex } from "root/components/design-system";

export default function MarketplaceLoader() {
  return (
    <Box data-loader>
      <Container>
        <Box height={40} />
        <Flex flexWrap="wrap" width={1} mx="auto">
          <Box width={{ _: 1, md: 0.5 }} pr={{ md: "5%", lg: "2%" }}>
            <Box borderRadius="10px" width={1} bg="#eaeaea">
              <Box height={{ _: "319px", md: "308px", lg: "569px" }} />
            </Box>
          </Box>
          <Flex
            flexDirection="column"
            width={{ _: 1, md: 0.5 }}
            px={{ _: "0", lg: "5%" }}
            pt={{ _: "24px", lg: "4px" }}
          >
            <Flex flex="1" flexDirection="column" justifyContent="center">
              <Box
                height={{ _: "24px", lg: "48px" }}
                width={0.8}
                bg="#eaeaea"
                borderRadius="4px"
                mb="16px"
              />
              <Box
                height={{ _: "14px", lg: "24px" }}
                width="180px"
                bg="#eaeaea"
                borderRadius="4px"
              />
              <Box
                height="74px"
                width={0.3}
                bg="#eaeaea"
                borderRadius="4px"
                my={{ _: "42px", lg: "48px" }}
              />
              <Button variant="medium" color="black" skeleton>
                VIEW ARTWORK
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Box
          height="0"
          width={1}
          p="0 20px 0 48px"
          mt={{ _: "84px", lg: "64px", xl: "84px", xxl: "92px" }}
          mb={{ _: "24px", lg: "36px", xxl: "48px" }}
        />
        <AssetSkeleton quantity={3} />
      </Container>
    </Box>
  );
}
