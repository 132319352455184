/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef } from "react";
import dynamic from "next/dynamic";

export interface ModelViewerProps {
  src: string;
  alt: string;
  onLoad?: CallableFunction;
}

const Model = dynamic(
  async () => {
    await import("@google/model-viewer");

    return ({ src, alt, onLoad }: ModelViewerProps) => {
      const ref = useRef<HTMLDivElement | undefined>();

      function handleProgress(event) {
        if (event.detail.totalProgress >= 1 && onLoad) onLoad();
      }

      useEffect(() => {
        const modelViewerEl = ref.current;

        modelViewerEl?.addEventListener("progress", handleProgress);

        return () =>
          modelViewerEl?.removeEventListener("progress", handleProgress);
      }, []);

      return (
        <model-viewer
          style={{ width: "100%", height: "100%" }}
          src={src}
          loading="eager"
          autoplay
          alt={alt}
          shadow-intensity="1"
          camera-controls
          auto-rotate
          ar
          data-js-focus-visible
          ref={ref}
        />
      );
    };
  },
  {
    ssr: false,
  }
);

export default function ModelViewer(props: ModelViewerProps) {
  return <Model {...props} />;
}
