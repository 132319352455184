import React from "react";

export default function GiftIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68839 0.059018C8.03569 0.201729 7.33504 0.818004 6.83935 1.68566L6.45087 2.36574L6.07303 1.62499C5.43096 0.36566 4.33052 -0.14109 3.41427 0.400413C2.83332 0.74366 2.89292 1.64407 3.53246 2.18358L4.02595 2.59989L2.39284 2.60203C1.49466 2.60331 0.676168 2.65131 0.574025 2.70885C0.232479 2.90127 0 3.43024 0 4.01475V4.59386H6.5H13V3.88173C13 2.79117 12.6824 2.59989 10.8721 2.59989H9.4196L9.94348 2.09642C11.0898 0.99504 10.2967 -0.292489 8.68839 0.059018ZM9.73531 0.828686C9.89773 1.18561 9.52089 1.70304 8.75997 2.16792C8.04936 2.60217 7.25974 2.74246 7.25974 2.4344C7.25974 2.15069 8.09056 1.05728 8.48174 0.826122C8.9619 0.542409 9.60565 0.543692 9.73531 0.828686ZM4.56891 0.895483C4.88513 1.03819 5.57143 2.00755 5.57143 2.31134C5.57143 2.4955 4.4465 2.11778 4.01598 1.78907C3.53836 1.42446 3.63072 0.87754 4.18972 0.759612C4.21892 0.753487 4.38961 0.814585 4.56891 0.895483ZM0.506494 8.28681C0.506494 11.0655 0.546002 11.5857 0.77173 11.7762C0.988509 11.959 1.48369 12 3.47303 12H5.90909V8.51057V5.02113H3.20779H0.506494V8.28681ZM6.75325 8.51057V12H9.44492C12.0351 12 12.1466 11.988 12.3995 11.6834C12.6183 11.4199 12.6623 10.8352 12.6623 8.19396V5.02113H9.70779H6.75325V8.51057Z"
        fill={color}
      />
    </svg>
  );
}
