import mixpanel from "mixpanel-browser";

export const ANALYTICS_EVENTS = {
  COUNTRY_IS_NOT_SUPPORTED: "country_is_not_supported",
  LOGIN: "login",
  SIGNUP: "signup",
  CLICK_ON_ACTION_WHILE_NOT_CONNECTED_WALLET: "click_for_action_without_wallet",
  CLICK_ON_ACTION_AND_CONNECT_WALLET: "click_for_action_and_connect_wallet",
  CLICK_ON_BUY_NOW_BUTTON: "click_on_buy_now_button",
  OPEN_BUY_NOW_CHECKOUT_MODAL: "open_buy_now_checkout_modal",
  CLOSE_BUY_NOW_CHECKOUT_MODAL: "close_buy_now_checkout_modal",
  CONFIRM_BUY_NOW_CHECKOUT: "confirm_buy_now_checkout",
  COMPLETE_ASSET_BUY_NOW: "complete_buy_now_asset",
  CLICK_ON_PLACE_A_BID: "click_on_place_a_bid",
};

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
  debug: process.env.NEXT_PUBLIC_MIXPANEL_DEBUG || false,
});

export function emitEvent(eventName: string, extra: unknown = {}) {
  mixpanel.track(eventName, extra);
}

export function identifyUser(userAdderss: string) {
  mixpanel.identify(userAdderss);
}
