import React, { forwardRef, Ref, ComponentPropsWithRef } from "react";
import { useId } from "@reach/auto-id";
import styled from "styled-components";

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  color: var(--color);

  input[type="checkbox"] {
    visibility: hidden;
    position: absolute;
  }

  svg {
    border: 1px solid;
    border-color: var(--color);
    border-radius: 4px;

    g:not([data-unchecked="true"]) {
      display: none;
    }
  }

  input[type="checkbox"]:checked + svg {
    g[data-checked="true"] {
      display: block;
    }

    g[data-unchecked="true"] {
      display: none;
    }
  }

  input[type="checkbox"]:disabled + svg {
    g[data-checked="true"] {
      display: none;
    }

    g[data-unchecked="true"] {
      display: none;
    }
    g[data-disabled="true"] {
      display: block;
    }
  }
`;

const Icon = styled.svg`
  margin-right: 8px;

  g[data-disabled="true"] {
    display: none;
  }
`;

type CheckboxProps = Omit<ComponentPropsWithRef<"input">, "size"> & {
  size?: string;
  color?: "black" | "white";
  children: React.ReactNode;
};

const Checkbox = forwardRef(
  (
    { children, size = "12px", color = "black", ...props }: CheckboxProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const id = useId();

    return (
      <Wrapper htmlFor={id} style={{ "--color": color }}>
        <input ref={ref} type="checkbox" id={id} {...props} />
        <Icon
          width={size}
          height={size}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-checked="true">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.96854 4.37371L4.72727 9.03262L1.66782 6.3131L2.33218 5.56569L4.72727 7.69466L9.30418 3.6263L9.96854 4.37371Z"
              fill={color}
            />
          </g>

          <g data-disabled="true">
            <rect width="12" height="12" rx="2" fill="#AAAAAA" />
            <path d="M3 6H6H9" stroke="#7A7A7A" />
          </g>
        </Icon>

        {children}
      </Wrapper>
    );
  }
);

export default Checkbox;
