import React from "react";
import range from "lodash/range";
import { Row, Col, Box, Flex } from "./design-system";

export default function CollectionSkeleton({ quantity }: { quantity: number }) {
  return (
    <Row>
      {range(0, quantity + 1).map((asset) => (
        <Col md={6} key={asset}>
          <Box
            mb={{ _: "24px", xl: "42px" }}
            pt="18px"
            bg="#fff"
            borderRadius="10px"
            boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
          >
            <Box p={{ _: "232px 30px 30px 30px", xl: "346px 78px 48px 30px" }}>
              <Box
                width="147px"
                height="54px"
                bg="#EDF2F7"
                borderRadius="50px"
              />
              <Box
                width="234px"
                height="19px"
                mt="20px"
                mb={{ _: "70px", xl: "105px" }}
                bg="#EDF2F7"
                borderRadius="10px"
              />
              <Flex justifyContent="space-between">
                <Box mr="34px">
                  <Box
                    width={{ _: "64px", xl: "80px" }}
                    height="15px"
                    bg="#EDF2F7"
                    borderRadius="10px"
                  />
                  <Box
                    mt="16px"
                    width={{ _: "45px", xl: "55px" }}
                    height="15px"
                    bg="#DBE6F0"
                    borderRadius="10px"
                  />
                </Box>
                <Box mr="34px">
                  <Box
                    width={{ _: "64px", xl: "80px" }}
                    height="15px"
                    bg="#EDF2F7"
                    borderRadius="10px"
                  />
                  <Box
                    mt="16px"
                    width={{ _: "45px", xl: "55px" }}
                    height="15px"
                    bg="#DBE6F0"
                    borderRadius="10px"
                  />
                </Box>
                <Box mr="34px">
                  <Box
                    width={{ _: "64px", xl: "80px" }}
                    height="15px"
                    bg="#EDF2F7"
                    borderRadius="10px"
                  />
                  <Flex gap="5px" mt="10px">
                    <Box
                      bg="#DBE6F0"
                      width={{ _: "22px", xl: "27px" }}
                      height={{ _: "22px", xl: "27px" }}
                      borderRadius="100%"
                    />
                    <Box
                      bg="#DBE6F0"
                      width={{ _: "22px", xl: "27px" }}
                      height={{ _: "22px", xl: "27px" }}
                      borderRadius="100%"
                    />
                    <Box
                      bg="#DBE6F0"
                      width={{ _: "22px", xl: "27px" }}
                      height={{ _: "22px", xl: "27px" }}
                      borderRadius="100%"
                    />
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Col>
      ))}
    </Row>
  );
}
