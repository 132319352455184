import React from "react";

export default function HelpCircle({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 15.1667C11.6821 15.1667 14.6668 12.1819 14.6668 8.50001C14.6668 4.81811 11.6821 1.83334 8.00016 1.83334C4.31826 1.83334 1.3335 4.81811 1.3335 8.50001C1.3335 12.1819 4.31826 15.1667 8.00016 15.1667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06006 6.50001C6.21679 6.05446 6.52616 5.67875 6.93336 5.43944C7.34056 5.20012 7.81932 5.11264 8.28484 5.19249C8.75036 5.27234 9.1726 5.51436 9.47678 5.8757C9.78095 6.23703 9.94743 6.69436 9.94672 7.16668C9.94672 8.50001 7.94673 9.16668 7.94673 9.16668"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.8333H8.00667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
