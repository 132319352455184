import React from "react";

export default function Star({ color = "black" }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.0123 1.15912C12.1297 0.639344 12.8703 0.639344 12.9877 1.15912L14.3173 7.04533C14.3934 7.3822 14.78 7.54233 15.072 7.35793L20.1743 4.13592C20.6249 3.85141 21.1486 4.37509 20.8641 4.82565L17.6421 9.92801C17.4577 10.22 17.6178 10.6066 17.9547 10.6827L23.8409 12.0123C24.3607 12.1297 24.3607 12.8703 23.8409 12.9877L17.9547 14.3173C17.6178 14.3934 17.4577 14.78 17.6421 15.072L20.8641 20.1743C21.1486 20.6249 20.6249 21.1486 20.1743 20.8641L15.072 17.6421C14.78 17.4577 14.3934 17.6178 14.3173 17.9547L12.9877 23.8409C12.8703 24.3607 12.1297 24.3607 12.0123 23.8409L10.6827 17.9547C10.6066 17.6178 10.22 17.4577 9.92801 17.6421L4.82565 20.8641C4.37509 21.1486 3.85141 20.6249 4.13592 20.1743L7.35793 15.072C7.54233 14.78 7.3822 14.3934 7.04533 14.3173L1.15912 12.9877C0.639344 12.8703 0.639344 12.1297 1.15912 12.0123L7.04533 10.6827C7.3822 10.6066 7.54233 10.22 7.35793 9.92801L4.13592 4.82565C3.85141 4.37509 4.37509 3.85141 4.82565 4.13592L9.92801 7.35793C10.22 7.54233 10.6066 7.3822 10.6827 7.04533L12.0123 1.15912Z" />
    </svg>
  );
}
