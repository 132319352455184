import React, { forwardRef, Ref, ComponentPropsWithRef } from "react";
import { useId } from "@reach/auto-id";
import styled from "styled-components";
import theme from "root/config/theme";
import Typography from "./Typography";
import Box from "./Box";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 1px;
  width: 36px;
  height: 21px;
  border-radius: 10.5px;

  border: 1px solid ${theme.colors["gray-30"]};

  transition: background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;
`;

const Control = styled.div`
  width: 17px;
  height: 17px;
  background-color: ${theme.colors["gray-30"]};
  border-radius: 100%;
  transition: background-color ease-in-out 0.25s, transform ease-in-out 0.25s;
`;

const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    visibility: hidden;
    position: absolute;
  }

  input[type="checkbox"]:checked ~ ${Container} {
    border: 1px solid ${theme.colors.blue};
    background-color: ${theme.colors.blue};
  }

  input[type="checkbox"]:checked ~ ${Container} > ${Control} {
    background-color: ${theme.colors.white};

    transform: translateX(calc(100% - 2px));
  }
`;

interface ToggleProps extends ComponentPropsWithRef<"input"> {
  children?: React.ReactNode;
}

const Toggle = forwardRef(
  ({ children, ...props }: ToggleProps, ref: Ref<HTMLInputElement>) => {
    const id = useId();

    return (
      <Wrapper htmlFor={id}>
        <input ref={ref} type="checkbox" id={id} {...props} />

        {children && (
          <Box mr="11px">
            <Typography fontSize="11px" lineHeight="13.76px" fontWeight="500">
              {children}
            </Typography>
          </Box>
        )}

        <Container>
          <Control />
        </Container>
      </Wrapper>
    );
  }
);

export default Toggle;
