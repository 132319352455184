import React from "react";
import { Box, Container, Flex } from "root/components/design-system";

const FeaturedArtworkSkeleton = () => (
  <Container>
    <Flex
      flexWrap="wrap"
      width={1}
      mx="auto"
      flexDirection={{ _: "column-reverse", md: "row" }}
      mt={20}
    >
      <Flex
        flexDirection="column"
        width={{ _: 1, md: 0.5 }}
        pt={{ _: "11px", lg: "4px" }}
      >
        <Flex flex="1" flexDirection="column" justifyContent="center">
          <Box
            height={{ _: "28px", lg: "63px" }}
            width={{ _: "132px", lg: "297px" }}
            bg="#eaeaea"
            borderRadius={{ _: "40px", lg: "90px" }}
            mb="15px"
          />
          <Box
            height={{ _: "12px", lg: "19px" }}
            width={{ _: "81px", lg: "127px" }}
            bg="#eaeaea"
            borderRadius={{ _: "18px", lg: "28px" }}
          />
          <Box
            mt={{ _: "22px", lg: "28px" }}
            mb={{ _: "15px", lg: "17px" }}
            width={{ _: "332px", lg: "440px" }}
            border="1px solid #E6E6E6"
          />
          <Flex justifyContent={{ _: "space-between", md: "flex-start" }}>
            <Box position="relative" pr={{ _: "24px", lg: "46px" }} mr="10px">
              <Box
                height={{ _: "9px", lg: "11px" }}
                width={{ _: "49px", lg: "59px" }}
                bg="#eaeaea"
                borderRadius="15px"
              />
              <Box
                height={{ _: "27px", lg: "34px" }}
                width={{ _: "72px", lg: "110px" }}
                bg="#eaeaea"
                borderRadius="60px"
                mt="9.5px"
                mb="10.5px"
              />
              <Box
                height={{ _: "9px", lg: "11px" }}
                width={{ _: "49px", lg: "59px" }}
                bg="#eaeaea"
                borderRadius="15px"
              />
              <Box
                display={{ _: "none", md: "flex" }}
                position="absolute"
                top="10px"
                right="0"
                width="1px"
                height="90%"
                bg="#eaeaea"
              />
            </Box>
            <Box position="relative" pl={{ _: "24px", lg: "26px" }}>
              <Box
                height={{ _: "11px", lg: "11px" }}
                width={{ _: "86px", lg: "86px" }}
                bg="#eaeaea"
                borderRadius="15px"
              />
              <Flex mt="9.5px" mb="10.5px">
                <Box
                  height={{ _: "19px", lg: "28px" }}
                  width={{ _: "24px", lg: "37px" }}
                  bg="#eaeaea"
                  borderRadius={{ _: "26px", lg: "40px" }}
                />
                <Box
                  height={{ _: "19px", lg: "28px" }}
                  width={{ _: "25px", lg: "38px" }}
                  bg="#eaeaea"
                  ml="30px"
                  borderRadius={{ _: "27px", lg: "40px" }}
                />
                <Box
                  height={{ _: "19px", lg: "28px" }}
                  width={{ _: "19px", lg: "29px" }}
                  bg="#eaeaea"
                  ml="30px"
                  borderRadius={{ _: "27px", lg: "40px" }}
                />
              </Flex>
              <Flex mt="9.5px" ml="10px">
                <Box
                  height={{ _: "9px", lg: "11px" }}
                  width={{ _: "14px", lg: "17px" }}
                  bg="#eaeaea"
                  borderRadius={{ _: "13px", lg: "15px" }}
                />
                <Box
                  height={{ _: "9px", lg: "11px" }}
                  width={{ _: "22px", lg: "26px" }}
                  bg="#eaeaea"
                  ml={{ _: "30px", lg: "45px" }}
                  borderRadius={{ _: "13px", lg: "15px" }}
                />
                <Box
                  height={{ _: "9px", lg: "11px" }}
                  width={{ _: "21px", lg: "25px" }}
                  bg="#eaeaea"
                  ml={{ _: "30px", lg: "40px" }}
                  borderRadius={{ _: "13px", lg: "15px" }}
                />
              </Flex>
            </Box>
          </Flex>
          <Box
            height={{ _: "38px", lg: "64px" }}
            width={{ _: "340px", lg: "446px" }}
            bg="#eaeaea"
            borderRadius="26px"
            mt={{ _: "23px", lg: "44px" }}
          />
        </Flex>
      </Flex>
      <Box width={{ _: 1, md: 0.5 }} pl={{ md: "5%", lg: "5%" }}>
        <Box width={1} bg="#eaeaea">
          <Box height={{ _: "424.84px", md: "385px", lg: "676px" }} />
        </Box>
      </Box>
    </Flex>
  </Container>
);

export default FeaturedArtworkSkeleton;
