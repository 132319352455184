import React from "react";
import { Container, Box, Flex } from "root/components/design-system";

export default function AssetLoader() {
  return (
    <Box data-loader>
      <Flex bg="#EDF2F7" minHeight={{ lg: "42rem" }} />

      <Container>
        <Box
          mt={{ _: "2rem", lg: "4rem" }}
          mb={{ _: "10px", lg: "25px" }}
          width={{ _: "50px", lg: "120px" }}
          height={{ _: "16px", lg: "32px" }}
          borderRadius="100px"
          bg="#E6E6E6"
        />
        <Box
          width={{ _: "200px", lg: "360px" }}
          height={{ _: "30px", lg: "45px" }}
          borderRadius="40px"
          bg="#E6E6E6"
        />
      </Container>
    </Box>
  );
}
