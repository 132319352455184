import { ethers } from "ethers";
import { useQuery, UseQueryOptions } from "react-query";
import useServerState from "root/hooks/useServerState";

interface BalanceType {
  eth: string;
  usd: string;
}

export default function useEthBalance(opts: UseQueryOptions<BalanceType> = {}) {
  const { contractsInfo, user, ethPriceInUsd } = useServerState();

  return useQuery<BalanceType>(
    "etherBalance",
    async () => {
      const defaultProvider = ethers.getDefaultProvider(
        contractsInfo.chainName
      );
      const balanceInWei = await defaultProvider.getBalance(user.ethAddress);
      const balanceInEth = Number(ethers.utils.formatEther(balanceInWei));
      const balanceInUSD = balanceInEth * ethPriceInUsd;

      return {
        eth: balanceInEth.toLocaleString("en-us"),
        usd: balanceInUSD.toLocaleString("en-us"),
      };
    },
    {
      ...opts,
    }
  );
}
