import React, { ReactNode } from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { Box } from "root/components/design-system";

interface HiddenProps {
  children?: ReactNode;
  className?: string;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  xxl?: boolean;
  x3l?: boolean;
}

const HiddenEl = styled(Box)`
  display: var(--sm);

  ${up("md")} {
    display: var(--md);
  }

  ${up("lg")} {
    display: var(--lg);
  }

  ${up("xl")} {
    display: var(--xl);
  }

  ${up("xxl")} {
    display: var(--xxl);
  }

  ${up("x3l")} {
    display: var(--x3l);
  }
`;

export default function Hidden({
  children,
  className,
  sm,
  md,
  lg,
  xl,
  xxl,
  x3l,
}: HiddenProps) {
  const display = (view: boolean) => (view ? "none" : "initial");

  return (
    <HiddenEl
      css={`
        --sm: ${display(sm)};
        --md: ${display(md)};
        --lg: ${display(lg)};
        --xl: ${display(xl)};
        --xxl: ${display(xxl)};
        --x3l: ${display(x3l)};
      `}
      className={className}
    >
      {children}
    </HiddenEl>
  );
}
