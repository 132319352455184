import { ethers } from "ethers";

export function generateSignatureData(nonce: string): any {
  return `
      Hey! We are asking your wallet to sign us something so we can
      verify that this wallet address really belongs to you!\n\n

      Nonce: ${nonce}
  `;
}

export function sign(
  ethersProvider: ethers.providers.Web3Provider,
  data: string
): Promise<string> {
  const msg = generateSignatureData(data);

  return ethersProvider.getSigner().signMessage(msg);
}
