import React, {
  ComponentPropsWithRef,
  forwardRef,
  Ref,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import theme from "root/config/theme";
import { up } from "styled-breakpoints";
import { useId } from "@reach/auto-id";
import { Control, FieldValues, useWatch } from "react-hook-form";
import { Box, Typography } from "../design-system";

const InputElement = styled.input`
  margin-bottom: 1rem;
  padding: 1.1875rem 1.5rem;
  width: 100%;
  max-width: 100%;
  font-size: 0.75rem;
  font-weight: ${theme.fontWeights.regular};
  color: #494d50;

  border-radius: 10px;
  background: #fff;
  outline: none;
  border: 1px solid #b6c0c8;

  ${up("lg")} {
    margin-bottom: 1.25rem;
  }

  &[data-variant="solid"] {
    background: #e7eef5;
    border-color: transparent;
  }

  &[data-size="small"] {
    :focus + label {
      top: -1rem;
      left: 4px;
    }
  }

  &[data-size="medium"] {
    font-size: 1rem;
    padding: 1.4375rem 1.875rem;

    :focus + label {
      top: -1.275rem;
      left: 4px;
    }
  }

  &[data-error="true"] {
    border-color: #fc4444;
    margin-bottom: 2rem;
  }

  &[data-error-corrected="true"] {
    border-color: #40b72f;
    margin-bottom: 2rem;
  }

  :focus {
    border-color: #6372ff;

    &[data-error="true"] {
      border-color: #fc4444;
    }

    &[data-error-corrected="true"] {
      border-color: #40b72f;
    }
  }
`;

const Label = styled.label`
  position: absolute;
  left: 1.5rem;
  top: 1.1875rem;
  font-size: 0.75rem;
  font-weight: ${theme.fontWeights.regular};
  color: #494d50;

  &[data-size="medium"] {
    font-size: 1rem;
    top: 1.45rem;
    left: 1.875rem;
  }

  &[data-input-filled="true"] {
    top: -1rem;
    left: 4px;
  }

  &[data-input-filled="true"][data-size="medium"] {
    top: -1.25rem;
  }

  transition: all 0.1s ease;
`;

const StatusMessage = styled(Typography)`
  position: absolute;
  bottom: 0.75rem;
`;

interface InputProps extends ComponentPropsWithRef<"input"> {
  variant?: "outline" | "solid";
  fontSize?: "small" | "medium";
  label: string;
  error?: string | null;
  control: Control<FieldValues, object>;
}

const Input = forwardRef(
  (
    {
      label,
      error,
      control,
      variant = "outline",
      fontSize = "small",
      ...props
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const id = useId();
    const [hadError, setHadError] = useState(false);

    const currentValue = useWatch({
      control,
      name: props.name,
    });

    useEffect(() => {
      if (error) setHadError(true);
    }, [error]);

    const errorCorrected = !error && hadError;

    return (
      <Box position="relative" mb="0.25rem" data-error={!!error}>
        <InputElement
          id={id}
          data-variant={variant}
          data-size={fontSize}
          data-error={!!error && !!currentValue}
          data-error-corrected={errorCorrected}
          ref={ref}
          autoComplete="off"
          {...props}
        />
        <Label
          htmlFor={id}
          data-size={fontSize}
          data-input-filled={!!currentValue}
        >
          {label}
        </Label>
        {!!error && !!currentValue && (
          <StatusMessage fontSize="0.75rem" fontWeight="normal" color="#fc4444">
            {error}
          </StatusMessage>
        )}
        {errorCorrected && (
          <StatusMessage fontSize="0.75rem" fontWeight="normal" color="#40B72F">
            Looks good
          </StatusMessage>
        )}
      </Box>
    );
  }
);

export default Input;
