import { ContractsInfo, ListingType } from "root/lib/graphqlDefs";
import { ethers, providers } from "ethers";

const MARKETPLCAE_V1_ABI = [
  "function listAuctionSale(address nftAddress, uint256 tokenId, uint256 amount, uint256 duration, uint256 reservePrice, address[] calldata royaltiesPayees, uint256[] calldata royaltiesShares) external",
  "function bid(address nftAddress, uint256 tokenId, uint256 saleId) external payable",
  "function settle(address nftAddress, uint256 tokenId, uint256 saleId) external",
  "function isAuctionEnded(address nftAddress, uint256 tokenId, uint256 saleId) public view returns (bool)",
  "function cancelAuctionSale(address nftAddress, uint256 tokenId, uint256 saleId) external",
  "function updateAuctionSale(address nftAddress, uint256 tokenId, uint256 saleId, uint256 duration, uint256 reservePrice, address[] calldata royaltiesPayees, uint256[] calldata royaltiesShares) external",
  "function listDirectSale(address nftAddress, uint256 tokenId, uint256 amount, uint256 price, address[] calldata royaltiesPayees, uint256[] calldata royaltiesShares) external",
  "function updateDirectSale(address nftAddress, uint256 tokenId, uint256 saleId, uint256 price, address[] calldata royaltiesPayees, uint256[] calldata royaltiesShares) external",
  "function cancelDirectSale(address nftAddress, uint256 tokenId, uint256 saleId) external",
  "function buyDirectSale(address nftAddress, uint256 tokenId, uint256 saleId, uint256 amount) external payable",
];

export interface BaseInput {
  listing: ListingType;
}

export interface BaseListingInput {
  nftAddress: string;
  tokenId: string;
}

export interface Royalty {
  payee: string;
  share: number;
}

export interface ListAuctionSaleInput extends BaseListingInput {
  amount: number;
  duration: number;
  reservePrice: number;
  royalties: Royalty[];
}

export interface BidInput extends BaseInput {
  price: number;
}

export interface UpdateAuctionSaleInput extends BaseInput {
  price: number;
  duration: number;
  reservePrice: number;
  royalties: Royalty[];
}

export interface ListDirectSaleInput extends BaseListingInput {
  amount: number;
  price: number;
  royalties: Royalty[];
}

export interface UpdateDirectSaleInput extends BaseInput {
  price: number;
  royalties: Royalty[];
}

export interface BuyDirectSaleInput extends BaseInput {
  amount: number;
}

export interface MarketplaceV1 {
  listDirectSale: (
    params: ListDirectSaleInput
  ) => Promise<providers.TransactionResponse>;
  buyDirectSale: (
    params: BuyDirectSaleInput
  ) => Promise<providers.TransactionResponse>;
  cancelDirectSale: (
    params: BaseInput
  ) => Promise<providers.TransactionResponse>;
  updateDirectSale: (
    params: UpdateDirectSaleInput
  ) => Promise<providers.TransactionResponse>;
  listAuctionSale: (
    params: ListAuctionSaleInput
  ) => Promise<providers.TransactionResponse>;
  updateAuctionSale: (
    params: UpdateAuctionSaleInput
  ) => Promise<providers.TransactionResponse>;
  placeBid: (params: BidInput) => Promise<providers.TransactionResponse>;
  settleAuctionSale: (
    params: BaseInput
  ) => Promise<providers.TransactionResponse>;
  cancelAuctionSale: (
    params: BaseInput
  ) => Promise<providers.TransactionResponse>;
}

function getBaseInfoFromListing(listing: ListingType): {
  nftAddress: string;
  tokenId: string;
  saleId: string;
} {
  return {
    nftAddress: listing.asset.contractAddress,
    tokenId: listing.asset.assetId,
    saleId: listing.blockchainId,
  };
}

function formatRoyalties(royalties: Royalty[]): {
  royaltiesPayees: string[];
  royaltiesShares: number[];
} {
  const royaltiesPayees = royalties.map((obj) => obj.payee);
  const royaltiesShares = royalties.map((obj) => obj.share);

  return { royaltiesPayees, royaltiesShares };
}

export default async function buildMarketplaceV1Contract(
  provider: ethers.providers.Web3Provider,
  contractsInfo: ContractsInfo
): Promise<MarketplaceV1> {
  const signer = provider.getSigner();
  const marketplaceV1Contract = new ethers.Contract(
    contractsInfo.marketplaceV1SaleContract,
    MARKETPLCAE_V1_ABI,
    signer
  );

  return {
    listDirectSale({ nftAddress, tokenId, royalties, price, amount }) {
      const { royaltiesPayees, royaltiesShares } = formatRoyalties(royalties);

      return marketplaceV1Contract.listDirectSale(
        nftAddress,
        tokenId,
        amount,
        ethers.utils.parseEther(price.toString()),
        royaltiesPayees,
        royaltiesShares
      );
    },
    updateDirectSale({ listing, royalties, price }) {
      const { nftAddress, tokenId, saleId } = getBaseInfoFromListing(listing);
      const { royaltiesPayees, royaltiesShares } = formatRoyalties(royalties);

      return marketplaceV1Contract.updateDirectSale(
        nftAddress,
        tokenId,
        saleId,
        ethers.utils.parseEther(price.toString()),
        royaltiesPayees,
        royaltiesShares
      );
    },
    cancelDirectSale({ listing }) {
      const { nftAddress, tokenId, saleId } = getBaseInfoFromListing(listing);

      return marketplaceV1Contract.cancelDirectSale(
        nftAddress,
        tokenId,
        saleId
      );
    },
    buyDirectSale({ listing, amount }) {
      const { nftAddress, tokenId, saleId } = getBaseInfoFromListing(listing);

      return marketplaceV1Contract.buyDirectSale(
        nftAddress,
        tokenId,
        saleId,
        amount,
        {
          value: ethers.BigNumber.from(listing.price).mul(amount),
        }
      );
    },
    listAuctionSale({
      nftAddress,
      tokenId,
      amount,
      royalties,
      reservePrice,
      duration,
    }) {
      const { royaltiesPayees, royaltiesShares } = formatRoyalties(royalties);

      return marketplaceV1Contract.listAuctionSale(
        nftAddress,
        tokenId,
        amount,
        duration,
        ethers.utils.parseEther(reservePrice.toString()),
        royaltiesPayees,
        royaltiesShares
      );
    },
    updateAuctionSale({ listing, royalties, reservePrice, duration }) {
      const { nftAddress, tokenId, saleId } = getBaseInfoFromListing(listing);
      const { royaltiesPayees, royaltiesShares } = formatRoyalties(royalties);

      return marketplaceV1Contract.updateAuctionSale(
        nftAddress,
        tokenId,
        saleId,
        duration,
        ethers.utils.parseEther(reservePrice.toString()),
        royaltiesPayees,
        royaltiesShares
      );
    },
    placeBid({ listing, price }) {
      const { nftAddress, tokenId, saleId } = getBaseInfoFromListing(listing);

      return marketplaceV1Contract.bid(nftAddress, tokenId, saleId, {
        value: ethers.utils.parseEther(price.toString()),
      });
    },
    settleAuctionSale({ listing }) {
      const { nftAddress, tokenId, saleId } = getBaseInfoFromListing(listing);

      return marketplaceV1Contract.settle(nftAddress, tokenId, saleId);
    },
    cancelAuctionSale({ listing }) {
      const { nftAddress, tokenId, saleId } = getBaseInfoFromListing(listing);

      return marketplaceV1Contract.cancelAuctionSale(
        nftAddress,
        tokenId,
        saleId
      );
    },
  };
}
