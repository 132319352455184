import React from "react";
import CollectionSkeleton from "root/components/CollectionSkeleton";
import { Box, Container, Typography } from "root/components/design-system";

export default function CollectionListLoader() {
  return (
    <Box data-loader>
      <Container>
        <Box
          display={{ _: "inline-block", md: "none" }}
          mt={{ _: "36px", lg: "80px", xxl: "100px" }}
          mb={{ _: "30px", md: "49px", lg: "78px" }}
        >
          <Typography
            fontFamily="pexel"
            lineHeight="72px"
            fontSize={{ _: "30px", md: "35px", lg: "60px" }}
            color="#eaeaea"
          >
            Collections
          </Typography>
          <Typography
            fontFamily="reckless"
            fontSize={{ _: "12px", md: "16px", lg: "20px" }}
            letterSpacing="-0.02em"
            color="#eaeaea"
          >
            Artwork and collectibles categorised by content
          </Typography>
        </Box>

        <Box
          display="flex"
          width="100%"
          mt={{ _: "0px", md: "101px", lg: "141px" }}
          mb={{ _: "0px", md: "39px", lg: "53px" }}
          position="relative"
        >
          <Box
            display={{ _: "none", md: "block" }}
            position="absolute"
            bottom="0"
            right="0"
            width="271px"
            height="54px"
            background="#eaeaea"
            borderRadius="5px"
          />
        </Box>

        <CollectionSkeleton quantity={5} />
      </Container>
    </Box>
  );
}
