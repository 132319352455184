/* eslint-disable prefer-destructuring */
const breakpoints = [
  "0", // default
  "400px", // xs
  "576px", // sm
  "768px", // md
  "992px", // lg
  "1300px", // xl
  "1920px", // xxl
  "2400px", /// x3l
];

breakpoints.default = breakpoints[0];
breakpoints.xs = breakpoints[1];
breakpoints.sm = breakpoints[2];
breakpoints.md = breakpoints[3];
breakpoints.lg = breakpoints[4];
breakpoints.xl = breakpoints[5];
breakpoints.xxl = breakpoints[6];
breakpoints.x3l = breakpoints[7];

const fontSizes = [12, 14, 16, 18, 22, 24, 28, 30, 36, 48, 64, 72, 12];

const space = {};

space["0"] = 0;
space["4"] = 4;
space["8"] = 8;
space["10"] = 10;
space["12"] = 12;
space["14"] = 14;
space["16"] = 16;
space["18"] = 18;
space["20"] = 20;
space["32"] = 32;
space["48"] = 48;
space["64"] = 64;
space["80"] = 80;
space["96"] = 96;
space["128"] = 128;
space["256"] = 256;
space["512"] = 512;

// Grid
const containerWidth = 1280;
const gutter = 36;
const sizes = {
  sm: 540,
  md: 630,
  lg: 870,
  xl: 1280,
  xxl: 1750,
  x3l: 2000,
};

const theme = {
  colors: {
    neutralColor: "#9299A0",
    shadeBlack: "#212121",
    blue: "#001AFF",
    green: "#B8DFD6",
    red: "#EA2C2C",
    black: "#000",
    white: "#fff",
    yellow: "#FDB600",
    // TODO: move this to flat
    grays: {
      10: "#F2F2F2",
      20: "#EAEAEA",
      30: "#CFCFCF",
      40: "#AAAAAA",
      50: "#969696",
      60: "#7A7A7A",
      70: "#5E5E5E",
      80: "#383838",
    },
    "gray-10": "#F2F2F2",
    "gray-20": "#EAEAEA",
    "gray-30": "#CFCFCF",
    "gray-40": "#AAAAAA",
    "gray-50": "#969696",
    "gray-60": "#7A7A7A",
    "gray-70": "#5E5E5E",
    "gray-80": "#383838",
  },
  container: containerWidth,
  grid: {
    container: sizes,
    gutter: 36,
  },
  gutter,
  space,
  sizes,
  fontSizes,
  fonts: {
    abcdiatype: "ABCDiatype, Helvetica Neue, Helvetica, Arial, sans-serif",
    rhode: "Rhode, Helvetica Neue, Helvetica, Arial, sans-serif",
    reckless: "RecklessNeue, Georgia, serif",
    pexel: "Pexel Grotesk",
    saintColombe: "Sainte Colombe, Georgia, serif",
    apocRevelations: "Apoc Revelations, Georgia, serif",
    rigton: "Rigton Variable, Helvetica Neue, Arial, sans-serif",
  },
  fontWeights: {
    light: 100,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  easings: {
    inOut: "ease-in-out",
  },
  breakpoints,
  textTransforms: ["uppercase", "lowercase", "capitalize"],
};

export default theme;
