import React, { useState, useEffect, useRef } from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { Flex, Box } from "root/components/design-system";
import "@reach/dialog/styles.css";
import styled, { keyframes } from "styled-components";
import { AssetMediaItem } from "root/modules/AssetDetailsPage/AssetDetailsHero";
import AssetFullMedia from "root/modules/AssetDetailsPage/AssetFullMedia";
import { up } from "styled-breakpoints";
import { Transition } from "react-transition-group";
import { AssetMetadataType } from "root/lib/graphqlDefs";
import ChevronIcon from "../icons/ChevronIcon";
import Typography from "./Typography";
import Close from "../icons/Close";
import Hidden from "./grid/Hidden";

const enterAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Overlay = styled(DialogOverlay)`
  z-index: 1000;
  transition: opacity 0.4s ease;
  background: rgba(0, 0, 0, 0.9);

  animation: ${enterAnimation} 0.3s ease forwards;

  ${up("lg")} {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const DialogWrap = styled(DialogContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100vh;
  padding: 0;
  background: none;
  pointer-events: none;
`;

const MediaWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  margin: auto;
  inset: 0;
  pointer-events: none;

  &[data-state="entering"] {
    opacity: 0;
    transform: translateX(var(--xMotion));
    transition: all 1s 0.3 ease;
  }

  &[data-state="entered"] {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s 0.3s ease;

    img,
    video {
      pointer-events: auto;
    }
  }

  &[data-state="exiting"] {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s ease;
  }

  &[data-state="exited"] {
    opacity: 0;
    transform: translateX(calc(var(--xMotion) * -1));
    transition: all 1s ease;
  }
`;

interface DialogModalProps {
  label: string;
  media: AssetMediaItem[];
  metadata?: AssetMetadataType;
  initial: number;
  onDismiss?: () => void;
}

export default function AssetLightboxModal({
  label,
  onDismiss,
  media,
  metadata,
  initial,
}: DialogModalProps) {
  const [active, setActive] = useState(initial);
  const videoRef = useRef<HTMLVideoElement>();
  const prevSlide = useRef(initial);
  const hasMultipleImages = media.length > 1;

  function goToNextImage() {
    setActive(active < media.length - 1 ? active + 1 : 0);
  }

  function goToPreviousImage() {
    setActive(active > 0 ? active - 1 : media.length - 1);
  }

  useEffect(() => {
    prevSlide.current = active;
  }, [active]);

  function handleKeyPressed(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowLeft":
        goToPreviousImage();
        break;

      case "ArrowRight":
        goToNextImage();
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPressed);

    return () => {
      window.removeEventListener("keydown", handleKeyPressed);
    };
  }, [active]);

  function handleLoad() {
    if (!videoRef?.current || media[active].mediaType !== "video") return;

    setTimeout(() => {
      videoRef.current.volume = 0.5;
      videoRef.current.muted = false;
    }, 100);
  }

  return (
    <Overlay onDismiss={onDismiss}>
      <DialogWrap aria-label={label}>
        <Box>
          {hasMultipleImages && (
            <Hidden sm md>
              <Typography
                fontSize="1.25rem"
                textAlign="center"
                color="#fff"
                p={{ _: "0.5rem", lg: "2rem" }}
              >
                {active + 1} / {media.length}
              </Typography>
            </Hidden>
          )}
          <Box
            as="button"
            onClick={onDismiss}
            position="absolute"
            top="20px"
            right="20px"
            display={{ _: "flex", lg: "none" }}
          >
            <Close size="17px" stroke="#fff" />
          </Box>
        </Box>
        <Flex justifyContent="center" alignItems="center">
          {hasMultipleImages && (
            <Box
              position={{ _: "absolute", lg: "initial" }}
              bottom="10%"
              left="40%"
              as="button"
              type="button"
              onClick={goToPreviousImage}
              data-cursor-pointer
            >
              <ChevronIcon
                width={{ _: "16px", lg: "26px" }}
                height={{ _: "16px", lg: "46px" }}
                left
              />
            </Box>
          )}
          <Flex
            justifyContent="center"
            position="relative"
            width={hasMultipleImages ? "85vw" : "90vw"}
            mx="2rem"
            height={hasMultipleImages ? "80vh" : "90vh"}
            overflow="hidden"
            style={{
              "--xMotion": active > prevSlide.current ? "200px" : "-200px",
            }}
          >
            {media.map((item, ind) => (
              <Transition in={ind === active} timeout={100}>
                {(state) => (
                  <MediaWrapper data-state={state} key={item.mediaCid}>
                    <AssetFullMedia
                      data-state={ind}
                      assetMedia={item}
                      metadata={metadata}
                      ref={videoRef}
                      onLoadedData={handleLoad}
                    />
                  </MediaWrapper>
                )}
              </Transition>
            ))}
          </Flex>

          {hasMultipleImages && (
            <Box
              position={{ _: "absolute", lg: "initial" }}
              bottom="10%"
              right="40%"
              as="button"
              type="button"
              onClick={goToNextImage}
              data-cursor-pointer
              style={{ pointerEvents: "auto" }}
            >
              <ChevronIcon
                width={{ _: "16px", lg: "26px" }}
                height={{ _: "16px", lg: "46px" }}
                right
              />
            </Box>
          )}
        </Flex>
      </DialogWrap>
    </Overlay>
  );
}
