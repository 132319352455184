import React, { forwardRef, Ref } from "react";
import { Img as ReactImage } from "react-image";
import { Box } from "root/components/design-system";
import MuxVideo from "root/components/MuxVideo";
import { AssetMetadataType } from "root/lib/graphqlDefs";
import cidToUrl from "root/utils/cidToUrl";
import { AssetMediaItem } from "root/modules/AssetDetailsPage/AssetDetailsHero";
import getAssetMediaUrl from "root/utils/getAssetMediaUrl";
import nextImageUrl from "root/utils/nextImageUrl";
import styled from "styled-components";
import cloudinary from "root/lib/cloudinary";
import ModelViewer from "./ModelViewer";

const Video = styled(MuxVideo)`
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
`;

const StyledImage = styled(ReactImage)`
  max-width: 100%;
  max-height: 100%;
`;

interface AssetFullMediaProps {
  assetMedia: AssetMediaItem;
  metadata: AssetMetadataType;
  handleLoaded: () => void;
  [attribute: string]: unknown;
}

const AssetFullMedia = forwardRef(
  (
    { assetMedia, metadata, handleLoaded, ...props }: AssetFullMediaProps,
    ref: Ref<HTMLVideoElement>
  ) => {
    const cldImg =
      metadata.cloudinaryId && cloudinary.image(metadata.cloudinaryId).toURL();

    switch (assetMedia.mediaType) {
      case "video":
        return (
          <Video
            onLoadedData={handleLoaded}
            key={assetMedia.mediaCid}
            ref={ref}
            ipfsBlob={assetMedia?.ipfsBlob}
            playbackId={assetMedia.muxPlaybackId}
            fallbackSrc={getAssetMediaUrl(metadata, "video")}
            autoPlay
            playsInline
            loop
            muted
            {...props}
          />
        );
      case "3d":
        return (
          <Box height="500px" width="600px" {...props}>
            <ModelViewer
              src={cidToUrl(assetMedia.mediaCid)}
              alt={`${metadata.name} by ${metadata.author}`}
            />
          </Box>
        );
      default:
        return (
          <StyledImage
            onLoad={handleLoaded}
            key={assetMedia.imageCid}
            src={
              cldImg ||
              nextImageUrl(
                getAssetMediaUrl({ ...metadata, ...assetMedia }, "image")
              )
            }
            alt={`${metadata.name} by ${metadata.author}`}
            decode={false}
            {...props}
          />
        );
    }
  }
);

export default AssetFullMedia;
