import create, { SetState } from "zustand";

interface ConnectModalStore {
  showConnectModal: boolean;
  showSignUp: boolean;
  setShowConnectModal: (value: boolean) => void;
  setShowSignUp: (value: boolean) => void;
}

export const connectModalStore = create((set: SetState<ConnectModalStore>) => ({
  showConnectModal: false,
  showSignUp: false,
  setShowConnectModal: (value) => set({ showConnectModal: value }),
  setShowSignUp: (showSignUp) => set({ showSignUp }),
}));

export default function useConnectModal() {
  const modalStore = connectModalStore();

  function startConnect() {
    modalStore.setShowConnectModal(true);
  }

  function endConnect() {
    modalStore.setShowConnectModal(false);
  }

  return {
    showConnectModal: modalStore.showConnectModal,
    setShowSignUp: modalStore.setShowSignUp,
    showSignUp: modalStore.showSignUp,
    startConnect,
    endConnect,
  };
}
