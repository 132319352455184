import React, { ReactNode } from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import theme from "root/config/theme";

interface ColProps {
  children?: ReactNode;
  className?: string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  x3l?: number;
  offset?: Record<string, number>;
}

const ColEl = styled.div`
  padding-left: ${theme.grid.gutter / 2}px;
  padding-right: ${theme.grid.gutter / 2}px;
  flex: 1 0;

  max-width: var(--xs);
  flex-basis: var(--xs);
  margin-left: var(--default-offset);

  ${up("sm")} {
    max-width: var(--sm);
    flex-basis: var(--sm);
    margin-left: var(--default-offset);
  }

  ${up("md")} {
    flex-basis: var(--md);
    max-width: var(--md);
    margin-left: var(--md-offset);
  }

  ${up("lg")} {
    flex-basis: var(--lg);
    max-width: var(--lg);
    margin-left: var(--lg-offset);
  }

  ${up("xl")} {
    flex-basis: var(--xl);
    max-width: var(--xl);
    margin-left: var(--xl-offset);
  }

  ${up("xxl")} {
    padding-left: 18px;
    padding-right: 18px;
    flex-basis: var(--xxl);
    max-width: var(--xxl);
    margin-left: var(--xxl-offset);
  }

  ${up("x3l")} {
    flex-basis: var(--x3l);
    max-width: var(--x3l);
    margin-left: var(--x3l-offset);
  }
`;

const Col = ({
  children,
  className,
  xs = 12,
  sm = xs,
  md = sm,
  lg = md,
  xl = lg,
  xxl = xl,
  x3l = xxl,
  offset = {},
}: ColProps) => {
  const widthCalc = (num: number) => `${(100 / 12) * num}%`;

  const offsetVars = () => {
    let accum = "";

    return Object.keys(theme.breakpoints).map((breakpoint) => {
      accum = breakpoint in offset ? widthCalc(offset[breakpoint]) : accum;

      return `--${breakpoint}-offset: ${accum};`;
    });
  };

  return (
    <ColEl
      className={className}
      css={`
        --xs: ${widthCalc(xs)};
        --sm: ${widthCalc(sm)};
        --md: ${widthCalc(md)};
        --lg: ${widthCalc(lg)};
        --xl: ${widthCalc(xl)};
        --xxl: ${widthCalc(xxl)};
        --x3l: ${widthCalc(x3l)};
        ${offsetVars()}
      `}
    >
      {children}
    </ColEl>
  );
};

export const ColStyled = ColEl;

export default Col;
