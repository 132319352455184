/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  ComponentPropsWithRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Hls, { HlsConfig } from "hls.js";
import nextImageUrl from "root/utils/nextImageUrl";

export interface MuxVideoProps
  extends Omit<ComponentPropsWithRef<"video">, "src"> {
  fallbackSrc?: string;
  playbackId?: string;
  hlsConfig?: Partial<HlsConfig>;
  loadLevel?: number;
  ipfsBlob?: string;
}

const MuxVideo = React.forwardRef(
  (
    {
      playbackId,
      fallbackSrc,
      hlsConfig,
      loadLevel,
      ipfsBlob,
      ...props
    }: MuxVideoProps,
    existingVideoRef: RefObject<HTMLVideoElement>
  ) => {
    const hlsRef = useRef<Hls>();
    const src = playbackId
      ? `https://stream.mux.com/${playbackId}.m3u8`
      : fallbackSrc;
    const poster = playbackId
      ? nextImageUrl(
          `https://image.mux.com/${playbackId}/thumbnail.jpg?time=0`,
          1200,
          75
        )
      : undefined;
    const newRef = useRef();
    const videoRef = existingVideoRef || newRef;

    const init = useCallback(async () => {
      if (videoRef) {
        const video = videoRef.current;

        if (!src) return;

        // If we have no playbackId just use fallback
        if (ipfsBlob) {
          video.src = ipfsBlob;
        } else if (!playbackId) {
          video.src = src;
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
          // Some browers (safari and ie edge) support HLS natively
          video.src = src;
        } else if (Hls.isSupported()) {
          // This will run in all other modern browsers
          hlsRef.current = new Hls(hlsConfig);

          // For mobile devices use level 2, for all others use level 4 (highest quality)
          // using hlsRef.current.loadLevel fixes the level and prevents level switching
          const HLS_LEVEL = window.innerWidth < 540 ? 2 : 4;

          hlsRef.current.loadLevel = loadLevel || HLS_LEVEL;

          hlsRef.current.loadSource(src);

          hlsRef.current.attachMedia(video);
        } else {
          console.error("This is a legacy browser that doesn't support MSE");
        }
      }
      // ONLY REDEFINE THIS CALLBACK IF SRC CHANGES!!
      //
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, ipfsBlob]);

    useEffect(() => {
      init();

      return () => {
        if (hlsRef.current) {
          hlsRef.current.destroy();
        }
      };
    }, [init, ipfsBlob]);

    return (
      <video
        ref={videoRef}
        poster={poster}
        playsInline
        {...props}
        // Fix Safari videos not respecting z-index
        style={{ ...props.style, WebkitTransformStyle: "preserve-3d" }}
      />
    );
  }
) as FC<MuxVideoProps>;

export default MuxVideo;
