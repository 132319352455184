import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import theme from "root/config/theme";
import TagIcon from "../icons/TagIcon";

const TagWrapper = styled.div`
  font-weight: ${theme.fontWeights.medium};
  font-size: 12px;
  line-height: 1;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  border: 2px solid #cfcfcf;
  color: #7a7a7a;
  border-radius: 20px;
  width: fit-content;

  &[data-variant="highlighted"] {
    color: ${theme.colors.white};
    background: rgba(255, 255, 255, 0.38);
    border: 0px solid;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  ${up("md")} {
    padding: 6px 12px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
`;

interface TagProps {
  icon?: string;
  variant?: "highlighted";
  children: React.ReactNode;
}

const Tag = ({ icon, variant, children }: TagProps) => (
  <TagWrapper data-variant={variant}>
    {icon && <TagIcon icon={icon} color="#7a7a7a" size={21} />}

    {children}
  </TagWrapper>
);

Tag.Styled = TagWrapper;

export default Tag;
