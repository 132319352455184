import React from "react";
import { Box } from "./design-system";

export default function SkeletonLoader() {
  return (
    <Box
      position="absolute"
      zIndex="2"
      width="100%"
      height="100%"
      top="0"
      background="#eaeaea"
    />
  );
}
