import React from "react";
import {
  AutocompleteSource,
  getAlgoliaResults,
} from "@algolia/autocomplete-js";
import { SearchResponse } from "@algolia/client-search";
import { useRouter } from "next/router";
import Image from "next/image";
import algoliasearch from "algoliasearch";
import { createAlgoliaInsightsPlugin } from "@algolia/autocomplete-plugin-algolia-insights";
import insightsClient from "search-insights";
import { Routes } from "types/routeTypes";
import styled from "styled-components";
import truncateString from "root/utils/truncateString";
import isClient from "root/utils/isClient";
import cidToUrl from "root/utils/cidToUrl";
import AlgoliaSearchContainer from "./AlgoliaSearchContainer";
import { Box, Flex, Typography } from "../design-system";
import UserAvatar from "../UserAvatar";
import AssetMedia from "../AssetMedia";

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

insightsClient("init", { appId, apiKey, useCookie: true });
const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({ insightsClient });

const Header = styled(Typography)`
  font-weight: 700;
  color: #737373;
  font-size: 14px;
  font-family: "abcdiatype";
`;

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 15px;
  font-family: "abcdiatype";
`;

const SubTitle = styled(Typography)`
  font-weight: 700;
  color: #b0b0b0;
  font-size: 11px;
  font-family: "abcdiatype";
`;

const Username = styled(Typography)`
  font-weight: 500;
  color: #001aff;
  font-size: 11px;
  font-family: "abcdiatype";
`;

function debouncePromise(fn, time) {
  let timerId = null;
  let resolveFns = [];

  return function debounced(
    ...args
  ): Promise<Array<AutocompleteSource<never> | boolean | undefined>> {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      resolveFns.forEach((resolve) => resolve(fn(...args)));
      resolveFns = [];
    }, time);

    return new Promise((resolve) => resolveFns.push(resolve));
  };
}

function AssetItem({ hit }) {
  const assetMetadata = { mediaType: "image", ...hit };

  return (
    <Flex p="4px">
      <Box
        data-media
        position="relative"
        borderRadius="10px"
        overflow="hidden"
        width="40px"
      >
        <AssetMedia assetMetadata={assetMetadata} />
      </Box>
      <Box ml="12px">
        <Title>{hit.name}</Title>
        <SubTitle>By {hit.author || hit.creator.name}</SubTitle>
      </Box>
    </Flex>
  );
}

function ProfileItem({ hit }) {
  return (
    <Flex p="4px">
      <UserAvatar user={hit} size="40px" />
      <Box ml="12px">
        <Title>{hit.name}</Title>
        <Username>
          @{truncateString(hit.username || hit.ethAddress, 10)}
        </Username>
      </Box>
    </Flex>
  );
}

function CollectionItem({ hit }) {
  const videoPreview = hit.coverMediaVideoPreview;

  return (
    <Flex p="4px">
      <Box
        data-media
        position="relative"
        borderRadius="10px"
        overflow="hidden"
        width="40px"
      >
        <Image
          src={videoPreview || cidToUrl(hit.coverMediaCid)}
          alt={`${hit?.title} by ${hit?.creator?.username}`}
          layout="fill"
          objectFit="cover"
          quality="75"
          decoding="async"
        />
      </Box>
      <Box ml="12px">
        <Title>{hit.title}</Title>
        <SubTitle>By {hit.creator.name || hit.creator.username}</SubTitle>
      </Box>
    </Flex>
  );
}

export default function AlgoliaSearch() {
  const router = useRouter();
  const debounced = debouncePromise((items) => Promise.resolve(items), 300);

  // See https://github.com/algolia/autocomplete/issues/853
  // Clicking cancel also triggers buttons underneath, so we
  // need to stop propagation
  function handleCancelClick(event) {
    event.stopPropagation();
  }

  return (
    <AlgoliaSearchContainer
      openOnFocus
      container={null}
      plugins={[algoliaInsightsPlugin]}
      onStateChange={({ state }) => {
        if (!isClient()) return;

        if (state.isOpen) {
          (
            document.querySelector("#search-blurred-background") as HTMLElement
          ).style.opacity = "1";

          document
            .querySelector(".aa-DetachedCancelButton")
            ?.addEventListener("touchstart", handleCancelClick);
        } else {
          (
            document.querySelector("#search-blurred-background") as HTMLElement
          ).style.opacity = "0";

          document
            .querySelector(".aa-DetachedCancelButton")
            ?.removeEventListener("touchstart", handleCancelClick);
        }
      }}
      getSources={({ query, setContext }) =>
        debounced([
          {
            sourceId: "collections",
            getItemUrl({ item }) {
              return Routes.COLLECTION_PAGE({ slug: item?.slug });
            },
            onSelect({ itemUrl, setIsOpen }) {
              setIsOpen(false);
              router.push(itemUrl);
            },
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "collections",
                    query,
                    params: {
                      clickAnalytics: true,
                      snippetEllipsisText: "…",
                      hitsPerPage: 3,
                    },
                  },
                ],
                transformResponse({ results, hits }) {
                  setContext({
                    nbCollections: (results[0] as SearchResponse<never>).nbHits,
                  });

                  return hits;
                },
              });
            },
            templates: {
              item({ item }) {
                return <CollectionItem hit={item} />;
              },
              header({ state }) {
                return (
                  <Header>Collections ({state.context.nbCollections})</Header>
                );
              },
            },
          },
          {
            sourceId: "assets",
            getItemUrl({ item }) {
              return Routes.ASSETS({
                metadata: {
                  name: item?.name,
                  assetId: item?.assetId,
                  contractAddress: item?.contractAddress,
                },
                creatorUser: { username: item?.creator?.username },
              });
            },
            onSelect({ itemUrl, setIsOpen }) {
              setIsOpen(false);
              router.push(itemUrl);
            },
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "assets",
                    query,
                    params: {
                      clickAnalytics: true,
                      snippetEllipsisText: "…",
                      hitsPerPage: 3,
                    },
                  },
                ],
                transformResponse({ results, hits }) {
                  setContext({
                    nbAssets: (results[0] as SearchResponse<never>).nbHits,
                  });

                  return hits;
                },
              });
            },
            templates: {
              item({ item }) {
                return <AssetItem hit={item} />;
              },
              header({ state }) {
                return <Header>Artworks ({state.context.nbAssets})</Header>;
              },
            },
          },
          {
            sourceId: "users",
            getItemUrl({ item }) {
              return Routes.USERS({
                id: item?.objectID,
                username: item?.username,
                ethAddress: item?.ethAddress,
              });
            },
            onSelect({ itemUrl, setIsOpen }) {
              setIsOpen(false);
              router.push(itemUrl);
            },
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "users",
                    query,
                    params: {
                      clickAnalytics: true,
                      snippetEllipsisText: "…",
                      hitsPerPage: 3,
                    },
                  },
                ],
                transformResponse({ results, hits }) {
                  setContext({
                    nbUsers: (results[0] as SearchResponse<never>).nbHits,
                  });

                  return hits;
                },
              });
            },
            templates: {
              item({ item }) {
                return <ProfileItem hit={item} />;
              },
              header({ state }) {
                return <Header>Profiles ({state.context.nbUsers})</Header>;
              },
            },
          },
        ])
      }
    />
  );
}
