import React from "react";
import { Box, Container, Flex } from "root/components/design-system";

const HomepageLoader = () => (
  <Container>
    <Flex
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100vh"
      width="100%"
      zIndex="999"
      background="#eaeaea"
      position="relative"
    >
      <Box
        height={{ _: "18px", lg: "16px" }}
        width={{ _: "81px", lg: "78px" }}
        bg="#d4d4d4"
        borderRadius={{ _: "17px", lg: "23px" }}
      />
      <Box
        height={{ _: "37px", lg: "65px" }}
        width={{ _: "254px", lg: "706px" }}
        bg="#d4d4d4"
        mt={{ _: "30px", lg: "46px" }}
        mb={{ _: "15px", lg: "17px" }}
        borderRadius="50px"
      />
      <Box
        height={{ _: "37px", lg: "18px" }}
        width={{ _: "254px", lg: "121px" }}
        bg="#d4d4d4"
        borderRadius="50px"
      />
      <Box
        height={{ _: "18px", lg: "28px" }}
        width={{ _: "81px", lg: "193px" }}
        bg="#d4d4d4"
        mt={{ _: "16px", lg: "51px" }}
        mb={{ _: "65px", lg: "46px" }}
        borderRadius="26px"
      />
      <Box
        height={{ _: "38px", lg: "39px" }}
        width={{ _: "166px", lg: "203px" }}
        bg="#d4d4d4"
        borderRadius="45px"
      />
    </Flex>
  </Container>
);

export default HomepageLoader;
